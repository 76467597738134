import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Alert from "../Alert";
import {updateName} from "../../actions/authAction";

const ProfileInfo = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);

    const [userName, setUserName] = useState({
        name: auth.user.name,
        email: auth.user.email
    })

    const onInputChange = (e) => {
        setUserName({...userName, name: e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(updateName(userName, setLoader));
    }

    return(
        <>
            <Alert/>
            <div className="personal-details-box">
                <div className="row">
                    <div className="col-md-12 mx-auto">

                        <form className="form-box mt-5" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                            <h6 className="text-center">Personal Information</h6>
                            <div className="row mt-5 ">
                                <div className="col-md-6">
                                    <div className="form-group  ">
                                        <small className="form-text text-muted">Your Name</small>
                                        <input type="text" className="form-control" placeholder="Stephanie"
                                               required name="name" value={userName.name}
                                               onChange={(e)=>onInputChange(e)}
                                        />
                                    </div>
                                </div>
                                {/*<div className="col-md-6">*/}
                                {/*    <div className="form-group">*/}
                                {/*        <small className="form-text text-muted">Last Name</small>*/}
                                {/*        <input type="text" className="form-control" placeholder="Carley" />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <small className="form-text text-muted">Your Email</small>
                                        <input type="text" className="form-control"
                                               placeholder="example@gmail.com"
                                               readOnly value={userName.email}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <button type="submit" className="form-control btn-change6 ">
                                        { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Update
                                    </button>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProfileInfo;