import randomstring from "randomstring";

export const baseURL = "https://backend.clipsreel.io/"; //https://backend.clipsreel.io/

export const convertTextToArray = (string, maxLength) => {
  const rows = [];
  const arr = string.split(" ");
  let currentRow = arr[0];
  let rowLength = currentRow.length;
  for (let i = 1; i < arr.length; i++) {
    let word = arr[i];
    rowLength += word.length + 1;
    if (rowLength <= maxLength) {
      currentRow += " " + word;
    } else {
      rows.push(currentRow);
      currentRow = word;
      rowLength = word.length;
    }
  }
  rows.push(currentRow);

  return rows;
};

let randomId = randomstring.generate({ length: 20, charset: "numeric" });

export const slideObject = {
  id: randomId,
  type: "image",
  variation_id: 1,
  text: '<div id="' + randomId + '">Your Text Here</div>',
  uniq_id: randomstring.generate({ length: 10, charset: "numeric" }),
  font_color: "#000000",
  font_family: "Noto Sans",
  bg_color: false,
  font_size: 18,
  duration: 5,
  align: "left",
  bold: false,
  italic: false,
  uppercase: false,
  lowercase: false,
  underline: false,
  strike: false,
  file_url: false,
  poster: false,
  audio: false,
  line_height: 1,
  is_selected: false,
  tts: false,
  generated: true,
  is_outro: true,
};
