import styled from "styled-components";

export const Regular2Style = styled.div`
width: 100% !important;
height: 100% !important;

.t2-containersTwo .img-fluid {
  display: block;
  max-width: 100%;
}

.t2-containersTwo {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.t2-containersTwo {
  /* background: #005596; */
  justify-content: space-between;
}

.t2-containersTwo .d-flex {
  display: flex;
}

.t2-containersTwo .t2-img-wrapper .t2-img-fluid {
  opacity: 0.5;
}

.t2-containersTwo .f-cyan {
  color: #00fffc;
}

.t2-containersTwo .f-white {
  color: #fff;
}

.t2-containersTwo div {
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
  font-weight: 500;
  margin: 0;
}

.t2-containersTwo .t2-text-wrappers div {
  font-size: 1.6vw;
  line-height: 1.2em;
  text-align: right;
  //left: 100%;
}

.t2-containersTwo .t2-text-wrappers {
  width: 100%;
  padding-right: 2vw;
  padding-left: 1vw;
  overflow: hidden;
  max-height: 74%;
}

.t2-containersTwo .t2-t-colmn-1 {
  flex: 0 0 100%;
  max-width: 60%;
  display: flex;
  align-items: center;
  background: #005596;
  z-index: 1;
  position: relative;
  /* overflow: hidden; */
}

.t2-containersTwo .t2-t-colmn-1:after {
  content: "";
  height: 2.5vh;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #00fffc;
}

.t2-containersTwo .t2-woman {
  width: 100%;
  height: 100%;
}

.t2-containersTwo .t2-t-colmn-2 .t2-woman img {
  height: 100%;
  width: 100%;
  opacity: 0.6;
  object-fit: cover;
  object-position: 35% 0%;
}

.t2-containersTwo .t2-t-colmn-2 {
  flex: 0 0 40%;
  max-width: 40%;
  background: #005596;
  overflow: hidden;
  /* position: absolute;
  right: 0; */
}

@media screen and (max-width: 1366.98px) {
  .t2-containersTwo .t2-text-wrappers div {
    /*font-size: 1.4vw;*/
  }
}

@media screen and (max-width: 991.98px) {
  .t2-containersTwo .t2-text-wrappers div {
    /*font-size: 2vw;*/
  }
}

@media screen and (max-width: 640.98px) {
  .t2-containersTwo .t2-text-wrappers p {
    /*font-size: 3vw;*/
  }
}
`;
