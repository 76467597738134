import React, {useEffect, useState} from "react";
import axios from "axios";
import {baseURL} from "../../global/global";
import {useDispatch, useSelector} from "react-redux";
import {setAlert} from "../../actions/alert";
import Alert from "../Alert";
import {
    addTtsGenStatus,
    addTtsText,
    removeTtsGenStatus,
    updateTtsGenStatus,
    updateTtsText
} from "../../actions/videoAction";
import swal from "sweetalert";

let ttsAudio = false;
const TtsAudio = ({selectedIndex}) => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);
    const tilesData = useSelector(state => state.videos.data.slides);

    const selectedLayer = tilesData.find(({is_selected}) => is_selected === true);

    const [currentTts, setCurrentTts] = useState('');
    const [loader, setLoader] = useState(false);
    const [ttsStatus,setTtsStatus] = useState(true);
    const [pollyLanguages, setPollyLanguages] = useState({data: []});
    const [pollyVoices, setPollyVoices] = useState({data: []});
    const [ttsData, setTtsData] = useState({
        text: '',
        language_id: '',
        voice_id: ''
    });
    const [ttsAllStatus, setTtsAllStatus] = useState(false);


    const searchPollyLanguages = () => {
        axios({
            method: 'GET',
            url : `${baseURL}get-polly-languages`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res =>{

            if(res.data.status === true){
                setPollyLanguages({...pollyLanguages, data: res.data.data});
            }else{
                dispatch(setAlert(res.data.message, "danger"));
            }

        }).catch(error=>{
            dispatch(setAlert("Getting server error", "danger"));
        })
    }

    const searchPollyVoices = (value) => {

        let formData = new FormData();
        formData.append('lang_code',value);

        axios({
            method: 'POST',
            url : `${baseURL}get-artists`,
            data: formData,
            headers: {'Authorization': auth.token}
        }).then(res =>{

            if(res.data.status === true){
                setPollyVoices({...pollyVoices, data: res.data.data});
                setTtsData({...ttsData,language_id: value, voice_id: res.data.data[0].voice_id});
            }else{
                dispatch(setAlert(res.data.message,'danger'));
            }

        }).catch(error=>{
        })
    }

    const generateTts = () => {
        if(selectedLayer.tts_text !== ""){
            setLoader(true);

            axios({
                method: 'POST',
                url : `${baseURL}generate-tts`,
                data: JSON.stringify({
                    text: selectedLayer.tts_text!=='' ? selectedLayer.tts_text : selectedLayer.text,
                    language_id: ttsData.language_id,
                    voice_id: ttsData.voice_id
                }),
                headers: {'Authorization': auth.token}
            }).then(res =>{
                if(res.data.status === true){
                    dispatch(setAlert(res.data.message, 'success'));
                    setTtsStatus(true);

                    playTtsStatus(res.data.file_url)
                }else{
                    dispatch(setAlert(res.data.message, 'danger'));
                }
                setLoader(false);

            }).catch(error=>{
                setLoader(false);
            })

        }else{
            swal("Oops!", "Please, add text in TTS");
        }
    }

    const getLanguage = async (e) => {
        setTtsStatus(true);

        dispatch(updateTtsGenStatus(selectedIndex, false)); // for update tts status

        await searchPollyVoices(e.target.value);
        setTtsData({...ttsData, language_id: e.target.value});
    }

    const getVoices = (e) => {
        setTtsStatus(true);

        dispatch(updateTtsGenStatus(selectedIndex, false)); // for update tts status

        setTtsData({...ttsData, voice_id: e.target.value});
    }

    const changeTtsText = (e) => {
        setTtsData({...ttsData, text: e.target.value});

        dispatch(updateTtsGenStatus(selectedIndex, false));

        dispatch(updateTtsText(e.target.value, selectedIndex))

    }

    const saveTts = (url) => {
        addTtsToSlide(url, ttsData.voice_id, ttsData.language_id);
    }

    const addTtsToSlide = (url, voice, language, index=selectedIndex) => {
        let jsonData = {
            url: url,
            voice_id: voice,
            language_id: language,
            index: index,
            status: true
        }

        dispatch(addTtsText(jsonData))
    }

    const playTtsStatus = (url,index=selectedIndex) => {
        let jsonData = {
            url: url,
            index: index,
            status: true
        }

        dispatch(addTtsGenStatus(jsonData))
    }

    const playTts = (url) => {

        if(ttsAudio!==false){
            ttsAudio.pause();
        }

        if(currentTts === url){
            ttsAudio.pause();
            setCurrentTts('')
        }else{
            ttsAudio = new Audio(url);

            ttsAudio.play();

            ttsAudio.onended = function() {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    const generateTtsAll = (tile,index) => {
        if(tile.tts_text !== ""){
            setLoader(true);

            axios({
                method: 'POST',
                url : `${baseURL}generate-tts`,
                data: JSON.stringify({
                    text: tile.tts_text!=='' ? tile.tts_text : tile.text,
                    language_id: ttsData.language_id,
                    voice_id: ttsData.voice_id
                }),
                headers: {'Authorization': auth.token}
            }).then(res =>{
                if(res.data.status === true){
                    dispatch(setAlert(res.data.message, 'success'));
                    // setTtsStatus(true);

                    playTtsStatus(res.data.file_url,index)
                    addTtsToSlide(res.data.file_url, ttsData.voice_id, ttsData.language_id, index)
                }else{
                    dispatch(setAlert(res.data.message, 'danger'));
                }
                setLoader(false);

            }).catch(error=>{
                setLoader(false);
            })
        }
    }

    const allTts = () => {
        if(ttsAllStatus === true){

            tilesData.map((tile,index)=>{
                removeTtsAll(index)
            })

            setTtsAllStatus(false);
        }else{

            tilesData.map((tile,index)=>{
                generateTtsAll(tile,index)
            })

            setTtsAllStatus(true);
        }
    }

    const removeTtsAll = (index) => {
        dispatch(removeTtsGenStatus(index))
    }


    useEffect(()=>{
        searchPollyLanguages();
        searchPollyVoices('en-US');

        return () => {
            if(ttsAudio !== false){
                ttsAudio.pause();
            }
        }
    },[])

    return(
        <>
            <Alert/>
            <div className="tts-box-wrapper">
                <div className="row m-0">
                    <div className="alert alert-primary" role="alert">
                        This music will apply to slide by slide
                    </div>
                    <div className="col-sm-4">
                        <div className="audio-slide-box">
                            <div className="form-group text-center">
                                <input className="form-control" type="text" name="slide" value={selectedIndex+1} readOnly />
                                <label className="mt-2">SLIDE</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="audio-language-box">
                            <div className="form-group text-center">
                                <select className="form-select" onChange={(e)=>getLanguage(e)}>
                                    {
                                        pollyLanguages.data.length > 0 ?
                                            pollyLanguages.data.map((item,index)=>{
                                                return(
                                                    <option key={index} value={item.code}>{item.name}</option>
                                                )
                                            })
                                        : ''
                                    }
                                </select>
                                <label className="mt-2">LANGUAGE</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="audio-voice-box">
                            <div className="form-group text-center">
                                <select className="form-select" onChange={(e)=>getVoices(e)}>
                                    {
                                        pollyVoices.data.length > 0 ?
                                            pollyVoices.data.map((item,index)=>{
                                                return(
                                                    <option key={index} value={item.voice_id}>{item.voice_id} ({item.gender})</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                                <label className="mt-2">VOICE</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="audio-add-text-box">
                            <div className="mb-3">
                                  <textarea
                                      className="form-control"
                                      placeholder="Add text here"
                                      rows={8}
                                      onChange={(e)=>changeTtsText(e)}
                                      value={selectedLayer.tts_text}
                                  >
                                      {selectedLayer.tts_text}
                                  </textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="dimmer-light-box">
                        <div className="row m-0">
                            <div className="col-md-12 mx-auto p-0">
                                <div className="dimmer-wrapper">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <div className="dimmer-light d-flex align-items-center">
                                                <h6 className="mb-0">{ttsAllStatus?'Remove':'Apply'} for all</h6>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="dimmer-switch-btn">
                                                <div className="remove-logo-switch-btn ">
                                                    <div className="form-check form-switch d-flex justify-content-end  p-0">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexSwitchCheckChecked"
                                                            checked={ttsAllStatus}
                                                            onChange={()=>allTts()}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row my-2">
                    <div className="col-6">
                        <div className="audio-preview-btn">
                            {
                                selectedLayer.tts_status === false ?
                                    <button disabled={loader} className="form-control  btn-change6 " onClick={(e)=>generateTts()}>
                                          <span className="mr-1">
                                              {loader ? <i className="fa fa-spinner fa-spin"/> : <i className="fas fa-file-upload" />}
                                          </span>
                                                Generate
                                    </button>
                                    :
                                    <button className="form-control  btn-change6 " onClick={()=>playTts(selectedLayer.tts_status?.url)}>
                                          <span className="mr-1">
                                            <i className={`fas ${currentTts === selectedLayer.tts_status?.url ? 'fa-pause-circle' : 'fa-play-circle'}`} />
                                          </span>
                                        Preview
                                    </button>
                            }

                        </div>
                    </div>
                    <div className="col-6">
                        <div className="audio-save-use-btn">
                            <button onClick={()=>saveTts(selectedLayer.tts_status?.url)} className="form-control  btn-change6 " disabled={selectedLayer.tts_status===false?true:false}>
                                  <span className="mr-1">
                                    <i className="far fa-save" />
                                  </span>{" "}
                                Save &amp; Use
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(TtsAudio);