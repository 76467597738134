import React, {useEffect, useState} from "react";
import helpBanner from "../../images/bgh.png";
import HelpSubContent from "./HelpSubContent";
import {useSelector} from "react-redux";
import searchicon from '../../images/searchicon.png'

import favIcon from "../../images/FAVICON.png";

const HelpContent = (props) => {

    const supportData = useSelector(state => state.support);

    const [articlesData, setArticlesData] = useState(supportData.articles);
    const [q, setQ] = useState('');

    const onSearch = (e) => {
        setQ(e.target.value);
    }

    const search = (articles) => {
        return articles.filter(
            article => article.tags.toLowerCase().indexOf(q.toLowerCase()) > -1
        );
    }

    useEffect(()=>{
        setArticlesData(supportData.articles)
    },[supportData.articles])

    return(
        <>
            <section className="help-support-banner d-flex align-items-center justify-content-center" >
                <div className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-6 ">
                            <div className="help-banner-content   text-white">
                                <h3>What do you want <span className="d-lg-block" >to Know?</span></h3>
                                <div className="input-group mt-4">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text"><i className="fas fa-search"></i></span>
                                    </div>
                                    <input type="text" className="form-control"
                                           placeholder="Search"
                                           onChange={(e)=>onSearch(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                             <div className="secarchicone-part">
                                  <img src={searchicon} alt   id="searchimg" className="img-fluid"  />
                             </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="important-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="text-center text-white ">Important Topics</h3>
                        </div>
                    </div>
                    <div className="important-icon-box mt-5">
                        <div className="row">
                            <div className="col-md-10 mx-auto">
                                <div className="icon-box-topics">
                                    <div className="row">
                                        {
                                            articlesData.length > 0 ?
                                                search(articlesData).map((article, index)=>{
                                                    return(
                                                        <HelpSubContent
                                                            name={article.name}
                                                            image={article.logo}
                                                            url={`/training?id=${article.id}`}
                                                            key={index}
                                                        />
                                                    )
                                                })
                                            : ''
                                        }
                                        {
                                            props.loadArticles ?
                                                <div className="col-12">
                                                    <h4 className="text-center load-icon-help"><i className="fa fa-spinner fa-spin mr-2"/></h4>
                                                </div>
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </section>
        </>
    )
}

export default HelpContent;