import styled from "styled-components";

export const Square8Style = styled.div`
  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }

  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }
  .text-wrapper {
    white-space: pre-wrap;
  }
  /*.f-arial {*/
  /*	font-family: Arial;*/
  /*}*/

  /*.f-mvboli {*/
  /*	font-family: mvboli;*/
  /*}*/

  /*.f-nexalight {*/
  /*	font-family: nexalight;*/
  /*}*/

  /*.f-nexabold {*/
  /*	font-family: nexabold;*/
  /*}*/

  .f-cyan {
    color: #00fffc;
  }

  .f-cyan-dark {
    color: #00d1cf;
  }

  .f-blue {
    color: #005596;
  }

  .f-white {
    color: #ffffff;
  }

  .poster {
    position: relative;
    overflow: hidden !important;
  }

  .poster.nine-by-sixteen {
    width: 1080px;
    height: 1920px;
  }

  .poster.one-by-one {
    width: 310px;
    height: 310px;
  }

  .poster.nine-by-sixteen {
    width: 171px;
    height: 304px;
  }

  .poster.one-by-one.eight .bg {
    position: absolute;
    height: 140%;
    width: 140%;
    left: -10%;
    top: -20%;
  }

  .poster.one-by-one.eight .text-wrapper {
    position: absolute;
    left: 0;
    bottom: 110px;
    box-sizing: content-box;
    width: 100%;
  }

  .poster.one-by-one.eight .ta-outer {
    position: relative;
    width: fit-content;
    background-color: #fff;
    margin-bottom: 15px;
    padding: 5px 10px;
    overflow: hidden;
    white-space: nowrap;
  }

  .poster.one-by-one.eight .ta {
    width: fit-content;
    font-size: 78px;
    margin: 0;
  }

  .poster.one-by-one.eight .bg {
    position: absolute;
    height: 140%;
    width: 140%;
    left: -10%;
    top: -20%;
  }

  .poster.one-by-one.eight .text-wrapper {
    position: absolute;
    left: 0;
    bottom: 20px;
    box-sizing: content-box;
    width: 100%;
  }

  .poster.one-by-one.eight .ta-outer {
    position: relative;
    width: fit-content;
    background-color: #fff;
    margin-bottom: 3px;
    padding: 2px 3px;
    overflow: hidden;
    white-space: nowrap;
  }

  .poster.one-by-one.eight .ta {
    width: fit-content;
    font-size: 12px;
    margin: 0;
  }
`;
