import styled from "styled-components";

export const Square2Style = styled.div`
width:100%;
  height:100%;
  
  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .text-wrappers div {
    font-family: "Nexa", sans-serif;
    line-height: 1.2em;
    font-weight: 600;
    margin: 0;
  }

  .container6 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .two-layer-bg {
    position: absolute;
    display: flex;
    width:100%;
    height:100%;
  }

  .two-layer-bg .col-1 {
    background: #f4c88c;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .two-layer-bg .col-2 {
    background: #cc883e;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .front-layer {
    width:100%;
    height:100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
   
  }

  .front-layer .img-wrapper {
    padding: 2%;
    background: #fff;
    width: 100%;
    height: 100%;
    left: 101vw;
    position: absolute;
  }

  .front-layer .img-wrapper .wrap {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #000;
  }

  .front-layer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
    transform: scale(1.1);
  }

  .text-wrappers {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  
   
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-wrappers div {
    width: 100%;
  }
`;
