import React from "react";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";
import { Square2Style } from "./css/Square2Style";
import pink from "./images/pink.png";
const Template = (props) => {
  return (
    <Square2Style>
     <div className="container_two_two">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="back-layer" />
        <div className="front-layer">
          <TemplateImage slideType={props.slideType} file={props.file} />

          <img src={pink} alt className="img-fluid" />
          <div className="text-wrappers">
            <TemplateText
              id={props.id}
              text={props.text}
              textStyle={props.textStyle}
              editStatus={props.editStatus}
              onInputSlideText={props.onInputSlideText}
            />
          </div>
        </div>
      </div>
    </Square2Style>
  );
};

export default Template;
