import styled from "styled-components";

export const Square7Style = styled.div`
width: 100%;
height: 100%;


  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container6 {
    width: 100%;
    height: 100%;

    overflow: hidden;
  }

  .f-white {
    color: #fff;
  }

 
  .back-layer {
    width: 100%;
   height: 100%;
    background: #f4c88c;
  }

  .back-layer img {
    position:absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.1);
    left: 5%;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .text-wrapper > div {
    width:100%
  }

`;
