import styled from "styled-components";

export const Square1Style = styled.div`
.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: left;
  justify-content: left;
}

.subflex-left {
  width: 100%;
  text-align: left;
}

.subflex-right {
  width: 100%;
  text-align: right;
}

.subflex-center {
  width: 100%;
  text-align: center;
}
.text-wrapper {
  white-space: pre-wrap;
}
/*.f-arial {*/
/*	font-family: Arial;*/
/*}*/

/*.f-mvboli {*/
/*	font-family: mvboli;*/
/*}*/

/*.f-nexalight {*/
/*	font-family: nexalight;*/
/*}*/

/*.f-nexabold {*/
/*	font-family: nexabold;*/
/*}*/

.f-cyan {
  color: #00fffc;
}

.f-cyan-dark {
  color: #00d1cf;
}

.f-blue {
  color: #005596;
}

.f-white {
  color: #ffffff;
}

.poster {
  position: relative;
  overflow: hidden !important;
}

.poster.nine-by-sixteen {
  width: 1080px;
  height: 1920px;
}

.poster.one-by-one {
  width: 310px;
  height: 310px;
}

.poster.nine-by-sixteen {
  width: 171px;
  height: 304px;
}

.poster > .bg.one {
  position: absolute;
  height: 100%;
  width: 100%;
}

.poster-content.one {
  overflow: hidden;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 85, 150, 0.5);
}

.poster-content.one .text-wrapper {
  height: 200px;
  width: 65%;
  padding: 10px;
  top: 0;
  bottom: 0;
  right:0;
  margin: auto;
  position: absolute;
}

.poster-content.one .ta {
  margin: 0 -100% 0 0;
  text-align: right;
}

.poster.one-by-one .poster-content.one .ta {
  font-size: 16px !important;
  line-height: 19px;
}
`;
