import styled from "styled-components";

export const Regular3Style = styled.div`
  width:100%;
  height:100%;
  
  .container3 {
    width:100%;
    height:100%;
    overflow: hidden;
    background: #a1dffb;
  }

  .f-red {
    color: #c33124;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #a1dffb;
  }

  .f-yellow {
    color: #f9de59;
  }

  .back-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    opacity: 0.5;
  }

  .text-wrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
  }

  .text-wrapper > div{
    width: 100%;
  
  }

`;
