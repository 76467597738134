import { gsap, Linear, Power1, Power4, Power2 } from "gsap";
import {SplitText} from "gsap/SplitText";

import { init } from "./common";

// Regular

export const outro_regular_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  const st = new SplitText(".text-wrapper > div", { type: "chars" });
  localTimeline.to(".logo", { bottom: 0, rotation: 0, ease: Power1.easeOut });
  localTimeline.from(st.chars, {
    bottom: -100,
    ease: Power1.easeOut,
    stagger: 0.05,
  });
  return localTimeline;
};

export const outro_regular_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  const st = new SplitText(".text-wrapper > div", { type: "chars" });
  localTimeline.to(".logo", { top: 0, rotation: 0, ease: Power1.easeOut });
  localTimeline.from(st.chars, {
    bottom: -100,
    ease: Power1.easeOut,
    stagger: 0.05,
  });
  return localTimeline;
};

export const outro_regular_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".poster-content", {
    opacity: 1,
    filter: "blur(0)",
    ease: Linear.easeNone,
    duration: 1,
  });
  localTimeline.to(".poster-content", {
    scale: 1.1,
    ease: Power1.easeOut,
    duration: 3,
  });
  return localTimeline;
};

export const outro_regular_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".poster-content",
    { scale: 0.2 },
    { scale: 1.1, ease: Power1.easeOut, duration: 0.5 }
  );
  localTimeline.to(".poster-content", {
    scale: 1,
    ease: Power1.easeOut,
    duration: 0.5,
  });
  return localTimeline;
};

//Square

export const outro_square_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  const st = new SplitText(".text-wrapper > div", { type: "chars" });
  localTimeline.to(".logo", { bottom: 0, rotation: 0, ease: Power1.easeOut });
  localTimeline.from(st.chars, {
    bottom: -100,
    ease: Power1.easeOut,
    stagger: 0.05,
  });
  return localTimeline;
};

export const outro_square_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  const st = new SplitText(".text-wrapper > div", { type: "chars" });
  localTimeline.to(".logo", { top: 0, rotation: 0, ease: Power1.easeOut });
  localTimeline.from(st.chars, {
    bottom: -100,
    ease: Power1.easeOut,
    stagger: 0.05,
  });
  return localTimeline;
};

export const outro_square_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".poster-content", {
    opacity: 1,
    filter: "blur(0)",
    ease: Linear.easeNone,
    duration: 1,
  });
  localTimeline.to(".poster-content", {
    scale: 1.1,
    ease: Power1.easeOut,
    duration: 3,
  });
  return localTimeline;
};

export const outro_square_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".poster-content",
    { scale: 0.2 },
    { scale: 1.1, ease: Power1.easeOut, duration: 0.5 }
  );
  localTimeline.to(".poster-content", {
    scale: 1,
    ease: Power1.easeOut,
    duration: 0.5,
  });
  return localTimeline;
};

//Story

export const outro_story_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  const st = new SplitText(".text-wrapper > div", { type: "chars" });
  localTimeline.to(".logo", { bottom: 0, rotation: 0, ease: Power1.easeOut });
  localTimeline.from(st.chars, {
    bottom: -100,
    ease: Power1.easeOut,
    stagger: 0.05,
  });
  return localTimeline;
};

export const outro_story_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  const st = new SplitText(".text-wrapper > div", { type: "chars" });
  localTimeline.to(".logo", { top: 0, rotation: 0, ease: Power1.easeOut });
  localTimeline.from(st.chars, {
    bottom: -100,
    ease: Power1.easeOut,
    stagger: 0.05,
  });
  return localTimeline;
};

export const outro_story_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".poster-content", {
    opacity: 1,
    filter: "blur(0)",
    ease: Linear.easeNone,
    duration: 1,
  });
  localTimeline.to(".poster-content", {
    scale: 1.1,
    ease: Power1.easeOut,
    duration: 3,
  });
  return localTimeline;
};

export const outro_story_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".poster-content",
    { scale: 0.2 },
    { scale: 1.1, ease: Power1.easeOut, duration: 0.5 }
  );
  localTimeline.to(".poster-content", {
    scale: 1,
    ease: Power1.easeOut,
    duration: 0.5,
  });
  return localTimeline;
};
