import React, {useState} from 'react';
import loaderImg from "../../../images/imgloader.gif";
import ReactPlayer from "react-player";
const TemplateImage = (props) => {
    console.log(props, 'imagesssssssssssss')

    const [loader, setLoader] = useState(true);
    const loadImage = (e) => {
        setLoader(false);
    }
    return (
        <>
            {
                props.slideType === "image" ?
                    <img
                        src={loader === true ? loaderImg : props.file}
                        alt="Woman"
                        className="img-fluid"
                        onLoad={(e)=>loadImage(e)}
                    />
                    : <ReactPlayer width="100%" height="100%" url={props.file} playing={true} muted={true} className="img"/>
            }
        </>
    );
};

export default TemplateImage;
