import React from "react";
import { Regular5Style } from "./css/Regular5Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const regular_6_5 = (props) => {
  return (
    <Regular5Style>
      <div className="container6">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="two-layer-bg">
          <div className="col-1" />
          <div className="col-2" />
        </div>
        <div className="front-layer">
          <div className="col-1">
            <div className="wrap">
              <div className="img-wrapper">
                <TemplateImage slideType={props.slideType} file={props.file} />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="text-wrapper">
              <TemplateText
                id={props.id}
                text={props.text}
                textStyle={props.textStyle}
                editStatus={props.editStatus}
                onInputSlideText={props.onInputSlideText}
              />
            </div>
          </div>
        </div>
      </div>
    </Regular5Style>
  );
};

export default regular_6_5;
