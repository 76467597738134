import React, {useState} from "react";
import facebookImg from "../../images/Facebook-icon.png"
import instagramImg from "../../images/Instagram-Icon.png"
import igTvImg from "../../images/Igtv-Icon.png";
import whatsAppImg from "../../images/Whatsapp-Icon.png";
import youtubeImg from "../../images/Youtube-Icon.png";
import vimeoImg from "../../images/Vimeo-Icon.png";
import snapChatImg from "../../images/Snapchat-logo.png";
import videosImg from "../../images/VIDEOS.png";
import videosImgs from "../../images/VIDEOSs.png";
import img2 from "../../images/2.png";
import {useDispatch} from "react-redux";
import {addScriptDimension} from "../../actions/scriptAction";
import {useHistory} from "react-router-dom";

const DimensionPopUp = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const [vidSize, setVidSize] = useState('16:9');

    const selectDimension = (size) => {
        props.setDimension(size);

        dispatch(addScriptDimension(size));
        setVidSize(size);
    }

    const onButtonClick = () => {
        props.setStatusDim(false);

        history.push('/templates');
    }

    return (
        <>
            <div className={`modal customModal ${props.statusDim ? 'show-modal' : ''}`} tabIndex={-1} role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center mx-auto ">
                                Choose a video <span>Dimension</span>{" "}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={(e) => props.setStatusDim(false)}
                            >
                                <span aria-hidden="true" style={{ color: "#fff" }}>
                                    <i className="fas fa-times-circle" />
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-scrollbar">
                                <div className="row">
                                    <div className="col-md-11 mx-auto">
                                        {/*<h6>*/}
                                        {/*    <span className="mr-3">*/}
                                        {/*      <img src={facebookImg} />*/}
                                        {/*    </span>*/}
                                        {/*    Facebook*/}
                                        {/*</h6>*/}
                                        <div className={`facebook-item`}>
                                            <div className="row mt-4 d-flex justify-content-around">
                                                <div className="col-md-3 p-2 text-center">
                                                    <div className={`facebook-item-box p-4 ${vidSize === '16:9' ? 'active-dim' : ''}`} onClick={(e) => selectDimension('16:9')}>
                                                        <img
                                                            src={videosImg}
                                                            alt="fb-videos"
                                                            className="img-fluid"
                                                        />
                                                        <h5 className="mt-5">Regular</h5>
                                                        <h6 className="m-0">16:9 ratio</h6>
                                                        <div className="mt-1">
                                                            <img className="mr-1" style={{width: "20px"}} src={facebookImg} />
                                                            <img className="mr-1" style={{width: "20px"}} src={igTvImg} />
                                                            <img className="mr-1" style={{width: "20px"}} src={vimeoImg} />
                                                            <img className="mr-1" style={{width: "20px"}} src={youtubeImg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 p-2 text-center">
                                                    <div className={`facebook-item-box p-4 ${vidSize === '9:16' ? 'active-dim' : ''}`} onClick={(e) => selectDimension('9:16')}>
                                                        <img
                                                            src={img2}
                                                            alt="fb-videos"
                                                            className="img-fluid"
                                                        />
                                                        <h5>Story</h5>
                                                        <h6 className="m-0">9:16 ratio</h6>
                                                        <div className="mt-1">
                                                            <img className="mr-1" style={{width: "20px"}} src={facebookImg} />
                                                            <img className="mr-1" style={{width: "20px"}} src={instagramImg} />
                                                            <img className="mr-1" style={{width: "20px"}} src={snapChatImg} />
                                                            <img className="mr-1" style={{width: "20px"}} src={whatsAppImg} />
                                                            <img className="mr-1" style={{width: "20px"}} src={youtubeImg} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3 p-2 text-center">
                                                    <div className={`facebook-item-box p-4 ${vidSize === '1:1' ? 'active-dim' : ''}`} onClick={(e) => selectDimension('1:1')}>
                                                        <img
                                                            src={videosImgs}
                                                            alt="fb-videos"
                                                            className="img-fluid"
                                                        />
                                                        <h5 className="mt-4">Square</h5>
                                                        <h6 className="m-0">1:1 ratio</h6>
                                                        <div className="mt-1">
                                                            <img className="mr-1" style={{width: "20px"}} src={facebookImg} />
                                                            <img className="mr-1" style={{width: "20px"}} src={instagramImg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer custom-next">
                            <button type="button" className="btn-change7" disabled={vidSize==='' ? true : false}
                                    onClick={(e)=>onButtonClick(e)}>
                                Next
                                <span className="ml-2"><i className="fa fa-angle-double-right"></i></span>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default DimensionPopUp;