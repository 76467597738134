import styled from "styled-components";

export const Portait8Style = styled.div`
width: 100%;
height: 100%;

.img-fluid {
  display: block;
  max-width: 100%;
}

.container10 {
  width: 100%;
height: 100%;
  overflow: hidden;
  clip-path: circle(0% at 50% 50%);
}

.back-layer {
  width: 100%;
  height: 100%;
  background: -moz-linear-gradient(top,  rgba(255,38,252) 0%, rgba(255,245,128) 100%);
  background: -webkit-linear-gradient(top,  rgba(255,38,252) 0%,rgba(255,245,128) 100%);
  background: linear-gradient(to bottom,  rgba(255,38,252) 0%,rgba(255,245,128) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccff26fc', endColorstr='#ccfff580',GradientType=0 );
}

.back-layer img,
  .back-layer video{
  width: 100%;
height: 100%;
  object-fit: cover;
  object-position: center;    
  opacity: 0.5;
}

.text-wrapper {
  position: absolute;
  bottom: 0;
  top: 0;
  z-index: 1;
  width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.text-wrapper div {
 width:100%;
  text-align: center;
}

.text-wrapper p.desc:not(:last-child) {
  margin-bottom: 1.5vh;
}

.text-wrapper p.presented{
  font-size: 2vw;
  margin-top: 4vh;
}
`;
