import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";

export const regular_12_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper', {scale: 0.2}, {scale: 1.1, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};

export const regular_12_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.video-container', {width: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.fromTo('.text-wrapper', {scale: 0}, {scale: 1.2, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};

export const regular_12_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.video-container', {width: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.fromTo('.text-wrapper', {scale: 0}, {scale: 1.2, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};

export const regular_12_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper div',{autoAlpha:0}, {autoAlpha: 1, ease:Power1.easeOut, stagger: .7})
  return localTimeline;
};

export const regular_12_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.text-wrapper', {opacity: 1, ease:Power1.easeOut, duration: .8})
  return localTimeline;
};

export const regular_12_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.overlay.black', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.white', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.blue', {width: 0, ease:Linear.easeNone, duration: .3})
  return localTimeline;
};

export const regular_12_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.overlay.black', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.white', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.blue', {width: 0, ease:Linear.easeNone, duration: .3})
  return localTimeline;
};
export const regular_12_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{autoAlpha:0, top:'-30%'}, {top: '0',autoAlpha:1, ease:Power1.easeOut, duration: .8})
  return localTimeline;
};

export const square_12_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper', {scale: 0.2}, {scale: 1.2, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};
export const square_12_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.video-container', {width: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.fromTo('.text-wrapper', {scale: 0}, {scale: 1.2, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};

export const square_12_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.video-container', {width: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.fromTo('.text-wrapper', {scale: 0}, {scale: 1.2, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};

export const square_12_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper > div',{autoAlpha:0}, {autoAlpha: 1, ease:Power1.easeOut, stagger: .7})
  return localTimeline;
};

export const square_12_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{autoAlpha:0}, {autoAlpha: 1, ease:Power1.easeOut, duration: .8})
  return localTimeline;
};

export const square_12_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.overlay.black', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.white', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.blue', {width: 0, ease:Linear.easeNone, duration: .3})
  return localTimeline;
};

export const square_12_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.overlay.black', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.white', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.blue', {width: 0, ease:Linear.easeNone, duration: .3})
  return localTimeline;
};
export const square_12_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{top:'-20%',autoAlpha:0}, {top: '0',autoAlpha:1, ease:Power1.easeOut, duration: .8})
  return localTimeline;
};

export const story_12_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper', {scale: 0.2}, {scale: 1.2, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};

export const story_12_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.video-container', {width: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.fromTo('.text-wrapper', {scale: 0}, {scale: 1.2, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};

export const story_12_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.video-container', {width: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.fromTo('.text-wrapper', {scale: 0}, {scale: 1.2, ease:Power1.easeIn, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 0.9, ease:Power1.easeOut, duration: .5})
  localTimeline.to('.text-wrapper', {scale: 1, ease:Linear.easeNone, duration: .5})
  return localTimeline;
};

export const story_12_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper > div',{autoAlpha:0}, {autoAlpha: 1, ease:Power1.easeOut, stagger: .7})
  return localTimeline;
};

export const story_12_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.text-wrapper', {opacity: 1, ease:Power1.easeOut, duration: 1.5})
  return localTimeline;
};

export const story_12_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.overlay.black', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.white', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.blue', {width: 0, ease:Linear.easeNone, duration: .3})
  return localTimeline;
};

export const story_12_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.overlay.black', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.white', {width: 0, ease:Linear.easeNone, duration: .3})
  localTimeline.to('.overlay.blue', {width: 0, ease:Linear.easeNone, duration: .3})
  return localTimeline;
};

export const story_12_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper ',{autoAlpha:0, top:'-10%'}, {top:'0',autoAlpha:1, ease:Power1.easeOut, duration: .8})
  return localTimeline;
};
