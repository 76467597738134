import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";
export const regular_4_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    { duration: 3, scale: 1.2, ease: Linear.easeNone },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200%" },
    { duration: 1, marginLeft: 0, autoAlpha: 1, left: 0, stagger: 0.3 },
    1
  );
  return localTimeline;
};
export const regular_4_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1 }, 1);
  localTimeline.to(
    ".back-layer",
    { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }
  );
  return localTimeline;
};

export const regular_4_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { duration: 1, css: { width: "50%" } }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200%", autoAlpha: 0 },
    { duration: 0.4, autoAlpha: 1, marginLeft: 0, stagger: 0.1 },
    +2
  );
  return localTimeline;
};

export const regular_4_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { duration: 1, css: { left: "50%" } }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginRight: "-200%", autoAlpha: 0 },
    { duration: 0.4, autoAlpha: 1, marginRight: 0, stagger: 0.1 },
    +2
  );
  return localTimeline;
};

export const regular_4_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { clipPath: "inset(0% 0% 0% 0%)" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200%" },
    { autoAlpha: 1, duration: 1, marginLeft: 0, stagger: 0.5 },
    +2
  );
  return localTimeline;
};

export const regular_4_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    { duration: 1, clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)" },
    1
  );
  localTimeline.to(".back-layer img", { duration: 5, left: 0 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-200%" },
    { duration: 1, autoAlpha: 1, top: 0, ease: "expo.out", marginTop: "0" },
    +2
  );

  return localTimeline;
};

export const regular_4_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".front-layer",
    {
      duration: 0.8,
      ease: "none",
      clipPath:
        "polygon(20% 0, 20% 0, 50% 50%, 20% 100%, 20% 100%, 50% 50%, 80% 100%, 80% 100%, 50% 50%, 80% 0, 80% 0, 50% 50%)",
    },
    1
  );
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { duration: 1, autoAlpha: 1, stagger: 0.8 },
    +2
  );
  return localTimeline;
};
export const regular_4_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200%" },
    { duration: 1, autoAlpha: 1, marginLeft: 0, stagger: 0.2 }
  );
  return localTimeline;
};

export const square_4_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    { duration: 3, scale: 1.2, ease: Linear.easeNone },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200%" },
    { duration: 1, marginLeft: 0, autoAlpha: 1, left: 0, stagger: 0.3 },
    1
  );
  return localTimeline;
};
export const square_4_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1 }, 1);
  localTimeline.to(
    ".back-layer",
    { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }
  );
  return localTimeline;
};

export const square_4_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { duration: 1, css: { width: "50%" } }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200%", autoAlpha: 0 },
    { duration: 0.4, autoAlpha: 1, marginLeft: 0, stagger: 0.1 },
    +2
  );
  return localTimeline;
};

export const square_4_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { duration: 1, css: { left: "50%" } }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginRight: "-200%", autoAlpha: 0 },
    { duration: 0.4, autoAlpha: 1, marginRight: 0, stagger: 0.1 },
    +2
  );
  return localTimeline;
};

export const square_4_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { clipPath: "inset(0% 0% 0% 0%)" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0,marginLeft: "-200%" },
    { autoAlpha: 1, duration: 1, left: 0, stagger: 0.5,marginLeft: "0" },
    +2
  );
  return localTimeline;
};

export const square_4_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    { duration: 1, clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)" },
    1
  );
  localTimeline.to(".back-layer img", { duration: 5, left: 0 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-200%" },
    { duration: 1, autoAlpha: 1, top: 0, ease: "expo.out", marginTop: "0" },
    +2
  );
  return localTimeline;
};

export const square_4_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".front-layer",
    {
      duration: 0.8,
      ease: "none",
      clipPath:
        "polygon(20% 0, 20% 0, 50% 50%, 20% 100%, 20% 100%, 50% 50%, 80% 100%, 80% 100%, 50% 50%, 80% 0, 80% 0, 50% 50%)",
    },
    1
  );
  localTimeline.to(".back-layer img", { duration: 3, scale: 1.2 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { duration: 1, autoAlpha: 1, stagger: 0.8 },
    +2
  );
  return localTimeline;
};
export const square_4_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 2, scale: 1.2 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200%" },
    { duration: 1, autoAlpha: 1, marginLeft: 0, stagger: 0.2 }
  );
  return localTimeline;
};

export const story_4_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    { duration: 3, scale: 1.2, ease: Linear.easeNone },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200%" },
    { duration: 1, marginLeft: 0, autoAlpha: 1, left: 0, stagger: 0.3 },
    1
  );
  return localTimeline;
};
export const story_4_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 3, scale: 1 }, 1);
  localTimeline.to(
    ".back-layer",
    { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" },
    1
  );
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { autoAlpha: 1, clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)" }
  );
  return localTimeline;
};

export const story_4_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { duration: 1, css: { width: "50%" } }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200%", autoAlpha: 0 },
    { duration: 0.4, autoAlpha: 1, marginLeft: 0, stagger: 0.1 },
    +2
  );
  return localTimeline;
};

export const story_4_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { duration: 1, css: { left: "50%" } }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginRight: "-200%", autoAlpha: 0 },
    { duration: 0.4, autoAlpha: 1, marginRight: 0, stagger: 0.1 },
    +2
  );
  return localTimeline;
};

export const story_4_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer", { duration: 3, scale: 1.2 }, 1);
  localTimeline.to(".front-layer", { clipPath: "inset(0% 0% 0% 0%)" }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0,marginLeft: "-200%"},
    { autoAlpha: 1, duration: 1, marginLeft: 0, stagger: 0.5 },
    +2
  );
  return localTimeline;
};

export const story_4_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".back-layer",
    { duration: 1, clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)" },
    1
  );
  localTimeline.to(".back-layer img", { duration: 5, left: 0 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginTop: "-200%"},
    { duration: 1, autoAlpha: 1, top: 0, ease: "expo.out" ,marginTop: "0"},
    +2
  );
  return localTimeline;
};

export const story_4_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".front-layer",
    {
      duration: 0.8,
      ease: "none",
      clipPath:
        "polygon(20% 0, 20% 0, 50% 50%, 20% 100%, 20% 100%, 50% 50%, 80% 100%, 80% 100%, 50% 50%, 80% 0, 80% 0, 50% 50%)",
    },
    1
  );
  localTimeline.to(".back-layer img", { duration: 2, scale: 1.2 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    { duration: 1, autoAlpha: 1, stagger: 0.8 },
    +2
  );
  return localTimeline;
};
export const story_4_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".back-layer img", { duration: 2, scale: 1.2 }, 1);
  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0, marginLeft: "-200%" },
    { duration: 1, autoAlpha: 1, marginLeft: 0, stagger: 0.2 }
  );
  return localTimeline;
};
