let initialState = {
    data: []
}

export default function (state = initialState, action){
    switch (action.type){

        case 'ADD_MERGE_VIDEOS':
            return{
                ...state,
                data: action.payload
            }

        default :
            return state

    }
}