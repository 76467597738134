import React, {useEffect, useState} from "react";
import VideoCard from "../project/VideoCard";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {baseURL} from "../../global/global";

const DashboardContent = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const campaigns = useSelector(state => state.campaigns);
    const [loadProject, setLoadProject] = useState(false);
    const [campData, setCampData] = useState(campaigns.data);

    const fetchCampaigns = () => {
        setLoadProject(true);

        axios({
            method: "POST",
            url: `${baseURL}fetch-campaigns`,
            data: {},
            headers: {'Content-Type': 'application/json', 'Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setCampData(res.data.data)
            }
            setLoadProject(false);
        }).catch(err=>{
            setLoadProject(false);
        })
    }

    useEffect(()=>{
        fetchCampaigns();
    },[])

    return (
        <>
            <section className="banner-video-project">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="video-project text-white align-items-center d-flex justify-content-between">
                                <h5 className="m-0">Video <span>Projects</span></h5>
                                <Link to="/projects">View all <span><i className="fas fa-chevron-circle-right"></i></span></Link>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-5" style={{position: "relative"}}>
                        {
                            campData.length > 0 ?
                                campData.slice(0, 8).map((item,index)=>{
                                    return(
                                        <VideoCard item={item} fetchCampaigns={fetchCampaigns}  key={item.id} />
                                    )
                                })
                            : ''
                        }
                        {
                            loadProject && campData.length===0 ? <div className="loader-center text-center" style={{fontSize: "20px", color: "#ffffff"}}><i className="fa fa-spinner fa-spin mr-2"/> Fetching Campaigns</div> : ''
                        }
                        {
                            campData.length===0 && loadProject===false ? <div className="text-center" style={{fontSize: "20px", color: "#ffffff"}}>No campaigns created yet</div> : ''
                        }
                    </div>
                </div>
            </section>
        </>
)
}

export default DashboardContent;