import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeEmail} from "../../actions/authAction";
import Alert from "../Alert";

const PrivacyEmail = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [userDetails, setUserDetails] = useState({
        email: '',
    })

    const onInputChange = (e) => {
        setUserDetails({...userDetails, email: e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(changeEmail(userDetails, setLoader));
    }

    return(
        <>
            <Alert/>
            <div className="row mt-5">
                <div className="col-md-12">
                    <h5 className="text-white">Change Email</h5>
                </div>
            </div>

            <form className="gdpr-update-email mt-3" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">Current Email Address</small>
                            <input type="email" className="form-control"
                                   placeholder="Enter Current Email Address"
                                   value={auth.user?.email} readOnly
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <small className="form-text text-muted">New Email Address</small>
                            <input type="email" className="form-control"
                                   placeholder="Enter Email Address" required name="email"
                                   onChange={(e)=>onInputChange(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12 d-flex justify-content-center align-items-center ">
                        <div className="gdpr-update-btn">
                            <button type="submit" className="btn-change7" disabled={loader}>
                                { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Update
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default PrivacyEmail;