import styled from "styled-components";

export const Portait7Style = styled.div`
  width:100%;
  height:100%;
  .container3 {
    width:100%;
    height:100%;
    overflow: hidden;
    /* background: #a1dffb; */
  }

  .f-red {
    color: #c33124;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .back-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    transform: scale(1.05);
  }

  .front-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    background: -moz-linear-gradient(
            bottom,
            rgba(255, 255, 255, 0) 65%,
            rgba(161, 223, 251, 1) 100%
    );
    background: -webkit-linear-gradient(
            bottom,
            rgba(255, 255, 255, 0) 65%,
            rgba(161, 223, 251, 1) 100%
    );
    background: linear-gradient(
            to top,
            rgba(255, 255, 255, 0) 65%,
            rgba(161, 223, 251, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#a1dffb',GradientType=0 );
    top: -50%;
  }




  .text-wrapper {
    position: absolute;
    width: 100%;
    height:100%;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
`;
