import styled from "styled-components";

export const Portait8Style = styled.div`
.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: left;
  justify-content: left;
}

.subflex-left {
  width: 100%;
  text-align: left;
}

.subflex-right {
  width: 100%;
  text-align: right;
}

.subflex-center {
  width: 100%;
  text-align: center;
}
.text-wrapper {
  white-space: pre-wrap;
}
/*.f-arial {*/
/*	font-family: Arial;*/
/*}*/

/*.f-mvboli {*/
/*	font-family: mvboli;*/
/*}*/

/*.f-nexalight {*/
/*	font-family: nexalight;*/
/*}*/

/*.f-nexabold {*/
/*	font-family: nexabold;*/
/*}*/

.f-cyan {
  color: #00fffc;
}

.f-cyan-dark {
  color: #00d1cf;
}

.f-blue {
  color: #005596;
}

.f-white {
  color: #ffffff;
}

.poster {
  position: relative;
  overflow: hidden !important;
}

.poster.nine-by-sixteen {
  width: 1080px;
  height: 1920px;
}

.poster.one-by-one {
  width: 310px;
  height: 310px;
}

.poster.nine-by-sixteen {
  width: 171px;
  height: 304px;
}

.poster.nine-by-sixteen.eight .bg {
  position: absolute;
  height: 140%;
  width: 140%;
  left: -10%;
  top: -21%;
}

.poster.nine-by-sixteen.eight .ta-outer {
  position: relative;
  width: fit-content;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
}

.poster.nine-by-sixteen.eight .ta {
  width: fit-content;
  font-size: 48px;
  margin: 0;
}

.poster.nine-by-sixteen.eight .bg {
  position: absolute;
  height: 140%;
  width: 140%;
  left: -10%;
  top: -20%;
}

.poster.nine-by-sixteen.eight .text-wrapper {
  position: absolute;
  left: 0;
  bottom: 18px;
  box-sizing: content-box;
  /*padding-left: 15px;*/
  width: 100%;
  height: 70px;
}

.poster.nine-by-sixteen.eight .ta-outer {
  position: relative;
  width: fit-content;
  background-color: #fff;
  margin-bottom: 2px;
  padding: 2px 3px;
  overflow: hidden;
  white-space: nowrap;
}

.poster.nine-by-sixteen.eight .ta {
  width: fit-content;
  font-size: 7px;
  margin: 0;
}
`;
