import React from "react";

const OutroLogo = (props) => {
  return (
    <>
      {props.logo !== false ? (
        <div className={`center-logo`}>
          <img src={props.logo} alt="logo" className="logo img-fluid" />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default OutroLogo;
