let initialState = {
    data: false,
    dimension: '16:9',
    template_id: false,
    campaign_type: "copy",
    url: false
}

export default function (state = initialState, action){
    switch (action.type){

        case 'ADD_SCRIPT':
            return {
                ...state,
                data: action.payload
            }

        case 'ADD_DIMENSION':
            return {
                ...state,
                dimension: action.payload
            }

        case 'ADD_CAMPAIGN_TYPE':
            return {
                ...state,
                campaign_type: action.payload
            }

        case 'ADD_URL':
            return {
                ...state,
                url: action.payload
            }

        case 'ADD_TEMPLATE_ID':
            return {
                ...state,
                template_id: action.payload
            }

        case 'REMOVE_CAMP_DATA':
            return {
                ...state,
                campaign_type: "copy",
                url: false
            }

        case 'REMOVE_SCRIPT_DATA':
            return {
                ...state,
                data: false,
                dimension: '16:9',
                template_id: false
            }

        default :
            return state

    }
}