import axios from "axios";
import {baseURL} from "../global/global";

export const addMergeVideos = (token) => (dispatch) => {
    axios({
        method: "POST",
        url: `${baseURL}fetch-merge-campaigns`,
        data: {},
        headers: {'Content-Type': 'application/json', 'Authorization': token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'ADD_MERGE_VIDEOS', payload: res.data.data.data});
        }
    }).catch(err=>{

    })
}