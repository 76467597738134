import React from "react";
import { Square6Style } from "./css/Square6Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return (
    <Square6Style>
      <div className="poster six one-by-one">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="flex-column">
          <div className="photo-container flex-column">
            <div className="photo">
              <TemplateImage slideType={props.slideType} file={props.file} />
            </div>
          </div>
          <div className="subflex-center text-wrappers">
            <TemplateText
              id={props.id}
              text={props.text}
              textStyle={props.textStyle}
              editStatus={props.editStatus}
              onInputSlideText={props.onInputSlideText}
            />
          </div>
        </div>
      </div>
    </Square6Style>
  );
};

export default Template;
