import styled from "styled-components";

export const Portait3Style = styled.div`
.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: left;
  justify-content: left;
}

.subflex-left {
  width: 100%;
  text-align: left;
}

.subflex-right {
  width: 100%;
  text-align: right;
}

.subflex-center {
  width: 100%;
  text-align: center;
}
.t3-mob-text-wrapper {
  white-space: pre-wrap;
}
/*.f-arial {*/
/*	font-family: Arial;*/
/*}*/

/*.f-mvboli {*/
/*	font-family: mvboli;*/
/*}*/

/*.f-nexalight {*/
/*	font-family: nexalight;*/
/*}*/

/*.f-nexabold {*/
/*	font-family: nexabold;*/
/*}*/

.f-cyan {
  color: #00fffc;
}

.f-cyan-dark {
  color: #00d1cf;
}

.f-blue {
  color: #005596;
}

.f-white {
  color: #ffffff;
}

.poster {
  position: relative;
  overflow: hidden !important;
}

.poster.nine-by-sixteen {
  width: 1080px;
  height: 1920px;
}

.poster.one-by-one {
  width: 310px;
  height: 310px;
}

.poster.nine-by-sixteen {
  width: 171px;
  height: 304px;
}

.poster.nine-by-sixteen .bg.three {
  position: absolute;
  height: 140%;
  width: 140%;
  top: -23%;
  left: -25%;
}

.poster-content.three {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: #005596;
}

.poster-content.three::after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #00fffc;
}

.poster-content.three .t3-mob-text-wrapper {
  height: 80%;
  width: 100px;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  overflow: hidden;
}

.poster-content.three .ta {
  margin: 0 -100% 0 0;
  text-align: right;
}

.poster.nine-by-sixteen .poster-content.three .ta {
  font-size: 80px;
  line-height: 100px;
}

.poster.nine-by-sixteen .poster-content.three .ta {
  font-size: 12px !important;
  line-height: 16px;
}

.poster.nine-by-sixteen .bg.three {
  background-position: -215px center;
}

.poster-content.three::after {
  height: 10px;
}

  .text-wrappers {
    position:absolute;
    width:100%;
    height:100%
  }
  .text-wrappers > div {
    width:100%;
    overflow: hidden;
  } 
`;
