let OutroComponents = {};

OutroComponents["regular"] = {};
OutroComponents["square"] = {};
OutroComponents["story"] = {};

/* Outro 1 */
OutroComponents["regular"]["1"] = require("./../Outro/1/regular_1").default;
OutroComponents["regular"]["2"] = require("./../Outro/1/regular_2").default;
OutroComponents["regular"]["3"] = require("./../Outro/1/regular_3").default;
OutroComponents["regular"]["4"] = require("./../Outro/1/regular_4").default;

OutroComponents["square"]["1"] = require("./../Outro/1/square_1").default;
OutroComponents["square"]["2"] = require("./../Outro/1/square_2").default;
OutroComponents["square"]["3"] = require("./../Outro/1/square_3").default;
OutroComponents["square"]["4"] = require("./../Outro/1/square_4").default;

OutroComponents["story"]["1"] = require("./../Outro/1/story_1").default;
OutroComponents["story"]["2"] = require("./../Outro/1/story_2").default;
OutroComponents["story"]["3"] = require("./../Outro/1/story_3").default;
OutroComponents["story"]["4"] = require("./../Outro/1/story_4").default;


export default OutroComponents