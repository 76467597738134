let TemplateComponents = {};

TemplateComponents["regular"] = {};
TemplateComponents["square"] = {};
TemplateComponents["story"] = {};

/* Template 1 */
TemplateComponents["regular"]["1-1"] =
  require("./../Template/1/regular_1_1").default;
TemplateComponents["regular"]["1-2"] =
  require("./../Template/1/regular_1_2").default;
TemplateComponents["regular"]["1-3"] =
  require("./../Template/1/regular_1_3").default;
TemplateComponents["regular"]["1-4"] =
  require("./../Template/1/regular_1_4").default;
TemplateComponents["regular"]["1-5"] =
  require("./../Template/1/regular_1_5").default;
TemplateComponents["regular"]["1-6"] =
  require("./../Template/1/regular_1_6").default;
TemplateComponents["regular"]["1-7"] =
  require("./../Template/1/regular_1_7").default;
TemplateComponents["regular"]["1-8"] =
  require("./../Template/1/regular_1_8").default;

TemplateComponents["square"]["1-1"] =
  require("./../Template/1/square_1_1").default;
TemplateComponents["square"]["1-2"] =
  require("./../Template/1/square_1_2").default;
TemplateComponents["square"]["1-3"] =
  require("./../Template/1/square_1_3").default;
TemplateComponents["square"]["1-4"] =
  require("./../Template/1/square_1_4").default;
TemplateComponents["square"]["1-5"] =
  require("./../Template/1/square_1_5").default;
TemplateComponents["square"]["1-6"] =
  require("./../Template/1/square_1_6").default;
TemplateComponents["square"]["1-7"] =
  require("./../Template/1/square_1_7").default;
TemplateComponents["square"]["1-8"] =
  require("./../Template/1/square_1_8").default;

TemplateComponents["story"]["1-1"] =
  require("./../Template/1/story_1_1").default;
TemplateComponents["story"]["1-2"] =
  require("./../Template/1/story_1_2").default;
TemplateComponents["story"]["1-3"] =
  require("./../Template/1/story_1_3").default;
TemplateComponents["story"]["1-4"] =
  require("./../Template/1/story_1_4").default;
TemplateComponents["story"]["1-5"] =
  require("./../Template/1/story_1_5").default;
TemplateComponents["story"]["1-6"] =
  require("./../Template/1/story_1_6").default;
TemplateComponents["story"]["1-7"] =
  require("./../Template/1/story_1_7").default;
TemplateComponents["story"]["1-8"] =
  require("./../Template/1/story_1_8").default;

/* Template 2 */
TemplateComponents["regular"]["2-1"] =
  require("./../Template/2/regular_2_1").default;
TemplateComponents["regular"]["2-2"] =
  require("./../Template/2/regular_2_2").default;
TemplateComponents["regular"]["2-3"] =
  require("./../Template/2/regular_2_3").default;
TemplateComponents["regular"]["2-4"] =
  require("./../Template/2/regular_2_4").default;
TemplateComponents["regular"]["2-5"] =
  require("./../Template/2/regular_2_5").default;
TemplateComponents["regular"]["2-6"] =
  require("./../Template/2/regular_2_6").default;
TemplateComponents["regular"]["2-7"] =
  require("./../Template/2/regular_2_7").default;
TemplateComponents["regular"]["2-8"] =
  require("./../Template/2/regular_2_8").default;

TemplateComponents["square"]["2-1"] =
  require("./../Template/2/square_2_1").default;
TemplateComponents["square"]["2-2"] =
  require("./../Template/2/square_2_2").default;
TemplateComponents["square"]["2-3"] =
  require("./../Template/2/square_2_3").default;
TemplateComponents["square"]["2-4"] =
  require("./../Template/2/square_2_4").default;
TemplateComponents["square"]["2-5"] =
  require("./../Template/2/square_2_5").default;
TemplateComponents["square"]["2-6"] =
  require("./../Template/2/square_2_6").default;
TemplateComponents["square"]["2-7"] =
  require("./../Template/2/square_2_7").default;
TemplateComponents["square"]["2-8"] =
  require("./../Template/2/square_2_8").default;

TemplateComponents["story"]["2-1"] =
  require("./../Template/2/story_2_1").default;
TemplateComponents["story"]["2-2"] =
  require("./../Template/2/story_2_2").default;
TemplateComponents["story"]["2-3"] =
  require("./../Template/2/story_2_3").default;
TemplateComponents["story"]["2-4"] =
  require("./../Template/2/story_2_4").default;
TemplateComponents["story"]["2-5"] =
  require("./../Template/2/story_2_5").default;
TemplateComponents["story"]["2-6"] =
  require("./../Template/2/story_2_6").default;
TemplateComponents["story"]["2-7"] =
  require("./../Template/2/story_2_7").default;
TemplateComponents["story"]["2-8"] =
  require("./../Template/2/story_2_8").default;

/* Template 3 */
TemplateComponents["regular"]["3-1"] =
  require("./../Template/3/regular_3_1").default;
TemplateComponents["regular"]["3-2"] =
  require("./../Template/3/regular_3_2").default;
TemplateComponents["regular"]["3-3"] =
  require("./../Template/3/regular_3_3").default;
TemplateComponents["regular"]["3-4"] =
  require("./../Template/3/regular_3_4").default;
TemplateComponents["regular"]["3-5"] =
  require("./../Template/3/regular_3_5").default;
TemplateComponents["regular"]["3-6"] =
  require("./../Template/3/regular_3_6").default;
TemplateComponents["regular"]["3-7"] =
  require("./../Template/3/regular_3_7").default;
TemplateComponents["regular"]["3-8"] =
  require("./../Template/3/regular_3_8").default;

TemplateComponents["square"]["3-1"] =
  require("./../Template/3/square_3_1").default;
TemplateComponents["square"]["3-2"] =
  require("./../Template/3/square_3_2").default;
TemplateComponents["square"]["3-3"] =
  require("./../Template/3/square_3_3").default;
TemplateComponents["square"]["3-4"] =
  require("./../Template/3/square_3_4").default;
TemplateComponents["square"]["3-5"] =
  require("./../Template/3/square_3_5").default;
TemplateComponents["square"]["3-6"] =
  require("./../Template/3/square_3_6").default;
TemplateComponents["square"]["3-7"] =
  require("./../Template/3/square_3_7").default;
TemplateComponents["square"]["3-8"] =
  require("./../Template/3/square_3_8").default;

TemplateComponents["story"]["3-1"] =
  require("./../Template/3/story_3_1").default;
TemplateComponents["story"]["3-2"] =
  require("./../Template/3/story_3_2").default;
TemplateComponents["story"]["3-3"] =
  require("./../Template/3/story_3_3").default;
TemplateComponents["story"]["3-4"] =
  require("./../Template/3/story_3_4").default;
TemplateComponents["story"]["3-5"] =
  require("./../Template/3/story_3_5").default;
TemplateComponents["story"]["3-6"] =
  require("./../Template/3/story_3_6").default;
TemplateComponents["story"]["3-7"] =
  require("./../Template/3/story_3_7").default;
TemplateComponents["story"]["3-8"] =
  require("./../Template/3/story_3_8").default;

/* Template 4 */
TemplateComponents["regular"]["4-1"] =
  require("./../Template/4/regular_4_1").default;
TemplateComponents["regular"]["4-2"] =
  require("./../Template/4/regular_4_2").default;
TemplateComponents["regular"]["4-3"] =
  require("./../Template/4/regular_4_3").default;
TemplateComponents["regular"]["4-4"] =
  require("./../Template/4/regular_4_4").default;
TemplateComponents["regular"]["4-5"] =
  require("./../Template/4/regular_4_5").default;
TemplateComponents["regular"]["4-6"] =
  require("./../Template/4/regular_4_6").default;
TemplateComponents["regular"]["4-7"] =
  require("./../Template/4/regular_4_7").default;
TemplateComponents["regular"]["4-8"] =
  require("./../Template/4/regular_4_8").default;

TemplateComponents["square"]["4-1"] =
  require("./../Template/4/square_4_1").default;
TemplateComponents["square"]["4-2"] =
  require("./../Template/4/square_4_2").default;
TemplateComponents["square"]["4-3"] =
  require("./../Template/4/square_4_3").default;
TemplateComponents["square"]["4-4"] =
  require("./../Template/4/square_4_4").default;
TemplateComponents["square"]["4-5"] =
  require("./../Template/4/square_4_5").default;
TemplateComponents["square"]["4-6"] =
  require("./../Template/4/square_4_6").default;
TemplateComponents["square"]["4-7"] =
  require("./../Template/4/square_4_7").default;
TemplateComponents["square"]["4-8"] =
  require("./../Template/4/square_4_8").default;

TemplateComponents["story"]["4-1"] =
  require("./../Template/4/story_4_1").default;
TemplateComponents["story"]["4-2"] =
  require("./../Template/4/story_4_2").default;
TemplateComponents["story"]["4-3"] =
  require("./../Template/4/story_4_3").default;
TemplateComponents["story"]["4-4"] =
  require("./../Template/4/story_4_4").default;
TemplateComponents["story"]["4-5"] =
  require("./../Template/4/story_4_5").default;
TemplateComponents["story"]["4-6"] =
  require("./../Template/4/story_4_6").default;
TemplateComponents["story"]["4-7"] =
  require("./../Template/4/story_4_7").default;
TemplateComponents["story"]["4-8"] =
  require("./../Template/4/story_4_8").default;

/* Template 5 */
TemplateComponents["regular"]["5-1"] =
  require("./../Template/5/regular_5_1").default;
TemplateComponents["regular"]["5-2"] =
  require("./../Template/5/regular_5_2").default;
TemplateComponents["regular"]["5-3"] =
  require("./../Template/5/regular_5_3").default;
TemplateComponents["regular"]["5-4"] =
  require("./../Template/5/regular_5_4").default;
TemplateComponents["regular"]["5-5"] =
  require("./../Template/5/regular_5_5").default;
TemplateComponents["regular"]["5-6"] =
  require("./../Template/5/regular_5_6").default;
TemplateComponents["regular"]["5-7"] =
  require("./../Template/5/regular_5_7").default;
TemplateComponents["regular"]["5-8"] =
  require("./../Template/5/regular_5_8").default;

TemplateComponents["square"]["5-1"] =
  require("./../Template/5/square_5_1").default;
TemplateComponents["square"]["5-2"] =
  require("./../Template/5/square_5_2").default;
TemplateComponents["square"]["5-3"] =
  require("./../Template/5/square_5_3").default;
TemplateComponents["square"]["5-4"] =
  require("./../Template/5/square_5_4").default;
TemplateComponents["square"]["5-5"] =
  require("./../Template/5/square_5_5").default;
TemplateComponents["square"]["5-6"] =
  require("./../Template/5/square_5_6").default;
TemplateComponents["square"]["5-7"] =
  require("./../Template/5/square_5_7").default;
TemplateComponents["square"]["5-8"] =
  require("./../Template/5/square_5_8").default;

TemplateComponents["story"]["5-1"] =
  require("./../Template/5/story_5_1").default;
TemplateComponents["story"]["5-2"] =
  require("./../Template/5/story_5_2").default;
TemplateComponents["story"]["5-3"] =
  require("./../Template/5/story_5_3").default;
TemplateComponents["story"]["5-4"] =
  require("./../Template/5/story_5_4").default;
TemplateComponents["story"]["5-5"] =
  require("./../Template/5/story_5_5").default;
TemplateComponents["story"]["5-6"] =
  require("./../Template/5/story_5_6").default;
TemplateComponents["story"]["5-7"] =
  require("./../Template/5/story_5_7").default;
TemplateComponents["story"]["5-8"] =
  require("./../Template/5/story_5_8").default;

// Template 6
TemplateComponents["regular"]["6-1"] =
  require("./../Template/6/regular_6_1").default;
TemplateComponents["regular"]["6-2"] =
  require("./../Template/6/regular_6_2").default;
TemplateComponents["regular"]["6-3"] =
  require("./../Template/6/regular_6_3").default;
TemplateComponents["regular"]["6-4"] =
  require("./../Template/6/regular_6_4").default;
TemplateComponents["regular"]["6-5"] =
  require("./../Template/6/regular_6_5").default;
TemplateComponents["regular"]["6-6"] =
  require("./../Template/6/regular_6_6").default;
TemplateComponents["regular"]["6-7"] =
  require("./../Template/6/regular_6_7").default;
TemplateComponents["regular"]["6-8"] =
  require("./../Template/6/regular_6_8").default;

TemplateComponents["square"]["6-1"] =
  require("./../Template/6/square_6_1").default;
TemplateComponents["square"]["6-2"] =
  require("./../Template/6/square_6_2").default;
TemplateComponents["square"]["6-3"] =
  require("./../Template/6/square_6_3").default;
TemplateComponents["square"]["6-4"] =
  require("./../Template/6/square_6_4").default;
TemplateComponents["square"]["6-5"] =
  require("./../Template/6/square_6_5").default;
TemplateComponents["square"]["6-6"] =
  require("./../Template/6/square_6_6").default;
TemplateComponents["square"]["6-7"] =
  require("./../Template/6/square_6_7").default;
TemplateComponents["square"]["6-8"] =
  require("./../Template/6/square_6_8").default;

TemplateComponents["story"]["6-1"] =
  require("./../Template/6/story_6_1").default;
TemplateComponents["story"]["6-2"] =
  require("./../Template/6/story_6_2").default;
TemplateComponents["story"]["6-3"] =
  require("./../Template/6/story_6_3").default;
TemplateComponents["story"]["6-4"] =
  require("./../Template/6/story_6_4").default;
TemplateComponents["story"]["6-5"] =
  require("./../Template/6/story_6_5").default;
TemplateComponents["story"]["6-6"] =
  require("./../Template/6/story_6_6").default;
TemplateComponents["story"]["6-7"] =
  require("./../Template/6/story_6_7").default;
TemplateComponents["story"]["6-8"] =
  require("./../Template/6/story_6_8").default;

// Template 7
TemplateComponents["regular"]["7-1"] =
  require("./../Template/7/regular_7_1").default;
TemplateComponents["regular"]["7-2"] =
  require("./../Template/7/regular_7_2").default;
TemplateComponents["regular"]["7-3"] =
  require("./../Template/7/regular_7_3").default;
TemplateComponents["regular"]["7-4"] =
  require("./../Template/7/regular_7_4").default;
TemplateComponents["regular"]["7-5"] =
  require("./../Template/7/regular_7_5").default;
TemplateComponents["regular"]["7-6"] =
  require("./../Template/7/regular_7_6").default;
TemplateComponents["regular"]["7-7"] =
  require("./../Template/7/regular_7_7").default;
TemplateComponents["regular"]["7-8"] =
  require("./../Template/7/regular_7_8").default;

TemplateComponents["square"]["7-1"] =
  require("./../Template/7/square_7_1").default;
TemplateComponents["square"]["7-2"] =
  require("./../Template/7/square_7_2").default;
TemplateComponents["square"]["7-3"] =
  require("./../Template/7/square_7_3").default;
TemplateComponents["square"]["7-4"] =
  require("./../Template/7/square_7_4").default;
TemplateComponents["square"]["7-5"] =
  require("./../Template/7/square_7_5").default;
TemplateComponents["square"]["7-6"] =
  require("./../Template/7/square_7_6").default;
TemplateComponents["square"]["7-7"] =
  require("./../Template/7/square_7_7").default;
TemplateComponents["square"]["7-8"] =
  require("./../Template/7/square_7_8").default;

TemplateComponents["story"]["7-1"] =
  require("./../Template/7/story_7_1").default;
TemplateComponents["story"]["7-2"] =
  require("./../Template/7/story_7_2").default;
TemplateComponents["story"]["7-3"] =
  require("./../Template/7/story_7_3").default;
TemplateComponents["story"]["7-4"] =
  require("./../Template/7/story_7_4").default;
TemplateComponents["story"]["7-5"] =
  require("./../Template/7/story_7_5").default;
TemplateComponents["story"]["7-6"] =
  require("./../Template/7/story_7_6").default;
TemplateComponents["story"]["7-7"] =
  require("./../Template/7/story_7_7").default;
TemplateComponents["story"]["7-8"] =
  require("./../Template/7/story_7_8").default;

// Template 8
TemplateComponents["regular"]["8-1"] =
  require("./../Template/8/regular_8_1").default;
TemplateComponents["regular"]["8-2"] =
  require("./../Template/8/regular_8_2").default;
TemplateComponents["regular"]["8-3"] =
  require("./../Template/8/regular_8_3").default;
TemplateComponents["regular"]["8-4"] =
  require("./../Template/8/regular_8_4").default;
TemplateComponents["regular"]["8-5"] =
  require("./../Template/8/regular_8_5").default;
TemplateComponents["regular"]["8-6"] =
  require("./../Template/8/regular_8_6").default;
TemplateComponents["regular"]["8-7"] =
  require("./../Template/8/regular_8_7").default;
TemplateComponents["regular"]["8-8"] =
  require("./../Template/8/regular_8_8").default;

TemplateComponents["square"]["8-1"] =
  require("./../Template/8/square_8_1").default;
TemplateComponents["square"]["8-2"] =
  require("./../Template/8/square_8_2").default;
TemplateComponents["square"]["8-3"] =
  require("./../Template/8/square_8_3").default;
TemplateComponents["square"]["8-4"] =
  require("./../Template/8/square_8_4").default;
TemplateComponents["square"]["8-5"] =
  require("./../Template/8/square_8_5").default;
TemplateComponents["square"]["8-6"] =
  require("./../Template/8/square_8_6").default;
TemplateComponents["square"]["8-7"] =
  require("./../Template/8/square_8_7").default;
TemplateComponents["square"]["8-8"] =
  require("./../Template/8/square_8_8").default;

TemplateComponents["story"]["8-1"] =
  require("./../Template/8/story_8_1").default;
TemplateComponents["story"]["8-2"] =
  require("./../Template/8/story_8_2").default;
TemplateComponents["story"]["8-3"] =
  require("./../Template/8/story_8_3").default;
TemplateComponents["story"]["8-4"] =
  require("./../Template/8/story_8_4").default;
TemplateComponents["story"]["8-5"] =
  require("./../Template/8/story_8_5").default;
TemplateComponents["story"]["8-6"] =
  require("./../Template/8/story_8_6").default;
TemplateComponents["story"]["8-7"] =
  require("./../Template/8/story_8_7").default;
TemplateComponents["story"]["8-8"] =
  require("./../Template/8/story_8_8").default;

// Template 9
TemplateComponents["regular"]["9-1"] =
  require("./../Template/9/regular_9_1").default;
TemplateComponents["regular"]["9-2"] =
  require("./../Template/9/regular_9_2").default;
TemplateComponents["regular"]["9-3"] =
  require("./../Template/9/regular_9_3").default;
TemplateComponents["regular"]["9-4"] =
  require("./../Template/9/regular_9_4").default;
TemplateComponents["regular"]["9-5"] =
  require("./../Template/9/regular_9_5").default;
TemplateComponents["regular"]["9-6"] =
  require("./../Template/9/regular_9_6").default;
TemplateComponents["regular"]["9-7"] =
  require("./../Template/9/regular_9_7").default;
TemplateComponents["regular"]["9-8"] =
  require("./../Template/9/regular_9_8").default;

TemplateComponents["square"]["9-1"] =
  require("./../Template/9/square_9_1").default;
TemplateComponents["square"]["9-2"] =
  require("./../Template/9/square_9_2").default;
TemplateComponents["square"]["9-3"] =
  require("./../Template/9/square_9_3").default;
TemplateComponents["square"]["9-4"] =
  require("./../Template/9/square_9_4").default;
TemplateComponents["square"]["9-5"] =
  require("./../Template/9/square_9_5").default;
TemplateComponents["square"]["9-6"] =
  require("./../Template/9/square_9_6").default;
TemplateComponents["square"]["9-7"] =
  require("./../Template/9/square_9_7").default;
TemplateComponents["square"]["9-8"] =
  require("./../Template/9/square_9_8").default;

TemplateComponents["story"]["9-1"] =
  require("./../Template/9/story_9_1").default;
TemplateComponents["story"]["9-2"] =
  require("./../Template/9/story_9_2").default;
TemplateComponents["story"]["9-3"] =
  require("./../Template/9/story_9_3").default;
TemplateComponents["story"]["9-4"] =
  require("./../Template/9/story_9_4").default;
TemplateComponents["story"]["9-5"] =
  require("./../Template/9/story_9_5").default;
TemplateComponents["story"]["9-6"] =
  require("./../Template/9/story_9_6").default;
TemplateComponents["story"]["9-7"] =
  require("./../Template/9/story_9_7").default;
TemplateComponents["story"]["9-8"] =
  require("./../Template/9/story_9_8").default;

// Template 10
TemplateComponents["regular"]["10-1"] =
  require("./../Template/10/regular_10_1").default;
TemplateComponents["regular"]["10-2"] =
  require("./../Template/10/regular_10_2").default;
TemplateComponents["regular"]["10-3"] =
  require("./../Template/10/regular_10_3").default;
TemplateComponents["regular"]["10-4"] =
  require("./../Template/10/regular_10_4").default;
TemplateComponents["regular"]["10-5"] =
  require("./../Template/10/regular_10_5").default;
TemplateComponents["regular"]["10-6"] =
  require("./../Template/10/regular_10_6").default;
TemplateComponents["regular"]["10-7"] =
  require("./../Template/10/regular_10_7").default;
TemplateComponents["regular"]["10-8"] =
  require("./../Template/10/regular_10_8").default;

TemplateComponents["square"]["10-1"] =
  require("./../Template/10/square_10_1").default;
TemplateComponents["square"]["10-2"] =
  require("./../Template/10/square_10_2").default;
TemplateComponents["square"]["10-3"] =
  require("./../Template/10/square_10_3").default;
TemplateComponents["square"]["10-4"] =
  require("./../Template/10/square_10_4").default;
TemplateComponents["square"]["10-5"] =
  require("./../Template/10/square_10_5").default;
TemplateComponents["square"]["10-6"] =
  require("./../Template/10/square_10_6").default;
TemplateComponents["square"]["10-7"] =
  require("./../Template/10/square_10_7").default;
TemplateComponents["square"]["10-8"] =
  require("./../Template/10/square_10_8").default;

TemplateComponents["story"]["10-1"] =
  require("./../Template/10/story_10_1").default;
TemplateComponents["story"]["10-2"] =
  require("./../Template/10/story_10_2").default;
TemplateComponents["story"]["10-3"] =
  require("./../Template/10/story_10_3").default;
TemplateComponents["story"]["10-4"] =
  require("./../Template/10/story_10_4").default;
TemplateComponents["story"]["10-5"] =
  require("./../Template/10/story_10_5").default;
TemplateComponents["story"]["10-6"] =
  require("./../Template/10/story_10_6").default;
TemplateComponents["story"]["10-7"] =
  require("./../Template/10/story_10_7").default;
TemplateComponents["story"]["10-8"] =
  require("./../Template/10/story_10_8").default;

// Template 11
TemplateComponents["regular"]["11-1"] =
  require("./../Template/11/regular_11_1").default;
TemplateComponents["regular"]["11-2"] =
  require("./../Template/11/regular_11_2").default;
TemplateComponents["regular"]["11-3"] =
  require("./../Template/11/regular_11_3").default;
TemplateComponents["regular"]["11-4"] =
  require("./../Template/11/regular_11_4").default;
TemplateComponents["regular"]["11-5"] =
  require("./../Template/11/regular_11_5").default;
TemplateComponents["regular"]["11-6"] =
  require("./../Template/11/regular_11_6").default;
TemplateComponents["regular"]["11-7"] =
  require("./../Template/11/regular_11_7").default;
TemplateComponents["regular"]["11-8"] =
  require("./../Template/11/regular_11_8").default;

TemplateComponents["square"]["11-1"] =
  require("./../Template/11/square_11_1").default;
TemplateComponents["square"]["11-2"] =
  require("./../Template/11/square_11_2").default;
TemplateComponents["square"]["11-3"] =
  require("./../Template/11/square_11_3").default;
TemplateComponents["square"]["11-4"] =
  require("./../Template/11/square_11_4").default;
TemplateComponents["square"]["11-5"] =
  require("./../Template/11/square_11_5").default;
TemplateComponents["square"]["11-6"] =
  require("./../Template/11/square_11_6").default;
TemplateComponents["square"]["11-7"] =
  require("./../Template/11/square_11_7").default;
TemplateComponents["square"]["11-8"] =
  require("./../Template/11/square_11_8").default;

TemplateComponents["story"]["11-1"] =
  require("./../Template/11/story_11_1").default;
TemplateComponents["story"]["11-2"] =
  require("./../Template/11/story_11_2").default;
TemplateComponents["story"]["11-3"] =
  require("./../Template/11/story_11_3").default;
TemplateComponents["story"]["11-4"] =
  require("./../Template/11/story_11_4").default;
TemplateComponents["story"]["11-5"] =
  require("./../Template/11/story_11_5").default;
TemplateComponents["story"]["11-6"] =
  require("./../Template/11/story_11_6").default;
TemplateComponents["story"]["11-7"] =
  require("./../Template/11/story_11_7").default;
TemplateComponents["story"]["11-8"] =
  require("./../Template/11/story_11_8").default;

// Template 12
TemplateComponents["regular"]["12-1"] =
  require("./../Template/12/regular_12_1").default;
TemplateComponents["regular"]["12-2"] =
  require("./../Template/12/regular_12_2").default;
TemplateComponents["regular"]["12-3"] =
  require("./../Template/12/regular_12_3").default;
TemplateComponents["regular"]["12-4"] =
  require("./../Template/12/regular_12_4").default;
TemplateComponents["regular"]["12-5"] =
  require("./../Template/12/regular_12_5").default;
TemplateComponents["regular"]["12-6"] =
  require("./../Template/12/regular_12_6").default;
TemplateComponents["regular"]["12-7"] =
  require("./../Template/12/regular_12_7").default;
TemplateComponents["regular"]["12-8"] =
  require("./../Template/12/regular_12_8").default;

TemplateComponents["square"]["12-1"] =
  require("./../Template/12/square_12_1").default;
TemplateComponents["square"]["12-2"] =
  require("./../Template/12/square_12_2").default;
TemplateComponents["square"]["12-3"] =
  require("./../Template/12/square_12_3").default;
TemplateComponents["square"]["12-4"] =
  require("./../Template/12/square_12_4").default;
TemplateComponents["square"]["12-5"] =
  require("./../Template/12/square_12_5").default;
TemplateComponents["square"]["12-6"] =
  require("./../Template/12/square_12_6").default;
TemplateComponents["square"]["12-7"] =
  require("./../Template/12/square_12_7").default;
TemplateComponents["square"]["12-8"] =
  require("./../Template/12/square_12_8").default;

TemplateComponents["story"]["12-1"] =
  require("./../Template/12/story_12_1").default;
TemplateComponents["story"]["12-2"] =
  require("./../Template/12/story_12_2").default;
TemplateComponents["story"]["12-3"] =
  require("./../Template/12/story_12_3").default;
TemplateComponents["story"]["12-4"] =
  require("./../Template/12/story_12_4").default;
TemplateComponents["story"]["12-5"] =
  require("./../Template/12/story_12_5").default;
TemplateComponents["story"]["12-6"] =
  require("./../Template/12/story_12_6").default;
TemplateComponents["story"]["12-7"] =
  require("./../Template/12/story_12_7").default;
TemplateComponents["story"]["12-8"] =
  require("./../Template/12/story_12_8").default;

export default TemplateComponents;
