import React, {useRef, useState, useEffect} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import ContentEditable from "react-contenteditable";
import parse, {domToReact} from "html-react-parser";
import sanitizeHtml from "sanitize-html";
import window from "../../../global/window"

const TemplateText = (props) => {
    const editableRef = useRef();

    console.log('template textttttttttttttt',props.text)

    const [text, setText] = useState({
        id: props.id,
        text: props.text,
        textStyle: props.textStyle,
        editStatus:props.editStatus,
    });

    useEffect(() => {

        document.addEventListener("dragstart", (event) => {
            event.preventDefault();
        });

        setText({
            id: props.id,
            text: sanitizeHtml(props.text,sanitizeConf),
            textStyle: props.textStyle,
            editStatus:props.editStatus,
        })

    }, [props.id,props.editStatus,props.textStyle,props.text]);

    let sanitizeConf = {
        allowedTags: ["b", "i", "div","span"],
        allowedAttributes: { div: ["style","id"], span: ["style"] }
    };

    const handlePaste = (e) => {
        let clipboardData, pastedData, wraphtml;

        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();

        // Get pasted data via clipboard API
        clipboardData = e.clipboardData || window.clipboardData;

        console.log(clipboardData);
        pastedData = clipboardData.getData('Text');

        wraphtml = `<div id="${props.id}" style="color:#FFFFFF;background-color:;font-size:18px;text-align:left;font-weight:normal;font-style:normal;line-height:1;text-transform:none;text-decoration:none;padding:5px;border-radius:5px;padding-bottom:10px">${pastedData}</div>`;

        setText({...text, text: wraphtml})
        props.onInputSlideText(wraphtml);
    }

    const sanitize = () => {
        setText({...text, text: sanitizeHtml(text.text,sanitizeConf)})
    }

    const options = {
        replace: ({ attribs, children }) => {

            if (!attribs) {
                return;
            }

            if (attribs.id === text.id) {
                return (
                    <div id={text.id} style={props.textStyle}>
                        {domToReact(children, options)}
                    </div>
                );
            }
        }
    }

    const handleChange = (e) => {

        let parseHtml = parse(e.target.value, options);

        setText({...text, text: sanitizeHtml(e.target.value,sanitizeConf)});
        props.onInputSlideText(renderToStaticMarkup(parseHtml));
    }

    // useEffect(()=>{
    //     document.addEventListener("paste", (event) => {
    //         handlePaste(event)
    //     });
    // },[text.editStatus])

    return (
            <>
            {
                text.editStatus ?
                    <ContentEditable
                        innerRef={editableRef}
                        html={`${text.text}`} // innerHTML of the editable div
                        disabled={false}       // use true to disable editing
                        onChange={(e)=>handleChange(e)} // handle innerHTML change
                        style={text.textStyle}
                        tagName="" // Use a custom HTML tag (uses a div by default)
                        onBlur={sanitize}
                        id="editableId"
                    />
                    : parse(`${text.text}`, options)
            }
            </>
    );
};

export default TemplateText;
