import React, {useEffect} from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import Footer from "../Footer";
import IntegrationHead from "./IntegrationHead";
import ConnectFacebook from "./ConnectFacebook";
import ConnectYoutube from "./ConnectYoutube";
import ConnectReelapps from "./ConnectReelapps";
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.svg";
import liveIcon from "../../images/logo-livereel.png";

import syvidDisable from "../../images/SYVIDED_DISABLE.png";
import liveDisable from "../../images/LIVEREEL_DISABLE.png";
import scriptDisable from "../../images/SCRIPT_DISABLE.png";
import sonorityDisable from "../../images/SONORITY_DISABLE.png";
import trimDisable from "../../images/TRIM_DISABLE.png";

import Webinar from "../dashboard/Webinar";
import {useDispatch, useSelector} from "react-redux";
import sonorityIcon from "../../images/logo-sonority.png";
import {fetchSocialAccounts} from "../../actions/socialAction";

const Integration = () => {

    const dispatch = useDispatch();

    const socialData = useSelector(state => state.social);
    const brandName = useSelector(state => state.rebrand.data);

    useEffect(()=>{
        dispatch(fetchSocialAccounts());
    },[])

    return(
        <>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'ClipsReel'} | Integration</title>
            </Helmet>
            <Navbar/>

            <div className="warraper">
                <IntegrationHead/>

                <section className="my-connection">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="text-white">My Connection</h4>
                            </div>
                        </div>

                        <ConnectFacebook data={socialData.facebook} key="1"/>
                        <ConnectYoutube data={socialData.youtube} key="2" />
                        <ConnectReelapps icon={socialData.syvid.length>0?syvidIcon:syvidDisable} name="SyVid" type="syvid" data={socialData.syvid} key="3" />
                        <ConnectReelapps icon={socialData.scriptreel.length>0?scriptIcon:scriptDisable} name="ScriptReel" type="scriptreel" data={socialData.scriptreel} key="4" />
                        <ConnectReelapps icon={socialData.trimreel.length>0?trimIcon:trimDisable} name="TrimReel" type="trimreel" data={socialData.trimreel} key="5" />
                        <ConnectReelapps icon={socialData.livereel.length>0?liveIcon:liveDisable} name="LiveReel" type="livereel" data={socialData.livereel} key="6" />
                        <ConnectReelapps icon={socialData.sonority.length>0?sonorityIcon:sonorityDisable} name="Sonority" type="sonority" data={socialData.sonority} key="7" />

                    </div>
                </section>

                <Webinar/>
            </div>

            <Footer/>
        </>
    )
}

export default Integration;