import styled from "styled-components";

export const Portait3Style = styled.div`
width: 100%;
height: 100%;

.container_two_three .img-fluid {
  display: block;
  max-width: 100%;
}

.container_two_three {
  width: 100%;
  height: 100%;
  overflow: hidden;
}



.container_two_three div {
  font-family: "Poppins", sans-serif;
  line-height: 1.2;
  font-weight: 600;
  margin: 0;
}

.container_two_three .front-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff95a9;
  clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
  transform: scale(1.5);
}

.container_two_three .front-layer img {
  width: 100%;
  height: 100%;
}

.container_two_three .back-layer {
  width: 100%;
  height: 100%;
  background: #ff95a9;
}

.container_two_three .back-layer img {
  opacity: 0.5;
  width: 100%;
  height: 100%;;
  object-fit: cover;
}

.container_two_three .text-wrappers {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  width: 50%;
  left: 0;
  height: max-content;
}

.container_two_three .text-wrappers div {
  font-size: 18px;
  flex: 0 0 100%;
}

.container_two_three .text-wrappers div:last-child {
  font-size: 10px;
  margin-top: 20px;
}

.container_two_three{
  width:100%;
  height:100%;
}
.back-layer{
  width: 100%;
  height: 100%;
}

.front-layer img, .front-layer video{
  width:100%;
}

.text-wrappers {
  position:absolute;
  top:0;
  left:50%;
  width:50%;
  height:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-wrappers > div {
  width:100%;
  overflow: hidden;
}
`;
