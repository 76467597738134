import styled from "styled-components";

export const Square8Style = styled.div`
  width:100%;
  height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container4 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .back-layer {
    width:100%;
    height:100%;
  }

  .back-layer img,
  .back-layer video {
    width:100%;
    height:100%;
    object-fit: cover;
    transform: scale(1.1);
    left: 5%;
  }


  .text-wrapper {
    top: 0;
    position: absolute;
    overflow: hidden;
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5% 5% 5% 0;
  }

  .text-wrapper > div {
    width: 100%;
  }

`;
