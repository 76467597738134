import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";

export const regular_9_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".front-layer",
    {
      duration: 1.5,
      top: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2.5 },
    +1.8
  );

  return localTimeline;
};
export const regular_9_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 1,
    top: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2 }
  );

  return localTimeline;
};

export const regular_9_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(
    ".yellow.left",
    {
      duration: 0.8,
      left: 0,
    },
    +1
  );

  localTimeline.to(
    ".yellow.right",
    {
      duration: 0.8,
      right: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.8,
    },
    +1.3
  );

  return localTimeline;
};

export const regular_9_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".yellow.top",
    {
      duration: 0.8,
      top: 0,
    },
    +1
  );

  localTimeline.to(
    ".yellow.bottom",
    {
      duration: 0.8,
      bottom: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.8,
    },
    +1.3
  );

  return localTimeline;
};

export const regular_9_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 0.5,
    height: "7.5%",
    ease: "none",
    onComplete() {
      localTimeline.to(
        ".text-wrapper",
        {
          duration: 0.5,
          height: "58%",
          ease: "none",
        },
        +2
      );
      localTimeline.to(
        ".text-wrapper .wrap",
        {
          duration: 0.5,
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          ease: "none",
          onComplete: yellowFullWidth,
        },
        +2
      );
      localTimeline.to(".inner-wrap", { duration: 0.5, scale: 1 }, +2);
    },
  });

  function yellowFullWidth() {
    localTimeline.to(
      ".yellow-bar .yellow",
      {
        duration: 0.5,
        width: "65%",
        ease: "none",
      },
      +1
    );
  }
  return localTimeline;
};

export const regular_9_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginBottom: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginBottom: "0",
      stagger: 0.1,
      duration: 1,
    }
  );
  return localTimeline;
};

export const regular_9_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 0.5,
    left: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginLeft: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};
export const regular_9_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 0.5,
    right: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginRight: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginRight: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};

export const square_9_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".front-layer",
    {
      duration: 1.5,
      top: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2.5 },
    +1.8
  );

  return localTimeline;
};
export const square_9_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 1,
    top: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2 }
  );

  return localTimeline;
};

export const square_9_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(
    ".yellow.left",
    {
      duration: 0.8,
      left: 0,
    },
    +1
  );

  localTimeline.to(
    ".yellow.right",
    {
      duration: 0.8,
      right: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.8,
    },
    +1.3
  );

  return localTimeline;
};

export const square_9_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".yellow.top",
    {
      duration: 0.8,
      top: 0,
    },
    +1
  );

  localTimeline.to(
    ".yellow.bottom",
    {
      duration: 0.8,
      bottom: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.8,
    },
    +1.3
  );

  return localTimeline;
};

export const square_9_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 0.7,
    height: "7.5%",
    ease: "none",
    onComplete() {
      localTimeline.to(
        ".text-wrapper",
        {
          duration: 0.5,
          height: "58%",
          ease: "none",
        },
        +1
      );
      localTimeline.to(
        ".text-wrapper .wrap",
        {
          duration: 0.5,
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          ease: "none",
          onComplete: yellowFullWidth,
        },
        +1
      );
      localTimeline.to(".inner-wrap", { duration: 0.5, scale: 1 }, +1);
    },
  });

  function yellowFullWidth() {
    localTimeline.to(
      ".yellow-bar .yellow",
      {
        duration: 0.5,
        width: "65%",
        ease: "none",
      },
      +1
    );
  }
  return localTimeline;
};

export const square_9_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginBottom: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginBottom: "0",
      stagger: 0.1,
      duration: 1,
    }
  );
  return localTimeline;
};

export const square_9_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 0.5,
    left: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginLeft: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};
export const square_9_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 0.5,
    right: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginRight: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginRight: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};

export const story_9_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".front-layer",
    {
      duration: 1.5,
      top: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2.5 },
    +1.8
  );

  return localTimeline;
};
export const story_9_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 1,
    top: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper",
    { autoAlpha: 0 },
    { autoAlpha: 1, duration: 2 }
  );

  return localTimeline;
};

export const story_9_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);

  localTimeline.to(
    ".yellow.left",
    {
      duration: 0.8,
      left: 0,
    },
    +1
  );

  localTimeline.to(
    ".yellow.right",
    {
      duration: 0.8,
      right: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.8,
    },
    +1.3
  );

  return localTimeline;
};

export const story_9_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(
    ".yellow.top",
    {
      duration: 0.8,
      top: 0,
    },
    +1
  );

  localTimeline.to(
    ".yellow.bottom",
    {
      duration: 0.8,
      bottom: 0,
    },
    +1
  );

  localTimeline.fromTo(
    ".text-wrapper div",
    { autoAlpha: 0 },
    {
      duration: 2,
      autoAlpha: 1,
      stagger: 0.8,
    },
    +1.3
  );

  return localTimeline;
};

export const story_9_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".text-wrapper", {
    duration: 0.5,
    height: "7.5%",
    ease: "none",
    onComplete() {
      localTimeline.to(
        ".text-wrapper",
        {
          duration: 0.5,
          height: "58%",
          ease: "none",
        },
        +0.7
      );
      localTimeline.to(
        ".text-wrapper .wrap",
        {
          duration: 0.5,
          clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
          ease: "none",
          onComplete: yellowFullWidth,
        },
        +0.7
      );
      localTimeline.to(".inner-wrap", { duration: 0.5, scale: 1 }, +0.7);
    },
  });

  function yellowFullWidth() {
    localTimeline.to(
      ".yellow-bar .yellow",
      {
        duration: 0.5,
        width: "65%",
        ease: "none",
      },
      +1
    );
  }
  return localTimeline;
};

export const story_9_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo(
    ".text-wrapper div",
    { marginBottom: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginBottom: "0",
      stagger: 0.1,
      duration: 1,
    }
  );
  return localTimeline;
};

export const story_9_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 0.5,
    left: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginLeft: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginLeft: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};

export const story_9_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to(".front-layer", {
    duration: 0.5,
    right: 0,
  });

  localTimeline.fromTo(
    ".text-wrapper div",
    { marginRight: "-200px", autoAlpha: 0 },
    {
      autoAlpha: 1,
      marginRight: "0",
      stagger: 0.1,
    }
  );

  return localTimeline;
};
