import styled from "styled-components";

export const Portait5Style = styled.div`
width: 100%;
height: 100%;

.img-fluid {
  display: block;
  max-width: 100%;
}

.container10 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.back-layer {
  width: 100%;
  height: 100%;
}

.front-layer {
  background: -moz-linear-gradient(
    top,
    rgba(255, 38, 252) 0%,
    rgba(255, 245, 128) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 38, 252) 0%,
    rgba(255, 245, 128) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 38, 252) 0%,
    rgba(255, 245, 128) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccff26fc', endColorstr='#ccfff580',GradientType=0 );
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.5;
}

.back-layer img,
.back-layer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.text-wrapper {
  position: absolute;
  top: 15%;
  bottom: 0;
  left: 12%;
  clip-path: polygon(98% 50%, 100% 50%, 100% 50%, 98% 50%);
  width: 80%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
}

// .text-wrapper div {
//   width: 100%;
// }

.border-white {
  position: absolute;
  height: 100%;
  width: 0.7vw;
  background: #fff;
  right: 0%;
}
`;
