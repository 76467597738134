import styled from "styled-components";

export const Square5Style = styled.div`
  width: 100%;
  height: 100%;
  * {
    position: relative;
    box-sizing: border-box;
  }

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container5 {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .back-layer {
    width: 100%;
    height: 100%;
    background: #007a79;
  }

  .back-layer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  .front-layer {
    width: 100%;
    height: 100%;
    background: #007a79;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
  }

  .text-wrappers {
    position: absolute;
    top: 0;
    // padding: 5% 5% 5% 0;
    width:60%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-wrappers > div {
    //clip-path: polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%);
    width: 100%;
  }
`;
