import styled from "styled-components";

export const Portait3Style = styled.div`
  width: 100%;
  height: 100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container5 {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }



  .back-layer {
    width: 100%;
    height: 100%;
    background: #fff;
  }

  .back-layer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
    object-position: 50% 40%;
  }

  .text-wrapper {
    top: 0;
    left:12%;
    position: absolute;
    width: 75%;
    height: 100%;
    background:#01797a;
    overflow: hidden;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    clip-path: polygon(0% 50%, 100% 50%, 100% 50%, 0% 50%);
  }



  .text-wrapper > div {
    width: 100%;
    //margin-top: 20%;
  }
`;
