import styled from "styled-components";

export const Square4Style = styled.div`
  width:100%;
  height:100%;
  .container3 {
    width:100%;
    height:100%;
    overflow: hidden;
    background: #a1dffb;
  }

  .f-red {
    color: #c33124;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .back-layer {
    position:absolute;
    top:10%;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    /* clip-path: ellipse(0% 0% at 50% 0%); */
    clip-path: ellipse(0% 0% at 50% 105%);
  }

  .text-wrapper {
    position: absolute;
    bottom: 0;
    width:100%;
    height:100%;
    padding:5%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item:center;
  }
  .text-wrapper div{
    text-align: center;
  }

`;
