import { init } from "./common";
import { gsap, Power1, Power2, Power3, Power4, Linear } from "gsap";

export const regular_11_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper div', {top:'-30%', autoAlpha:0}, {autoAlpha:1,top: '0', ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};

export const regular_11_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{top:'-20%', autoAlpha:0}, {autoAlpha:1,top: '0', ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};

export const regular_11_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.text-wrapper > div:not(:last-of-type)', {margin: '0 0 0 -20%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut})
  localTimeline.to('.border', {left: '60%', ease:Power1.easeOut, duration: 1})
  localTimeline.to('.border::before', {left: '-160%', ease:Power1.easeOut, duration: 1}, '-=1')
  localTimeline.fromTo('.img-container', {right:'-20%'} ,{right:'0',ease:Linear.easeNone, duration: 10}, '-=1')
  return localTimeline;
};

export const regular_11_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{top:'-20%', autoAlpha:0}, {autoAlpha:1,top: '0', ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};

export const regular_11_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper div',{autoAlpha:0}, {autoAlpha: 1, ease:Power1.easeOut, stagger:0.5,duration:3},'+1')
  // localTimeline.to('.text-wrapper > div:last-of-type', {opacity: 1, ease:Power1.easeOut})
  localTimeline.to('.cover', {height: '50%', ease:Power1.easeOut, duration: .8}, '+1')
  localTimeline.to('.img-container', {padding: '0 25% 0 0', ease:Linear.easeNone, duration: 5}, '+1')
  return localTimeline;
};

export const regular_11_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.cover', {width: '50%', ease:Power1.easeOut, duration: .8})
  localTimeline.from('.text-wrapper > div', {margin: '0 0 0 -50%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 5}, '-=.8')
  return localTimeline;
};

export const regular_11_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.cover', {width: '50%', ease:Power1.easeOut, duration: .8})
  localTimeline.from('.text-wrapper > div', {margin: '0 0 0 -50%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 5}, '-=.8')
  return localTimeline;
};
export const regular_11_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.img-container',{height: '0', width: '0'}, {width: '30%',height: '52%', ease:Power1.easeOut, duration: 1},+1)
  localTimeline.fromTo('.text-wrapper  div',{autoAlpha:0, bottom:'-200%'}, {bottom: '0', autoAlpha: 1, ease:Power1.easeOut, stagger: 0.2},+2)
  // localTimeline.to('.text-wrapper > div:last-of-type', {opacity: 1, ease:Power1.easeOut})
  // localTimeline.to('img', {margin: '0 0 0 -25%', ease:Linear.easeNone, duration: 5}, '-=1.5')
  return localTimeline;
};

export const square_11_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{top:'-10%', autoAlpha:0}, {autoAlpha:1,top: '0', ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};
export const square_11_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{top:'-10%', autoAlpha:0},  {autoAlpha:1,top: '0', ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};

export const square_11_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.text-wrapper > div:not(:last-of-type)', {margin: '0 0 0 -20%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut})
  localTimeline.to('.border', {top: '50%', ease:Power1.easeOut, duration: 1})
  localTimeline.to('.border::before', {top: '-150%', ease:Power1.easeOut, duration: 1}, '-=1')
  localTimeline.fromTo('.img-container',{scale:1,padding: '0 0 0 0'}, {scale:1.3,padding: '0 23% 0 0', ease:Linear.easeNone, duration: 10}, '-=1')
  return localTimeline;
};

export const square_11_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{top:'-10%', autoAlpha:0}, {autoAlpha:1,top: '0', ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};

export const square_11_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{autoAlpha:0}, {autoAlpha: 1, ease:Power1.easeOut, duration:1.5},+1)
  // localTimeline.to('.text-wrapper > div:last-of-type', {opacity: 1, ease:Power1.easeOut})
  localTimeline.to('.cover', {height: '50%', ease:Power1.easeOut, duration: .8},+1)
  localTimeline.fromTo('.img-container', {scale:1,padding: '0 0 0 0'},{scale:1.3,padding: '0 23% 0 0', ease:Linear.easeNone, duration: 10},+1)
  return localTimeline;
};

export const square_11_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.cover', {height: '50%', ease:Power1.easeOut, duration: .8})
  localTimeline.from('.text-wrapper > div', {margin: '0 0 0 -50%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 5}, '-=.8')
  return localTimeline;
};

export const square_11_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.cover', {height: '50%', ease:Power1.easeOut, duration: .8})
  localTimeline.from('.text-wrapper > div', {margin: '0 0 0 -50%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 5}, '-=.8')
  return localTimeline;
};
export const square_11_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.img-container', {height: '0', width: '0'}, {height: '50%', width: '50%', ease:Power1.easeOut, duration: 1})
  localTimeline.fromTo('.text-wrapper div',{bottom:'-200%', autoAlpha:0}, {bottom: 0, autoAlpha: 1, ease:Power1.easeOut, stagger: 0.2}, '-=.5')
  // localTimeline.to('.text-wrapper > div:last-of-type', {opacity: 1, ease:Power1.easeOut})
  // localTimeline.to('img', {margin: '0 0 0 -25%', ease:Linear.easeNone, duration: 5}, '-=1.5')
  return localTimeline;
};

export const story_11_1 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{marginTop:'-30%', autoAlpha:0}, {marginTop: '0',autoAlpha:1, ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};

export const story_11_2 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{marginTop:'-30%', autoAlpha:0}, {marginTop: '0',autoAlpha:1, ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};

export const story_11_3 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.from('.text-wrapper > div:not(:last-of-type)', {margin: '0 0 0 -20%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut})
  localTimeline.to('.border', {top: '50%', ease:Power1.easeOut, duration: 1})
  localTimeline.to('.border::before', {top: '-150%', ease:Power1.easeOut, duration: 1}, '-=1')
  localTimeline.to('.img-container', {padding: '0 25% 0 0', ease:Linear.easeNone, duration: 5}, '-=1')
  return localTimeline;
};

export const story_11_4 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper',{marginTop:'-30%', autoAlpha:0},  {marginTop: '0', autoAlpha:1,ease:Power1.easeOut, duration: .8})
  // localTimeline.from('.text-wrapper > div:last-of-type', {opacity: 0, ease:Power1.easeOut, duration: .8})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 4}, '-=1.6')
  return localTimeline;
};

export const story_11_5 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.text-wrapper div',{autoAlpha:0}, {autoAlpha: 1, ease:Power1.easeOut, stagger:0.4})
  // localTimeline.to('.text-wrapper > div:last-of-type', {opacity: 1, ease:Power1.easeOut})
  localTimeline.to('.cover', {height: '50%', ease:Power1.easeOut, duration: .8}, '-=1')
  localTimeline.to('.img-container', {padding: '0 25% 0 0', ease:Linear.easeNone, duration: 5}, '-=1')
  return localTimeline;
};

export const story_11_6 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.cover', {height: '50%', ease:Power1.easeOut, duration: .8})
  localTimeline.from('.text-wrapper > div', {margin: '0 0 0 -50%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 5}, '-=.8')
  return localTimeline;
};

export const story_11_7 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.to('.cover', {height: '50%', ease:Power1.easeOut, duration: .8})
  localTimeline.from('.text-wrapper > div', {margin: '0 0 0 -50%', opacity: 0, ease:Power1.easeOut, stagger: 0.2})
  localTimeline.to('img', {scale: 1.1, ease:Linear.easeNone, duration: 5}, '-=.8')
  return localTimeline;
};

export const story_11_8 = (pauseTTS) => {
  const localTimeline = init(pauseTTS);
  localTimeline.fromTo('.img-container',{width:'0',height: '0'},{height: '38%', width: '70%', ease:Power1.easeOut, duration: 1})
  localTimeline.fromTo('.text-wrapper div',{bottom:'-200%', autoAlpha:0} ,{bottom: '0', autoAlpha: 1, ease:Power1.easeOut, stagger: 0.2}, '-=.5')
  localTimeline.to('.text-wrapper > div:last-of-type', {opacity: 1, ease:Power1.easeOut})
  // localTimeline.to('img', {margin: '0 0 0 -25%', ease:Linear.easeNone, duration: 5}, '-=1.5')
  return localTimeline;
};
