import React from "react";

const EditorTabs = () => {
    return(
        <>
            {console.log('render tabs')}
            <div className="editor-tab-box">
                <div className="row">
                    <div className="col-md-12">
                        <div className="editor-tab-menu">
                            <ul
                                className="nav d-flex justify-content-center"
                                id="pills-tab"
                                role="tablist"
                            >
                                <li className="nav-item text-center">
                                    <a
                                        className="nav-link active"
                                        id="pills-story-tab"
                                        data-toggle="pill"
                                        href="#pills-story"
                                        role="tab"
                                        aria-controls="pills-story"
                                        aria-selected="true"
                                    >
                                        <div className="img-wrapper d-block mx-auto">
                                            {/* <img src="images/story.png" alt="" class="w-100"> */}
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 24 24"
                                                style={{ enableBackground: "new 0 0 24 24" }}
                                                xmlSpace="preserve"
                                            >
                                                <style
                                                    type="text/css"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "\n                                .st0{fill:#FFFFFF;}\n                              "
                                                    }}
                                                />
                                                <g id="ICON" transform="translate(0 0)">
                                                    <g id="Component_11_35" transform="translate(0)">
                                                        <path
                                                            id="Path_86"
                                                            className="st0"
                                                            d="M13.4,5.8v2.8c0,0.2,0.2,0.4,0.4,0.4H17c0.2,0,0.4-0.2,0.4-0.4V5.8c0-0.2-0.2-0.4-0.4-0.4h-3.2
                              C13.6,5.4,13.4,5.6,13.4,5.8z M14.1,6.1h2.5v2h-2.5V6.1z"
                                                        />
                                                        <path
                                                            id="Path_87"
                                                            className="st0"
                                                            d="M4,4.8h2.1c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4H4c-0.2,0-0.4,0.2-0.4,0.4
                              C3.6,4.7,3.8,4.8,4,4.8z"
                                                        />
                                                        <path
                                                            id="Path_88"
                                                            className="st0"
                                                            d="M4,6.3h6.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4l0,0H4C3.8,5.6,3.6,5.8,3.6,6
                              C3.6,6.2,3.8,6.3,4,6.3z"
                                                        />
                                                        <path
                                                            id="Path_89"
                                                            className="st0"
                                                            d="M4,7.8h6.2c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H4c-0.2,0-0.4,0.2-0.4,0.4S3.8,7.8,4,7.8z"
                                                        />
                                                        <path
                                                            id="Path_90"
                                                            className="st0"
                                                            d="M4,9.2h6.2c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4H4c-0.2,0-0.4,0.2-0.4,0.4S3.8,9.2,4,9.2z"
                                                        />
                                                        <path
                                                            id="Path_91"
                                                            className="st0"
                                                            d="M4,10.7h6.2c0.2,0,0.4-0.2,0.4-0.4S10.4,10,10.2,10H4c-0.2,0-0.4,0.2-0.4,0.4S3.8,10.7,4,10.7z"
                                                        />
                                                        <path
                                                            id="Path_92"
                                                            className="st0"
                                                            d="M3.6,15.1c0,0.2,0.2,0.4,0.4,0.4h6.5c0.2,0,0.4-0.2,0.4-0.4v-3.5c0-0.2-0.2-0.4-0.4-0.4H4
                              c-0.2,0-0.4,0.2-0.4,0.4V15.1z M4.3,12h5.8v2.8H4.3V12z"
                                                        />
                                                        <path
                                                            id="Path_93"
                                                            className="st0"
                                                            d="M18.1,6.3h2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-2c-0.2,0-0.4,0.2-0.4,0.4
                              C17.7,6.2,17.9,6.3,18.1,6.3z"
                                                        />
                                                        <path
                                                            id="Path_94"
                                                            className="st0"
                                                            d="M18.1,7.8h2c0.2,0,0.4-0.2,0.4-0.4S20.2,7.1,20,7.1h-2c-0.2,0-0.4,0.2-0.4,0.4
                              S17.9,7.8,18.1,7.8z"
                                                        />
                                                        <path
                                                            id="Path_95"
                                                            className="st0"
                                                            d="M18.1,9.2h2c0.2,0,0.4-0.2,0.4-0.4S20.2,8.5,20,8.5h-2c-0.2,0-0.4,0.2-0.4,0.4
                              S17.9,9.2,18.1,9.2z"
                                                        />
                                                        <path
                                                            id="Path_96"
                                                            className="st0"
                                                            d="M13.4,10.3c0,0.2,0.2,0.4,0.4,0.4H20c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-6.2
                              C13.6,10,13.4,10.1,13.4,10.3C13.4,10.3,13.4,10.3,13.4,10.3z"
                                                        />
                                                        <path
                                                            id="Path_97"
                                                            className="st0"
                                                            d="M20,11.6h-6.2c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4H20c0.2,0,0.4-0.2,0.4-0.4
                              S20.2,11.6,20,11.6z"
                                                        />
                                                        <path
                                                            id="Path_98"
                                                            className="st0"
                                                            d="M20,13.1h-6.2c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4H20c0.2,0,0.4-0.2,0.4-0.4
                              C20.4,13.3,20.2,13.1,20,13.1z"
                                                        />
                                                        <path
                                                            id="Path_99"
                                                            className="st0"
                                                            d="M20,14.7h-6.2c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4H20c0.2,0,0.4-0.2,0.4-0.4
                              C20.4,14.9,20.2,14.7,20,14.7z"
                                                        />
                                                        <path
                                                            id="Path_100"
                                                            className="st0"
                                                            d="M22.8,3.4h-0.6V3.1c0-0.6-0.5-1.1-1.1-1.1h-3.3c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4
                              h3.3c0.2,0,0.4,0.2,0.4,0.4v13.6h-7.7c-0.6,0-1.1,0.2-1.5,0.6V4.2c0-0.8,0.7-1.5,1.5-1.5h2.5c0.2,0,0.4-0.2,0.4-0.4
                              c0-0.2-0.2-0.4-0.4-0.4h-2.5c-0.8,0-1.5,0.4-1.9,1c-0.4-0.6-1.1-1-1.9-1H2.9C2.3,2,1.8,2.5,1.8,3.1v0.3H1.2C0.5,3.4,0,4,0,4.6
                              v15.2C0,20.4,0.5,21,1.2,21h2.2c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4H1.2c-0.2,0-0.5-0.2-0.5-0.5V4.6
                              c0-0.2,0.2-0.5,0.5-0.5h0.6v8.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4V3.1c0-0.2,0.2-0.4,0.4-0.4h7.2
                              c0.8,0,1.5,0.7,1.5,1.5v13.1c-0.4-0.4-0.9-0.6-1.5-0.6H2.5v-2.5c0-0.2-0.2-0.4-0.4-0.4c-0.2,0-0.4,0.2-0.4,0.4l0,0v3.7
                              c0,0.8,0.6,1.4,1.4,1.4h12.3v1H4.8c-0.2,0-0.4,0.2-0.4,0.4c0,0.2,0.2,0.4,0.4,0.4l0,0h10.6v0.7c0,0.2,0.2,0.4,0.4,0.4
                              c0.1,0,0.1,0,0.2,0l1-0.5l1,0.5c0.2,0.1,0.4,0,0.5-0.1c0-0.1,0-0.1,0-0.2V21h4.4c0.6,0,1.2-0.5,1.2-1.2V4.6
                              C24,4,23.5,3.4,22.8,3.4z M18.4,17.4h3.1v0.5c0,0.4-0.3,0.7-0.7,0.7h-2.4L18.4,17.4L18.4,17.4z M2.5,17.9v-0.5h7.7
                              c0.7,0,1.3,0.5,1.5,1.2H3.1C2.8,18.6,2.5,18.3,2.5,17.9L2.5,17.9z M12.4,18.6c0.2-0.7,0.8-1.2,1.5-1.2h1.6v1.2H12.4z M17.7,21.1
                              l-0.6-0.3c-0.1-0.1-0.2-0.1-0.3,0l-0.7,0.3v-3.7h1.6L17.7,21.1z M23.3,19.8c0,0.2-0.2,0.5-0.5,0.5h-4.4v-1h2.4
                              c0.8,0,1.4-0.6,1.4-1.4V4.1h0.6c0.2,0,0.5,0.2,0.5,0.5L23.3,19.8L23.3,19.8z"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <h6>Story</h6>
                                    </a>
                                </li>
                                <li className="nav-item text-center">
                                    <a
                                        className="nav-link"
                                        id="pills-text-tab"
                                        data-toggle="pill"
                                        href="#pills-text"
                                        role="tab"
                                        aria-controls="pills-text"
                                        aria-selected="false"
                                    >
                                        <div className="img-wrapper d-block mx-auto">
                                            {/* <img src="images/text ICON.png" alt="" class="w-100"> */}
                                            <svg
                                                version="1.1"
                                                id="ICON"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 24 24"
                                                style={{ enableBackground: "new 0 0 24 24" }}
                                                xmlSpace="preserve"
                                            >
                                                <style
                                                    type="text/css"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "\n                              .st0{fill:#FFFFFF;}\n                            "
                                                    }}
                                                />
                                                <path
                                                    id="Path_101"
                                                    className="st0"
                                                    d="M23.3,5C23.7,5,24,4.6,24,4.3c0,0,0,0,0,0V0.7C24,0.3,23.7,0,23.3,0c0,0,0,0,0,0h-3.6
                        C19.3,0,19,0.3,19,0.7c0,0,0,0,0,0v1.1H5V0.7C5,0.3,4.6,0,4.3,0H0.7C0.3,0,0,0.3,0,0.7c0,0,0,0,0,0v3.6C0,4.7,0.3,5,0.7,5h1.1V19
                        H0.7C0.3,19,0,19.3,0,19.7c0,0,0,0,0,0v3.6C0,23.7,0.3,24,0.7,24c0,0,0,0,0,0h3.6C4.7,24,5,23.7,5,23.3l0,0v-1.1H19v1.1
                        c0,0.4,0.3,0.7,0.7,0.7h0h3.6c0.4,0,0.7-0.3,0.7-0.7v0v-3.6c0-0.4-0.3-0.7-0.7-0.7h0h-1.1V5H23.3z M20.4,1.4h2.2v2.2h-2.2V1.4z
                        M1.4,1.4h2.2v2.2H1.4L1.4,1.4z M3.6,22.6H1.4v-2.2h2.2V22.6z M22.6,22.6h-2.2v-2.2h2.2L22.6,22.6z M20.8,19h-1.1
                        c-0.4,0-0.7,0.3-0.7,0.7v0v1.1H5v-1.1C5,19.3,4.6,19,4.3,19c0,0,0,0,0,0H3.2V5h1.1C4.6,5,5,4.6,5,4.3V3.2H19v1.1
                        C19,4.6,19.3,5,19.7,5l0,0h1.1L20.8,19L20.8,19z"
                                                />
                                                <path
                                                    id="Path_102"
                                                    className="st0"
                                                    d="M17,6.4H7c-0.4,0-0.7,0.3-0.7,0.7l0,0v2.6c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V7.8h3.6
                        v8.4H10c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7c0,0,0,0,0.1,0h4c0.4,0,0.7-0.3,0.7-0.7c0-0.4-0.3-0.7-0.7-0.7c0,0,0,0-0.1,0h-1.3
                        V7.8h3.6v1.9c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7V7.1C17.7,6.7,17.4,6.4,17,6.4z"
                                                />
                                            </svg>
                                        </div>
                                        <h6>Text</h6>
                                    </a>
                                </li>
                                <li className="nav-item text-center">
                                    <a
                                        className="nav-link"
                                        id="pills-media-tab"
                                        data-toggle="pill"
                                        href="#pills-media"
                                        role="tab"
                                        aria-controls="pills-media"
                                        aria-selected="false"
                                    >
                                        <div className="img-wrapper d-block mx-auto">
                                            {/* <img src="images/media.png" alt="" class="w-100"> */}
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 24 24"
                                                style={{ enableBackground: "new 0 0 24 24" }}
                                                xmlSpace="preserve"
                                            >
                                                <style
                                                    type="text/css"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "\n                                .st0{fill:#FFFFFF;}\n                              "
                                                    }}
                                                />
                                                <g id="ICON" transform="translate(0)">
                                                    <path
                                                        id="Path_103"
                                                        className="st0"
                                                        d="M22,6.2h-0.2c-0.1-1-1-1.8-2-2c-0.1-1.1-1.1-2-2.2-2H3.4C1.5,2.2,0,3.8,0,5.7v9.9
                            c0,1.1,0.9,2.1,2,2.2c0.1,1.1,1.1,2,2.2,2c0,1.1,0.9,2,2,2H22c1.1,0,2-0.9,2-2V8.2C24,7.1,23.1,6.2,22,6.2z M23.1,8.2v7.7l-2-1.5
                            c-0.6-0.4-1.3-0.4-1.9,0l-2.6,1.9l-4.9-5.1c-0.6-0.6-1.5-0.6-2.2-0.1l-4.4,3.9V8.2c0-0.6,0.5-1.1,1.1-1.1H22
                            C22.6,7.1,23.1,7.6,23.1,8.2z M23.1,19.8c0,0.6-0.5,1.1-1.1,1.1H6.2c-0.6,0-1.1-0.5-1.1-1.1v-3.7l5-4.4c0.3-0.2,0.6-0.2,0.9,0
                            l2.2,2.3l0,0l4.4,4.6c0.2,0.2,0.5,0.2,0.7,0c0.2-0.2,0.2-0.5,0-0.7l-1.1-1.1l2.5-1.8c0.2-0.2,0.5-0.2,0.8,0l2.6,2L23.1,19.8z
                            M2.9,17.6V6.5c0,0,0-0.1,0-0.1C3,5.7,3.6,5.1,4.3,5.1H10c0.3,0,0.5-0.2,0.5-0.5S10.3,4.2,10,4.2l0,0H4.3C3,4.2,2,5.2,2,6.5v10.4
                            c-0.6-0.1-1.1-0.6-1.1-1.3V5.7c0-1.4,1.1-2.5,2.5-2.5h14.2c0.6,0,1.1,0.4,1.3,1h-5.1c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h5.8
                            c0.6,0,1.1,0.4,1.3,1H6.2c-1.1,0-2,0.9-2,2v7.7l0,0v3C3.5,18.8,2.9,18.3,2.9,17.6L2.9,17.6z"
                                                    />
                                                    <path
                                                        id="Path_104"
                                                        className="st0"
                                                        d="M16.4,9c-1.1,0-2,0.9-2,2c0,1.1,0.9,2,2,2c1.1,0,2-0.9,2-2c0,0,0,0,0,0C18.4,9.9,17.5,9,16.4,9z
                            M16.4,12.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1c0,0,0,0,0,0C17.4,11.6,17,12.1,16.4,12.1
                            L16.4,12.1z"
                                                    />
                                                    <path
                                                        id="Path_105"
                                                        className="st0"
                                                        d="M9.6,4.9C9.7,4.9,9.7,4.9,9.6,4.9C9.7,5,9.7,5,9.7,5c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0
                            c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
                            c0-0.1,0-0.2-0.1-0.3c0,0,0,0-0.1-0.1c0,0,0,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
                            c-0.1,0-0.1,0.1-0.1,0.1C9.6,4.5,9.6,4.6,9.6,4.7C9.6,4.7,9.6,4.7,9.6,4.9C9.6,4.8,9.6,4.8,9.6,4.9z"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                        <h6>Media</h6>
                                    </a>
                                </li>
                                <li className="nav-item text-center">
                                    <a
                                        className="nav-link"
                                        id="pills-audio-tab"
                                        data-toggle="pill"
                                        href="#pills-audio"
                                        role="tab"
                                        aria-controls="pills-audio"
                                        aria-selected="false"
                                    >
                                        <div
                                            className="img-wrapper d-block mx-auto"
                                            style={{ width: 20 }}
                                        >
                                            {/* <img src="images/Audio ICON.png" alt="" class="w-100"> */}
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 24 24"
                                                style={{ enableBackground: "new 0 0 24 24" }}
                                                xmlSpace="preserve"
                                            >
                                                <style
                                                    type="text/css"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "\n                              .st0{fill:#FFFFFF;}\n                            "
                                                    }}
                                                />
                                                <g id="ICON" transform="translate(0)">
                                                    <path
                                                        id="Path_106"
                                                        className="st0"
                                                        d="M21.8,0H2.2C1,0,0,1,0,2.2v19.5C0,23,1,24,2.2,24h19.5c1.2,0,2.2-1,2.2-2.2V2.2
                          C24,1,23,0,21.8,0z M22.6,21.8c0,0.5-0.4,0.8-0.8,0.8H2.2c-0.5,0-0.8-0.4-0.8-0.8V2.2c0-0.5,0.4-0.8,0.8-0.8h19.5
                          c0.5,0,0.8,0.4,0.8,0.8V21.8z"
                                                    />
                                                    <path
                                                        id="Path_107"
                                                        className="st0"
                                                        d="M6.6,5.6H5.8V3.5c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v2.1H3.5C3.1,5.6,2.8,6,2.8,6.3
                          c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h0.8v10.6c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V7.1h0.8
                          c0.4,0,0.7-0.3,0.7-0.7C7.3,6,7,5.6,6.6,5.6L6.6,5.6z"
                                                    />
                                                    <path
                                                        id="Path_108"
                                                        className="st0"
                                                        d="M11.2,14.1h-0.8V3.5c0-0.4-0.3-0.7-0.7-0.7C9.3,2.8,9,3.1,9,3.5v10.6H8.1
                          c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7H9v2.1c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.1h0.8c0.4,0,0.7-0.3,0.7-0.7
                          C11.9,14.4,11.6,14.1,11.2,14.1L11.2,14.1z"
                                                    />
                                                    <path
                                                        id="Path_109"
                                                        className="st0"
                                                        d="M15.9,9.9H15V3.5c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v6.4h-0.8
                          c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7h0.8v6.4c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-6.4h0.8
                          c0.4,0,0.7-0.3,0.7-0.7C16.6,10.2,16.2,9.9,15.9,9.9z"
                                                    />
                                                    <path
                                                        id="Path_110"
                                                        className="st0"
                                                        d="M20.5,4.2h-0.8V3.5c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7v0.7h-0.8
                          c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0,0,0,0,0,0h0.8v12c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0v-12
                          h0.8c0.4,0,0.7-0.3,0.7-0.7C21.2,4.6,20.9,4.2,20.5,4.2z"
                                                    />
                                                    <path
                                                        id="Path_111"
                                                        className="st0"
                                                        d="M5.1,19.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7
                          C5.8,20.1,5.5,19.8,5.1,19.8z"
                                                    />
                                                    <path
                                                        id="Path_112"
                                                        className="st0"
                                                        d="M9.7,19.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7
                          C10.4,20.1,10.1,19.8,9.7,19.8z"
                                                    />
                                                    <path
                                                        id="Path_113"
                                                        className="st0"
                                                        d="M14.3,19.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7
                          C15,20.1,14.7,19.8,14.3,19.8z"
                                                    />
                                                    <path
                                                        id="Path_114"
                                                        className="st0"
                                                        d="M18.9,19.8c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7
                          C19.6,20.1,19.3,19.8,18.9,19.8z"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                        <h6>Audio</h6>
                                    </a>
                                </li>
                                <li className="nav-item text-center">
                                    <a
                                        className="nav-link"
                                        id="pills-style-tab"
                                        data-toggle="pill"
                                        href="#pills-style"
                                        role="tab"
                                        aria-controls="pills-style"
                                        aria-selected="false"
                                    >
                                        <div
                                            className="img-wrapper d-block mx-auto"
                                            style={{ width: 20 }}
                                        >
                                            {/* <img src="images/shape.png" alt="" class="w-100"> */}
                                            <svg
                                                version="1.1"
                                                id="shape"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 24 24"
                                                style={{ enableBackground: "new 0 0 24 24" }}
                                                xmlSpace="preserve"
                                            >
                                                <style
                                                    type="text/css"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "\n                                .st0{fill:#FFFFFF;}\n                              "
                                                    }}
                                                />
                                                <g id="Group_71" transform="translate(0 0)">
                                                    <g id="Group_70">
                                                        <path
                                                            id="Path_115"
                                                            className="st0"
                                                            d="M9.3,13.7H0.9c-0.5,0-0.9,0.4-0.9,0.9c0,0,0,0,0,0v8.4C0,23.6,0.4,24,0.9,24c0,0,0,0,0,0h8.4
                              c0.5,0,0.9-0.4,0.9-0.9l0,0v-8.4C10.3,14.2,9.8,13.7,9.3,13.7C9.3,13.7,9.3,13.7,9.3,13.7z M8.4,22.1H1.9v-6.5h6.5V22.1z"
                                                        />
                                                        <path
                                                            id="Path_116"
                                                            className="st0"
                                                            d="M14.3,14c-0.4-0.4-1-0.4-1.3,0c-0.4,0.4-0.4,1,0,1.3l8.1,8.1c0.4,0.4,1,0.4,1.3,0s0.4-1,0-1.3
                              L14.3,14z"
                                                        />
                                                        <path
                                                            id="Path_117"
                                                            className="st0"
                                                            d="M23.9,10l-4.7-9.5c-0.2-0.5-0.8-0.7-1.3-0.4c-0.2,0.1-0.3,0.2-0.4,0.4L12.8,10
                              c-0.2,0.5,0,1,0.4,1.3c0.1,0.1,0.3,0.1,0.4,0.1h9.5c0.5,0,0.9-0.4,0.9-0.9C24,10.2,24,10.1,23.9,10z M15.1,9.5L18.3,3l3.2,6.4
                              H15.1z"
                                                        />
                                                        <path
                                                            id="Path_118"
                                                            className="st0"
                                                            d="M5.7,0C2.5,0,0,2.5,0,5.7s2.5,5.7,5.7,5.7s5.7-2.5,5.7-5.7l0,0C11.3,2.5,8.8,0,5.7,0z M5.7,9.5
                              c-2.1,0-3.8-1.7-3.8-3.8s1.7-3.8,3.8-3.8s3.8,1.7,3.8,3.8l0,0C9.4,7.8,7.8,9.4,5.7,9.5z"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <h6>Style</h6>
                                    </a>
                                </li>
                                <li className="nav-item text-center">
                                    <a
                                        className="nav-link"
                                        id="pills-credits-tab"
                                        data-toggle="pill"
                                        href="#pills-credits"
                                        role="tab"
                                        aria-controls="pills-credits"
                                        aria-selected="false"
                                    >
                                        <div
                                            className="img-wrapper d-block mx-auto"
                                            style={{ width: 20 }}
                                        >
                                            {/* <img src="images/film.png" alt="" class="w-100"> */}
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 24 24"
                                                style={{ enableBackground: "new 0 0 24 24" }}
                                                xmlSpace="preserve"
                                            >
                                                <style
                                                    type="text/css"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            "\n                                .st0{fill:#FFFFFF;}\n                              "
                                                    }}
                                                />
                                                <g id="film" transform="translate(0)">
                                                    <g id="Group_72" transform="translate(0)">
                                                        <path
                                                            id="Path_119"
                                                            className="st0"
                                                            d="M21.9,0H2.1C0.9,0,0,0.9,0,2.1v19.8C0,23.1,0.9,24,2.1,24h19.8c1.2,0,2.1-0.9,2.1-2.1V2.1
                              C24,0.9,23.1,0,21.9,0z M4.2,22.6H2.1c-0.4,0-0.7-0.3-0.7-0.7v-0.7h2.8V22.6z M4.2,19.8H1.4V17h2.8V19.8z M4.2,15.6H1.4v-2.8h2.8
                              V15.6z M4.2,11.3H1.4V8.4h2.8V11.3z M4.2,7H1.4V4.2h2.8V7z M4.2,2.8H1.4V2.1c0-0.4,0.3-0.7,0.7-0.7h2.1V2.8z M18.4,22.6H5.6v-9.8
                              h12.8V22.6z M18.4,11.3H5.6V1.4h12.8V11.3z M22.6,21.9c0,0.4-0.3,0.7-0.7,0.7h-2.1v-1.4h2.8V21.9z M22.6,19.8h-2.8V17h2.8V19.8z
                              M22.6,15.6h-2.8v-2.8h2.8V15.6z M22.6,11.3h-2.8V8.4h2.8V11.3z M22.6,7h-2.8V4.2h2.8V7z M22.6,2.8h-2.8V1.4h2.1
                              c0.4,0,0.7,0.3,0.7,0.7V2.8z"
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <h6>Credits</h6>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(EditorTabs);