import styled from "styled-components";

export const Portait5Style = styled.div`
width:100%;
height:100%;


  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container8 {
    width:100%;
    height:100%;
    overflow: hidden;
    background: #2f7dff;
    background: -moz-linear-gradient(left, #2f7dff 0%, #ff48d2 100%);
    background: -webkit-linear-gradient(left, #2f7dff 0%, #ff48d2 100%);
    background: linear-gradient(to right, #2f7dff 0%, #ff48d2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f7dff', endColorstr='#ff48d2',GradientType=1 );
  }

  .f-white {
    color: #fff;
  }

  .text-wrapper div {
    width:100%;
  }

  .vid-wrapper img,
  .vid-wrapper video {
    width:100%;
    height:9vh;
    object-fit: cover;
    object-position: center;
    opacity: 0.7;
  }

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .content .col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .vid-wrapper {
    height: 80%;
    width: 130%;
    overflow: hidden;
    margin-left: auto;
    clip-path: polygon(50% 0, 50% 0, 25% 100%, 25% 100%);
    background: #ff48d2;
  }

  .text-wrapper {
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

`;
