import React, {useState} from "react";
import HoverVideoPlayer from "react-hover-video-player";
import imgLoader from '../../images/imgloader.gif'

const TempContent = (props) => {

    const [loadImg, setLoadImg] = useState(false);

    const loadImage = () => {
        setLoadImg(true);
    }

    return (
        <>
            <div className="col-lg-3  col-md-4 col-sm-6 p-lg-2">
                <div className={`template-cover text-white text-center template-border ${props.template_id === props.id ? 'active-template' : '' }`}>
                    <div className={`template-img cursor-pointer`} onClick={()=>props.onInputTemplate(props.id, props.template_no)}>
                        {
                            <HoverVideoPlayer
                                videoSrc={props.url}
                                pausedOverlay={
                                    <img src={loadImg ? props.image : imgLoader } alt={props?.name} className="img-fluid" onLoad={()=>loadImage()} />
                                }
                                loadingOverlay={
                                    <div className="loader-center"><i style={{color: "#16d489"}} className="fa fa-spinner fa-spin mr-2"/></div>
                                }
                            />
                        }

                        {/*<div className="template-time">*/}
                        {/*    <span>27s</span>*/}
                        {/*</div>*/}
                        {/*<div className="ClipsReel-overlay d-flex justify-content-center align-items-center ">*/}
                        {/*    <ul className="list-inline m-0 px-2 d-flex">*/}
                        {/*        <li className="text-center m-3">*/}
                        {/*            <a><span>*/}
                        {/*                <i className="fas fa-play-circle"></i></span>Preview*/}
                        {/*            </a>*/}
                        {/*        </li>*/}
                        {/*        <li className="text-center m-3 ">*/}
                        {/*            <a onClick={()=>props.onInputTemplate(props.id)}><span>*/}
                        {/*               <i className="far fa-hand-pointer"></i></span>Choose*/}
                        {/*            </a>*/}
                        {/*        </li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                    <div className="template-names">
                        <h6>{props.name}</h6>
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(TempContent);