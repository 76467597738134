import styled from "styled-components";

export const Square5Style = styled.div`
  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }

  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }
  .text-wrapper {
    white-space: pre-wrap;
  }
  /*.f-arial {*/
  /*	font-family: Arial;*/
  /*}*/

  /*.f-mvboli {*/
  /*	font-family: mvboli;*/
  /*}*/

  /*.f-nexalight {*/
  /*	font-family: nexalight;*/
  /*}*/

  /*.f-nexabold {*/
  /*	font-family: nexabold;*/
  /*}*/

  .f-cyan {
    color: #00fffc;
  }

  .f-cyan-dark {
    color: #00d1cf;
  }

  .f-blue {
    color: #005596;
  }

  .f-white {
    color: #ffffff;
  }

  .poster {
    position: relative;
    overflow: hidden !important;
  }

  .poster.nine-by-sixteen {
    width: 1080px;
    height: 1920px;
  }

  .poster.one-by-one {
    width: 310px;
    height: 310px;
  }

  .poster.nine-by-sixteen {
    width: 171px;
    height: 304px;
  }

  .poster.five {
    background-color: #00d1cf;
  }

  .poster.one-by-one .sidepanel.five {
    float: left;
    height: 100%;
    width: 0;
    background-color: #005596;
  }

  .poster.one-by-one .sidepanel.five .photo-container {
    height: 770px;
    margin-left: 170px;
    width: 0;
    overflow: hidden;
  }

  .poster.one-by-one .sidepanel.five .photo {
    position: relative;
    height: 800px;
    width: 780px;
    top: -15px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  .poster.one-by-one.five .text-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: calc(100% - 1050px);
    box-sizing: border-box;
    overflow: hidden;
    padding: 0;
  }

  .poster.one-by-one.five .ta {
    font-size: 88px;
    line-height: 108px;
    margin: 0 0 0 -100%;
    font-weight: bold;
  }

  .poster.one-by-one.five .sub-ta {
    font-size: 48px;
    line-height: 50px;
    margin: 40px 0 0 -100%;
  }

  .poster.one-by-one .sidepanel.five .photo-container {
    margin-left: 25px;
    height: 120px;
  }

  .poster.one-by-one .sidepanel.five .photo {
    height: 130px;
    width: 120px;
    top: -5px;
    background-position: -37px center;
  }

  .poster.one-by-one.five .text-wrapper {
    width: calc(100% - 165px);
    height:100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column
  }

  .poster.one-by-one.five .ta {
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 0 -100%;
  }

  .poster.one-by-one.five .sub-ta {
    font-size: 8px;
    line-height: 10px;
    margin: 8px 0 0 -100%;
  }
  .photo-container{
    position: absolute;
  }
`;
