import React, { useEffect, useState } from "react";
import limg from "../../images/L NEXT.png";
import pathimg from "../../images/Path 51.png";
import pauseImg from "../../images/pause_icon.png"
import rimg from "../../images/R NEXT.png";
import clip from "../../images/clip.mp4";
import TemplateBody from "./Templates/TemplateBody";
import {updateSelectedTiles, updateSlideText} from "../../actions/videoAction";
import { useDispatch } from "react-redux";
import parse, {domToReact} from "html-react-parser";
import {renderToStaticMarkup} from "react-dom/server";
let audio = false;
let interval;
let durationSlide = 5;
const Preview = (props) => {
    let i = props.selectedIndex;
    const dispatch = useDispatch();
    let dimArr = props.dimension.split(':');

    const [editStatus, setEditStatus] = useState(false);
    const [currentDuration, setCurrentDuration] = useState(props.currentLayer.duration);
    const [playSlide, setPlaySlide] = useState(false);
    const [slideDuration, setSlideDuration] = useState({
        hours: "00",
        minutes: "00",
        seconds: "00"
    });

    const [controlsWidth, setControlsWidth] = useState(100);


    const nextSlide = () => {
        if (props.selectedIndex < (props.slideLength - 1)) {
            dispatch(updateSelectedTiles(props.selectedIndex + 1));
        }
    }

    const prevSlide = () => {
        if (props.selectedIndex >= 1) {
            dispatch(updateSelectedTiles(props.selectedIndex - 1));
        }
    }


    const handlePlaySlide = async () => {
        setPlaySlide(true);

        if(props.audioFile !== ""){
            audio = new Audio(props.audioFile);
            audio.currentTime = props.lastSlide!==undefined ? currentDuration+1 : 0;
            audio.play().then((r)=> console.log('playing audio'))
        }

        if(props.selectedIndex === (props.slideLength-1)){
            i = props.selectedIndex;
        }else{
            i++;
            durationSlide = 0;
        }
        i = props.selectedIndex;

        function myLoop (layer) {

            let duration = layer.duration*1000;

            if(props.allSlides[i].sound === false){
                if(audio !== false){
                    audio.volume = 0;
                }
            }else{
                if(audio !== false) {
                    audio.volume = 1;
                }
            }

            interval = setTimeout(function() {

                i++;
                if (i < props.slideLength) {
                    dispatch(updateSelectedTiles(i));
                    myLoop(props.allSlides[i]);
                } else{
                    setPlaySlide(false);

                    /* pause audio after last slide ends */
                    if(props.audioFile !== "") {
                        setTimeout(() => {
                            audio.pause();
                        }, layer.duration*1000)
                    }
                }
            },  duration)
        }

        myLoop(props.allSlides[i])

    }


    // async function slideLoop(index,slide) {
    //     console.log('slide called')
    //     return new Promise(resolve => {
    //         // call redux get the silde duration of the given indexd
    //         setTimeout(() => {
    //             dispatch(updateSelectedTiles(index))
    //             resolve('tttt');
    //         }, (slide.duration*1000));
    //     });
    //
    // }
    //
    // const handlePlaySlide = async () => {
    //     setPlaySlide(true);
    //     console.log(props.allSlides)
    //
    //     for (let i =0; i < props.allSlides.length; i++ ){
    //         await slideLoop(i,props.allSlides[i]).then(value => console.log(value));
    //     }
    //
    //     setPlaySlide(false);
    //
    // }

    const handlePauseSlide = () => {
        setPlaySlide(false);

        if(props.audioFile !== "") {
            audio.pause();
        }

        clearTimeout(interval);
    }


    const [layer, setLayer] = useState({
        id: props.currentLayer.id,
        text: props.currentLayer.text,
        file: props.currentLayer.file_url,
        textStyle: {
            color: props.currentLayer.font_color,
            backgroundColor: props.currentLayer.bg_color,
            fontFamily: props.currentLayer.font_family,
            fontSize: props.currentLayer.font_size + 'px',
            textAlign: props.currentLayer.align,
            fontWeight: props.currentLayer.bold === true ? 'bold' : 'normal',
            fontStyle: props.currentLayer.italic === true ? 'italic' : 'normal',
            lineHeight: props.currentLayer.line_height,
            textTransform: props.currentLayer.lowercase === true ? 'lowercase' : props.currentLayer.uppercase === true ? 'uppercase' : 'none',
            textDecoration: props.currentLayer.underline,
            padding: '5px',
            paddingBottom: "10px"
        },
        duration: props.currentLayer.duration,
        slideType: props.currentLayer.type,
        logo: props.logo,
        watermark: props.watermark,
        tts: props.currentLayer.tts,
        playSlide: playSlide,
        is_outro: props.currentLayer.is_outro,
        variation_id: props.currentLayer.variation_id,
        sound: props.currentLayer.sound
    })


    const onInputSlideText = (value) => {
        dispatch(updateSlideText(value, props.selectedIndex));
    }

    const enableEdit = (e) => {
        e.stopPropagation();
        setEditStatus(true);
    }

    const disableEdit = () => {
        setEditStatus(false);
    }


    useEffect(() => {
        setCurrentDuration(props.currentLayer.duration);
        setLayer({
            id: props.currentLayer.id,
            text: props.currentLayer.text,
            file: props.currentLayer.file_url,
            textStyle: {
                color: props.currentLayer.font_color,
                backgroundColor: props.currentLayer.bg_color,
                fontFamily: props.currentLayer.font_family,
                fontSize: props.currentLayer.font_size + 'px',
                textAlign: props.currentLayer.align,
                fontWeight: props.currentLayer.bold === true ? 'bold' : 'normal',
                fontStyle: props.currentLayer.italic === true ? 'italic' : 'normal',
                lineHeight: props.currentLayer.line_height,
                textTransform: props.currentLayer.lowercase === true ? 'lowercase' : props.currentLayer.uppercase === true ? 'uppercase' : 'none',
                textDecoration: props.currentLayer.underline === true ? 'underline' : props.currentLayer.strike === true ? 'line-through' : 'none',
                padding: '5px',
                borderRadius: '5px',
                paddingBottom: "10px"
            },
            duration: props.currentLayer.duration,
            slideType: props.currentLayer.type,
            logo: props.logo,
            watermark: props.watermark,
            tts: props.currentLayer.tts,
            playSlide: playSlide,
            is_outro: props.currentLayer.is_outro,
            variation_id: props.currentLayer.variation_id,
            sound: props.currentLayer.sound
        })
    }, [JSON.stringify(props.currentLayer), props.logo, props.watermark, playSlide])


    useEffect(() => {
        let preWidth = document.querySelector('.preview-wrapper');
        let parentWidth = preWidth.offsetWidth;

        let wrapWidth = document.querySelector('.aspect-ratio-wrapper');
        let demoWidth = (wrapWidth.offsetWidth / parentWidth * 100).toFixed();

        setControlsWidth(demoWidth);
    }, [])


    useEffect(() => {

        let minutes = Math.floor(props.currentLayer.duration / 60);
        let seconds = props.currentLayer.duration - minutes * 60;
        let hours = Math.floor(props.currentLayer.duration / 3600);

        setSlideDuration({
            hours: hours,
            minutes: minutes,
            seconds: seconds
        })
    }, [JSON.stringify(props.currentLayer)])


    useEffect(()=>{

        const options = {
            replace: ({ attribs, children }) => {

                if (!attribs) {
                    return;
                }

                if (attribs.id === props.currentLayer.id) {
                    return (
                        <div id={props.currentLayer.id} style={layer.textStyle}>
                            {domToReact(children, options)}
                        </div>
                    );
                }
            }
        }

        let parseHtml = parse(props.currentLayer.text, options);

        dispatch(updateSlideText(renderToStaticMarkup(parseHtml), props.selectedIndex))

    },[layer.textStyle, props.id])



    return (
        <>
            <div className={`editor-preview mx-auto ${props.dimension === '16:9' ? 'ep-as-r-16-9' : props.dimension === '9:16' ? 'ep-as-r-9-16' : props.dimension === '1:1' ? 'ep-as-r-1-1' : ''}`} onClick={(e) => disableEdit(e)}>
                <div className="editor-preview-wrapper">
                    <div className="editor-preview-box">
                        <div className="preview-wrapper">
                            {/* Don't! Remove This Video Tag I used this a wrapper to preview to contain the responsiveness of preview*/}
                            <video
                                className="preview-def-wh"
                                width="100%"
                                height="100%"
                                style={{ opacity: 0, marginBottom: "-6px" }}
                            >
                                <source src={clip} type="video/mp4" />
                            </video>
                            {/* Don't! Remove This Video Tag I used this a wrapper to preview to contain the responsiveness of preview*/}
                            <div className={`aspect-ratio-wrapper ar-${dimArr[0]}-${dimArr[1]} `} onClick={(e)=>enableEdit(e)}>
                                <TemplateBody
                                    index={layer.index}
                                    id={layer.id}
                                    text={layer.text}
                                    file={layer.file}
                                    textStyle={layer.textStyle}
                                    slideType={layer.slideType}
                                    logo={layer.logo}
                                    watermark={layer.watermark}
                                    slideLength={layer.slideLength}
                                    duration={layer.duration}
                                    tts={layer.tts}
                                    playSlide={layer.playSlide}
                                    onInputSlideText={onInputSlideText}
                                    editStatus={editStatus}
                                    template_no={props.template_no}
                                    variation_id={layer.variation_id}
                                    dimension={props.dimension === '16:9' ? 'regular' : props.dimension === '1:1' ? 'square' : props.dimension === '9:16' ? 'story' : ''}
                                    outro={props.outro}
                                    is_outro={layer.is_outro}
                                    sound={layer.sound}
                                />
                                {
                                    props.dimmer === true ? <div className="dimmer-layer"> </div> : ''
                                }
                            </div>
                        </div>
                        <div className="controls-box" style={{ maxWidth: controlsWidth + '%' }}>
                            <div className="row m-0 w-100 justify-content-between align-items-center">
                                <div className={`cb-col cb-col-1 col-3 ${props.dimension === "9:16" ? 'preview-9-16-1' : ''}`}>
                                    <div className="start-end-time">
                                        <input
                                            type="text"
                                            className="control-time ct-start"
                                            placeholder={`${(props.selectedIndex+1)}/${props.slideLength}`}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                <div className={`cb-col cb-col-2 col-2 ${props.dimension === "1:1" ? 'preview-1-1' : ''}  ${props.dimension === "9:16" ? 'preview-9-16-2' : ''}`}>
                                    <div className="play-push-left-right">
                                        <ul className="list-inline d-flex justify-content-between mx-auto mb-0">
                                            <li>
                                                <a onClick={prevSlide}>
                                                    <img src={limg} alt="Previous Slide" className="cursor-pointer" />
                                                </a>
                                            </li>
                                            <li>
                                                {
                                                    playSlide === false ?
                                                        <a onClick={handlePlaySlide} >
                                                            <img src={pathimg} alt="Play Slide" className="cursor-pointer" />
                                                        </a>
                                                    :
                                                        <a onClick={handlePauseSlide}>
                                                            <img src={pauseImg} alt="Pause Slide" className="cursor-pointer" />
                                                        </a>
                                                }
                                                {/*<a onClick={() => handlePlaySlide()} >*/}
                                                {/*    <img src={pathimg} alt="Play Slide" className="cursor-pointer" />*/}
                                                {/*</a>*/}
                                            </li>
                                            <li>
                                                <a onClick={nextSlide}>
                                                    <img src={rimg} alt="Next Slide" className="cursor-pointer" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={`cb-col cb-col-3 col-3 ${props.dimension === "9:16" ? 'preview-9-16-3' : ''}`}>
                                    <div className="start-end-time">
                                        <input
                                            type="text"
                                            style={{cursor: "auto"}}
                                            className="control-time ct-end"
                                            readOnly
                                            placeholder={`${slideDuration.hours <= 9 ? '0' + slideDuration.hours : slideDuration.hours}:${slideDuration.minutes <= 9 ? '0' + slideDuration.minutes : slideDuration.minutes}:${slideDuration.seconds <= 9 ? '0' + slideDuration.seconds : slideDuration.seconds}`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Preview;