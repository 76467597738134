import {
  outro_regular_1,
  outro_regular_2,
  outro_regular_3,
  outro_regular_4,
  outro_square_1,
  outro_square_2,
  outro_square_3,
  outro_square_4,
  outro_story_1,
  outro_story_2,
  outro_story_3,
  outro_story_4,
} from "../../../Template/commonGsap/outro";

export const outro_export = (aspect, template, pauseTTS) => {
  if (aspect === "regular" && template === "1")
    return outro_regular_1(pauseTTS);
  if (aspect === "regular" && template === "2")
    return outro_regular_2(pauseTTS);
  if (aspect === "regular" && template === "3")
    return outro_regular_3(pauseTTS);
  if (aspect === "regular" && template === "4")
    return outro_regular_4(pauseTTS);

  if (aspect === "square" && template === "1") return outro_square_1(pauseTTS);
  if (aspect === "square" && template === "2") return outro_square_2(pauseTTS);
  if (aspect === "square" && template === "3") return outro_square_3(pauseTTS);
  if (aspect === "square" && template === "4") return outro_square_4(pauseTTS);

  if (aspect === "story" && template === "1") return outro_story_1(pauseTTS);
  if (aspect === "story" && template === "2") return outro_story_2(pauseTTS);
  if (aspect === "story" && template === "3") return outro_story_3(pauseTTS);
  if (aspect === "story" && template === "4") return outro_story_4(pauseTTS);
};
