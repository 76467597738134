import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Navbar from "../Navbar";
import DimensionPopUp from "./DimensionPopUp";
import {useDispatch, useSelector} from "react-redux";
import Alert from "../Alert";
import queryString from "query-string";
import {addCampaignType, addScriptText, removeScriptData} from "../../actions/scriptAction";
import {removeScrapImage, updateTranslate} from "../../actions/videoAction";
import axios from "axios";
import {baseURL} from "../../global/global";

const AddScript = ({location}) => {

    const type = queryString.parse(location.search).type;

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const videoText = useSelector(state => state.videos.text);
    const brandName = useSelector(state => state.rebrand.data);

    const [loadTranslate, setLoadTranslate] = useState(false)
    const [translateLang,setTranslateLang] = useState([]);
    const [selectLang, setSelectLang] = useState(false)
    const [rawText, setRawText] = useState(videoText?.text)

    const [statusDim ,setStatusDim] = useState(false);
    const [statusTemp ,setStatusTemp] = useState(false);
    const [dimension, setDimension] = useState('16:9');

    const [scriptData, setScriptData] = useState({
        title: '',
        text: '',
    });

    const onInputChange = (e) => {
        setScriptData({...scriptData, [e.target.name] : e.target.value});

        if(e.target.name == "text"){
            setRawText(e.target.value)
        }
    }


    const onFormSubmit = (e) => {
        e.preventDefault();

        dispatch(addScriptText(scriptData, setStatusDim));
    }

    const handleTranslate = () => {
        setLoadTranslate(true);

        axios({
            method: 'POST',
            url: `${baseURL}translate`,
            data: {lang_code: selectLang, text: rawText },
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            console.log(res);
            if(res.data.status === true){
                setRawText(res.data.data.text);
                setScriptData({...scriptData, text: res.data.data.text})
            }

            setLoadTranslate(false);
        }).catch(err=>{
            setLoadTranslate(false);
        })
    }

    useEffect(()=>{
        if(type !=='content'){
            setScriptData({...scriptData, text: videoText?.text});
            dispatch(addCampaignType(type));
        }

        return () => {
            if(type === "blog"){
                dispatch(removeScriptData());   // set initial state for script data
                dispatch(removeScrapImage());   // set initial state for script data
            }
        }

    },[videoText])

    useEffect(()=>{
        axios({
            method: 'POST',
            url: `${baseURL}fetch-google-language`,
            data: {},
            headers: {'Content-Type': 'application/json','Authorization': auth.token}
        }).then(res=>{
            if(res.data.status === true){
                setTranslateLang(res.data.data);
            }
        }).catch(err=>{

        })

    },[])




    return(
        <>
            <Alert/>
            <Helmet>
                <title>{brandName !== false ? brandName.name : 'ClipsReel'} | Add Script</title>
            </Helmet>
            <Navbar/>

            <section className="script-sec">

                {/*<div className="container">*/}
                {/*    <div className="row">*/}
                {/*        <div className="col-md-8 mx-auto">*/}
                {/*            <div className="form-group">*/}
                {/*                <label>Translate Text</label>*/}
                {/*                <select*/}
                {/*                    className="form-control editor-input-dark edit-input-height"*/}
                {/*                    name="font_family"*/}
                {/*                    onChange={(e) => setSelectLang(e.target.value)}*/}
                {/*                >*/}
                {/*                    <option disabled selected>Select Language</option>*/}
                {/*                    {*/}
                {/*                        translateLang.length > 0 ?*/}
                {/*                            translateLang.map((language, index) => {*/}
                {/*                                return (*/}
                {/*                                    <option value={language.code} key={index}>{language.name}</option>*/}
                {/*                                )*/}
                {/*                            })*/}
                {/*                            : ''*/}
                {/*                    }*/}
                {/*                </select>*/}
                {/*            </div>*/}

                {/*        </div>*/}
                {/*        <div className="col-md-4 mx-auto">*/}
                {/*            <button type="submit" className="btn-custom">*/}
                {/*                Next*/}
                {/*                <span className="ml-2"><i className="fa fa-angle-double-right"></i></span>*/}
                {/*            </button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <form className="container" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="form-group">
                                <label>Campaign Title</label>
                                <input type="text" name="title" onChange={(e)=>onInputChange(e)}
                                       className="form-control" placeholder="Title (Required)" required
                                />
                            </div>
                            <div className="form-group mt-5">
                                <label style={{display: "flex", justifyContent: 'space-between'}}>
                                    <div>Script <small style={{color: "#bbb5b5"}}>(Use [CLIPSREEL] to bifurcate slides)</small></div>
                                    <div className="d-flex text-right">
                                        <small style={{color: "#bbb5b5",fontSize: "15px", paddingBottom: "2px", marginRight: "5px"}}>Translate into </small>
                                        <select onChange={(e) => setSelectLang(e.target.value)}>
                                            <option disabled selected>Select Language</option>
                                            {
                                                translateLang.length > 0 ?
                                                    translateLang.map((language, index) => {
                                                        return (
                                                            <option value={language.code} key={index}>{language.name}</option>
                                                        )
                                                    })
                                                    : ''
                                            }
                                        </select>
                                        <button className="ml-1 btn-custom" style={{border: "none",padding:"2px 20px"}} type="button" onClick={()=>handleTranslate()} disabled={rawText && selectLang ?false:true}>
                                            {loadTranslate ? <i className="fa fa-spinner fa-spin"/> : 'Go'}
                                        </button>
                                    </div>
                                </label>
                                <textarea className="form-control" placeholder="Write or copy paste your script here" rows="13" name="text"
                                          required onChange={(e)=>onInputChange(e)}
                                          value={rawText}
                                >{rawText}</textarea>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-10 mx-auto d-flex justify-content-end">
                            <div className="script-create-btn">
                                <button type="submit" className="btn-change7">
                                        Next
                                    <span className="ml-2"><i className="fa fa-angle-double-right"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>


            </section>

            <DimensionPopUp setStatusDim={setStatusDim} statusDim={statusDim} setDimension={setDimension} setStatusTemp={setStatusTemp} />
            {/*<TemplatePopUp setStatusTemp={setStatusTemp} statusTemp={statusTemp} setTemplate={setTemplate} generateScriptData={generateScriptData} />*/}
        </>
    )
}

export default AddScript;