import styled from "styled-components";

export const Regular6Style = styled.div`
  width: 100%;
  height: 100%;
  .container_two_six .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container_two_six {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .f-orange {
    color: #ff7b3f;
  }

  .f-white {
    color: #fff;
  }

  .f-pink {
    color: #fbc5d0;
  }

  .container_two_six div {
    font-family: "Poppins", sans-serif;
    line-height: 1.2;
    font-weight: 600;
    margin: 0;
  }

  .container_two_six .back-layer {
    width: 100%;
    height: 100%;
    background: #fe793d;
  }

  .container_two_six .back-layer img {
    opacity: 0.5;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container_two_six .text-wrappers {
    position: absolute;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    left: 0;
    padding: 0 20px;
    height: max-content;
    opacity: 0;
    z-index: +99999;
    bottom:5%;
  }

  .container_two_six .text-wrappers div {
    font-size: 18px;
    flex: 0 0 100%;
    text-align: right;
  }

  .container_two_six .text-wrappers div:last-child {
    font-size: 12px;
    margin-top: 7px;
  }

  .container_two_six .front-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 1;
  }

  .container_two_six .front-layer .col {
    flex: 0 0 10%;
    max-width: 10%;
  }

  .container_two_six .front-layer .col .fw {
    height: 315px;
    background: #000;
    width: 100%;
  }
`;
