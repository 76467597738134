import React from "react";
import { Portait7Style } from "./css/Portrait7Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return (
    <Portait7Style>
      <div className="template-wrapper">
        <div className="poster seven nine-by-sixteen">
          <TemplateLogo logo={props.logo} />
          <TemplateWatermark watermark={props.watermark} />
          <div className="photo-container flex-column">
            <div className="photo-outer flex-column">
              <div className="photo-inner">
                <TemplateImage slideType={props.slideType} file={props.file} />
              </div>
            </div>
          </div>
          <div className="text-wrapper flex-column">
            <TemplateText
              id={props.id}
              text={props.text}
              textStyle={props.textStyle}
              editStatus={props.editStatus}
              onInputSlideText={props.onInputSlideText}
            />
          </div>
        </div>
      </div>
    </Portait7Style>
  );
};

export default Template;
