import styled from "styled-components";

export const Portait2Style = styled.div`
  width: 100%;
  height: 100%;
  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container5 {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }



  .back-layer {
    width: 100%;
    height: 100%;
    background: #007a79;
  }

  .back-layer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
    object-position: 50% 60%;
  }

  .text-wrapper {
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-wrapper > div {
    margin-top: 5%;
  }
`;
