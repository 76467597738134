import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    addSlideDuration,
    changeImage, deleteActiveSlide, deleteSlideData,
    generateBlankSlide,
    generateSlideData, handleDragStatus, removeOutro,
    updateSelectedTiles,
    updateSlidesData, updateSlideSound,
    updateVideoThumbnail
} from "../../actions/videoAction";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FileCrop from "./FileCrop";
import swal from "sweetalert";

const VideoTiles = ({ videos, dimension, template_id, selectedIndex, setOutro }) => {

    let dimArr = dimension.split(':');

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const slide20 = ['ryoungblood77@gmail.com', 'sumit@vega6.com', 'jjsmith@westnet.com.au', 'horizonunl@gmail.com'];
    const slide30 = ['salinasgino@yahoo.com', 'khalifsbm69@gmail.com', 'carla4001@gmail.com', 'guen.schmitt@yahoo.de', 'sisawoyusupha@yahoo.com', 'gisela@quakecityvideo.com', 'mr880usa@comcast.net', 'doc@iplas.com', 'leonardjffrsn@yahoo.com', 'rkmtnhi@gmail.com', 'clickonadmon@gmail.com', 'aiia.dsilva@gmail.com', 'shussey@testathletes.com', 'constance.hall@gmail.com', 'prismaelicoidale@gmail.com', 'Albaracin38@gmail.com', 'kblack59@gmail.com', 'albaracin38@gmail.com', 'darlan@adsolver.com.br', 'davecarrau@gmail.com', 'niches@marketingbiztips.com', 'simon@adasim.com', 'jbarnett7860@gmail.com', 'itajjohn@gmail.com', 'watersjulienne@gmail.com', 'olympiovital@gmail.com', 'enzo.giovinazzo77@gmail.com', 'menajohn25@gmail.com', 'pakdoctor@hotmail.com', 'norm@normbond.com', 'john.brack@gmail.com', 'dennistchen@yahoo.com', 'jolinmktg@gmail.com'];
    const slide50 = ['titogarciaogoy@gmail.com', 'rosonero484@gmail.com', 'constance.hall@gmail.com', 'Constance.hall@gmail.com', 'tiffyh2000@gmail.com', 'c023y.l3w15@gmail.com', 'dwdvids@gmail.com', 'andrew.smith02@hotmail.com', 'john.brack@gmail.com'];
    const slide100 = ['wpcontentdiscovery@gmail.com', 'rkariola19@hotmail.com', 'amous.ken@gmail.com'];
    const slide120 = ['pakdoctor@hotmail.com'];

    const [cropModel, setCropModel] = useState(false);
    const [cropImg, setCropImg] = useState('');
    const [cropIndex, setCropIndex] = useState('');
    const [cropType, setCropType] = useState('image');
    const [dragCamp, setDragCamp] = useState(true);

    const [tiles, setTiles] = useState([]);
    const [storyData, setStoryData] = useState([]);
    const [loadSlide, setLoadSlide] = useState(false);
    const [loaderNew, setLoaderNew] = useState(false);
    const [currentLoad, setCurrentLoad] = useState(false);
    const [refreshLoader, setRefreshLoader] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);

    const generateNewSlides = (text, index) => {
        dispatch(generateSlideData(text, index, template_id, setLoadSlide, false, false));

    }

    const activeSlide = (index) => {
        dispatch(updateSelectedTiles(index));
        setOutro(false);
    }

    const addMoreSlide = () => {
        let slideLimit = 15;
      
        if (slide20.includes(auth.user.email)) {
            slideLimit = 20;
        }

        if (slide30.includes(auth.user.email)) {
            slideLimit = 30;
        }

        if (slide50.includes(auth.user.email)) {
            slideLimit = 50;
        }

        if (slide100.includes(auth.user.email)) {
            slideLimit = 100;
        }

        if (slide120.includes(auth.user.email)) {
            slideLimit = 120;
        }

        if (auth.user) {
            if (auth.user.membership) {
                let arr = auth.user.membership.split("__")
                if (arr.includes("unlimited")) {
                    slideLimit = 50
                }
            }
        }


        if (videos.data.slides.length < slideLimit) {
            setLoaderNew(true);
            let randomKey = Math.floor(Math.random() * (7 - 1)) + 1;

            let arr = tiles[tiles.length - 1];
            dispatch(generateBlankSlide(arr.keyword, (tiles.length + 1), template_id, randomKey, setLoaderNew))
        } else {
            swal('Oops..', `You cannot add more than ${slideLimit} slides`);
        }
    }

    const refreshSlide = (keyword, index) => {
        setRefreshLoader(true);
        setCurrentLoad(index);
        dispatch(changeImage(keyword.trim(), 'image', index, setRefreshLoader))
    }

    const deleteSlide = (index, selected) => {
        let newIndex = index;

        if (selected === false) {
            tiles.splice(index, 1);

            dispatch(deleteSlideData(tiles));
        }
        else {
            if (videos.data.slides.length > 1) {
                if (index === 0) {
                    newIndex = index;
                } else {
                    newIndex = index - 1;
                }

                tiles.splice(index, 1);
                dispatch(deleteActiveSlide(tiles, newIndex));

            } else {
                swal('Oops..', 'You cannot delete last slide');
            }
        }
    }

    const handleCrop = (image, index, type) => {
        setCropModel(true);
        setCropImg(image);
        setCropIndex(index);
        setCropType(type);

    }

    const addDuration = (e, index) => {
        if (e.target.value <= 60 && e.target.value >= 1) {
            dispatch(addSlideDuration(e.target.value, index));
        }
    }

    const handleRemoveOutro = () => {
        dispatch(removeOutro());
    }

    /** Drag Tiles*/
    const onSetRecorder = (result) => {

        if (!result.destination) {
            return;
        }

        const items = reorder(
            tiles,
            result.source.index,
            result.destination.index
        );

        dispatch(updateSlidesData(items));
        dispatch(updateVideoThumbnail(items[0].poster));
        dispatch(handleDragStatus());
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const soundUpdate = (status, index) => {
        dispatch(updateSlideSound(status, index));
    }

    useEffect(() => {
        if (videos.data.slides.length > 0) {
            setTiles(videos.data.slides);
        }
        setStoryData(videos.data.storyboard);
    }, [videos.data.slides]);

    // Send request for generate new slides
    useEffect(() => {
        if (storyData.length > 0) {
            storyData.slice(0, 8).map((item, index) => {
                if (item.status === false) {
                    setLoadSlide(true);
                    generateNewSlides(item.text, index)
                }
            })
        }

    }, [storyData]);


    useEffect(() => {
        setDragCamp(videos.data.is_drag === "0" ? false : true);
    }, [videos.data.is_drag])


    return (
        <>
            {console.log('tiles render')}
            <section className="slide-sec">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="slide-box">
                                <DragDropContext onDragEnd={onSetRecorder}>
                                    <Droppable droppableId="droppable" direction="horizontal">
                                        {(provided) => (
                                            <ul className="d-flex list-inline align-items-center m-0"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                            >
                                                {
                                                    tiles.length > 0 ?

                                                        tiles.sort((a, b) => (a.uniq_id > b.uniq_id) ? 1 : dragCamp === false ? -1 : 1).map((item, index) => {
                                                            return (
                                                                <Draggable key={index} draggableId={`id_${index}`} index={index}>
                                                                    {(provided) => (
                                                                        <li
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            key={index}
                                                                            className={`${item.is_selected === true ? 'active-slide' : ''}`}
                                                                        >
                                                                            <div style={{ position: "relative" }} className={`box-ar-wrapper slider-${dimArr[0]}-${dimArr[1]}`} onClick={(e) => activeSlide(index)}>
                                                                                <img src={item.poster} alt="" className="img-fluid" />
                                                                            </div>
                                                                            <div className="video-tiles-controls">
                                                                                {/*<div className="video-tiles-time-duration">*/}
                                                                                {/*    <span>Duration:</span><input type="number" min="1" max="60" value={item.duration} onChange={(e) => addDuration(e, index)} name="duration" className="vttd" />*/}
                                                                                {/*</div>*/}
                                                                                {
                                                                                    item.is_outro === false ?
                                                                                        <div className="video-tiles-time-duration cursor-pointer ml-1" onClick={() => handleCrop(item.file_url, index, item.type)}>
                                                                                            <i className="fa fa-crop"></i>
                                                                                        </div>
                                                                                        : ''
                                                                                }

                                                                                <div className="refresh-delete">
                                                                                    {/*{*/}
                                                                                    {/*    item?.sound === true ?*/}
                                                                                    {/*        <a title="Pause Campaign Audio for this slide" className="cursor-pointer" onClick={()=>soundUpdate(false,index)}>*/}
                                                                                    {/*            <i className="fas fa-volume-up"></i>*/}
                                                                                    {/*        </a>*/}
                                                                                    {/*    : <a title="Play Campaign Audio for this slide" className="cursor-pointer" onClick={()=>soundUpdate(true,index)}>*/}
                                                                                    {/*            <i className="fas fa-volume-mute"></i>*/}
                                                                                    {/*        </a>*/}
                                                                                    {/*}*/}
                                                                                    {
                                                                                        item.is_outro === false ?
                                                                                            <a className="cursor-pointer"
                                                                                                onClick={(e) => refreshSlide(item.keyword, index)}
                                                                                            >
                                                                                                {
                                                                                                    refreshLoader && currentLoad === index ? <i className="fa fa-spinner fa-spin" /> : <i className="fas fa-redo-alt"></i>
                                                                                                }
                                                                                            </a>
                                                                                            : ''
                                                                                    }
                                                                                    <a className="cursor-pointer"
                                                                                        onClick={(e) => deleteSlide(index, item.is_selected)}
                                                                                    >
                                                                                        {
                                                                                            deleteLoader ? <i className="fa fa-spinner fa-spin" /> : <i className="far fa-trash-alt"></i>
                                                                                        }
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                        })
                                                        : ''
                                                }

                                                {
                                                    loadSlide === true ?
                                                        storyData.slice(1, 8).map((story, i) => {
                                                            return (
                                                                story.status === false ?
                                                                    <li key={i}>
                                                                        <div className="box-ar-wrapper" style={{ width: "100%" }}>
                                                                            <img alt="" className="img-fluid" />
                                                                            <p><i className="fa fa-spinner fa-spin mr-2" /></p>
                                                                        </div>
                                                                    </li>
                                                                    : ''
                                                            )
                                                        })

                                                        : ''
                                                }

                                                {
                                                    loaderNew === true ?
                                                        <li >
                                                            <div className="box-ar-wrapper" style={{ width: "100%" }}>
                                                                <img alt="" className="img-fluid" />
                                                                <p><i className="fa fa-spinner fa-spin mr-2" /></p>
                                                            </div>
                                                        </li>
                                                        : ''
                                                }

                                                {provided.placeholder}
                                            </ul>
                                        )
                                        }
                                    </Droppable>
                                </DragDropContext>

                                {/*<ul className="d-flex list-inline align-items-center m-0">*/}
                                {/*    {*/}
                                {/*        videos.data.outro !== false ?*/}
                                {/*            <li>*/}
                                {/*                <div style={{ position: "relative" }} className={`box-ar-wrapper cursor-pointer slider-${dimArr[0]}-${dimArr[1]}`} onClick={()=>loadOutroData()}>*/}
                                {/*                    <img src={videos.data.outro.thumbnail} alt="" className="img-fluid" />*/}
                                {/*                    <div className="video-tiles-controls">*/}
                                {/*                        <div className="refresh-delete">*/}
                                {/*                            <a className="cursor-pointer"*/}
                                {/*                                onClick={()=>handleRemoveOutro()}*/}
                                {/*                            >*/}
                                {/*                                {*/}
                                {/*                                    <i className="far fa-trash-alt"></i>*/}
                                {/*                                }*/}
                                {/*                            </a>*/}
                                {/*                        </div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </li>*/}
                                {/*        : ''*/}
                                {/*    }*/}
                                {/*</ul>*/}

                                <div className="plus-icon">
                                    <a className="cursor-pointer" onClick={(e) => addMoreSlide()}>
                                        <i className="fas fa-2x fa-plus-circle"></i>
                                    </a>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                cropModel ? <FileCrop selectedIndex={cropIndex} dimension={dimension} setCropModel={setCropModel} image={cropImg} type={cropType} /> : ''
            }

        </>
    )
}

export default React.memo(VideoTiles);