import styled from "styled-components";

export const Portait1Style = styled.div`
  width:100%;
  height:100%;

  
  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }


  .poster {
    position: relative;
    overflow: hidden !important;
  }

  .poster.nine-by-sixteen {
    width: 1080px;
    height: 1920px;
  }


  .poster.nine-by-sixteen {
    height: 100%;
    width: 100%;
  }


  .bg-container {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .bg {
    position: absolute;
    z-index: 1;
    height: 120%;
    width: 120%;
    left: -130px;
    top: -12%;
  }

  .cover {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(161, 223, 251, 0.3);
  }



  .ta {
    font-size: 62px;
    line-height: 70px;
    margin: 0;
    font-weight: bold;
    opacity: 0;
  }

  .sub-ta-container {
    position: absolute;
    top: -60px;
    left: 0;
    width: 100%;
  }

  .sub-ta {
    font-size: 46px;
    line-height: 46px;
    margin: 0;
    font-weight: bold;
  }

  .bg {
    height: 120%;
    width: 120%;
    left: -20px;
    top: -12%;
  }

  .text-wrapper {
    overflow: hidden;
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    
  }

  
`;
