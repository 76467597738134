import { gsap, Linear, Power1, Power4, Power2 } from "gsap";
import { init } from "./common";

export const regular_1_1 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.t1-woman img', { scale: 1 }, { duration: 3, scale: 1.2, ease: Linear.easeNone }, 1);
    localTimeline.fromTo('.t1-text-wrappers div', { right: -1500 }, { duration: 3, right: 0, stagger: 0.4 }, 1);
    return localTimeline;
}

export const regular_1_2 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    //localTimeline.set('.t2-text-wrappers div',{left:'100%',autoAlpha:0})
    localTimeline.fromTo('.t2-woman img', { scale: 1.2, transformOrigin: "200px 50%" }, { scale: 1, duration: 5, ease: Linear.easeNone }, 1);

    localTimeline.fromTo('.t2-t-colmn-1', { css: { maxWidth: "100%" } },
        {
            duration: 1,
            css: { maxWidth: "60%" },
        }, 1);

    localTimeline.fromTo('.t2-text-wrappers div', {left:'100%',autoAlpha:0}, {
        duration: 1,
        left:0,
        autoAlpha:1,
        stagger: 0.2
    }, +3)

    return localTimeline;
}

export const regular_1_3 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.t3-woman img', { scale: 1.2, transformOrigin: "200px 50%" }, { duration: 5, scale: 1, ease: Linear.easeNone }, 1);

    localTimeline.fromTo('.t3-t-colmn-1', { css: { maxWidth: "100%" } },
        {
            css: { maxWidth: "60%" },
            duration: 1
        }, 1);
    localTimeline.fromTo('.t3-text-wrappers div', {left:'100%',autoAlpha:0}, {
        left: 0,
        autoAlpha: 1,
        stagger: 0.2,
        duration: 1,
    }, +3);

    return localTimeline;
}

export const regular_1_4 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);

    localTimeline.fromTo('.t4-woman img', { scale: 1 }, { duration: 10, scale: 1.3, ease: Linear.easeNone },1.1);
    localTimeline.fromTo('.t4-text-wrappers', {}, { css: { clipPath: "inset(0% 0% 0% 0%)" }, duration: 1 }, 1);
    return localTimeline;
}

export const regular_1_5 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.t5-woman img', { scale: 1.1 }, { duration: 5, scale: 1, ease: Linear.easeNone }, 1);

    localTimeline.fromTo('.t5-first-layer .t5-t-colmn-1', {}, { css: { maxWidth: "30%" }, duration: 1 }, 1);

    localTimeline.fromTo('.t5-second-layer .t5-img-wrapper', {}, {
        css: { width: "100%" },
        duration: 1
    }, 1);
    localTimeline.fromTo('.t5-text-wrappers div', { autoAlpha: 0 }, { autoAlpha: 1, duration: 1, left: 0, stagger: 0.2 }, 1);
    return localTimeline;
}

export const regular_1_6 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.t6-woman', {}, { css: { clipPath: "circle(35% at 50% 50%)" }, duration: 4, ease: "expo.out", }, 1);
    localTimeline.fromTo('.t6-woman img', { scale: 1 }, { duration: 5, scale: 1.2 }, +1);
    localTimeline.fromTo('.t6-text-wrappers div', { autoAlpha: 0 }, { autoAlpha: 1, duration: 2 }, +2);
    return localTimeline;
}


export const regular_1_7 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.t7-woman', { left: "-10%", duration: 1 });
    localTimeline.to('.t7-woman img', { scale: 1.1, duration: 3 }, 1);
    localTimeline.fromTo('.t7-text-wrappers div', { autoAlpha: 0 }, { autoAlpha: 1, duration: 1 }, 0.5);
    return localTimeline;
}


export const regular_1_8 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.t8-woman img', { scale: 1, duration: 5, ease: Linear.easeNone }, 1);
    localTimeline.to('.t8-text-wrappers', { css: { clipPath: "inset(0% 0% 0% 0%)" }, duration: 1 }, 1);
    return localTimeline;
}


export const square_1_1 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.bg', { scale: 1.2, ease: Linear.easeNone, duration: 3 }, 1)
    // localTimeline.to('.text-wrapper div', {margin: '0 20px 0 0', duration:3, stagger: 0.1}, 1);
    localTimeline.fromTo('.text-wrapper div', { right: -1500 }, { duration: 3, right: 0, stagger: 0.4 }, 1);

    return localTimeline;
}

export const square_1_2 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.poster-content', { width: '185px', ease: Linear.easeNone, duration: 1 }, 1)
    localTimeline.fromTo('.bg', { scale: 1 }, { scale: 1.2, ease: Linear.easeNone, duration: 5 }, 1)
    localTimeline.fromTo('.text-wrapper div', { autoAlpha: 0, marginLeft: '-200%' }, {
        duration: 1,
        marginLeft: 0,
        autoAlpha: 1,
        stagger: 0.2
    }, +3);
    return localTimeline;
}
export const square_1_3 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.poster-content', { width: '180px', ease: Linear.easeNone, duration: 1 })
    localTimeline.fromTo('.bg', { scale: 1 }, { scale: 1.2, ease: Linear.easeNone, duration: 5 }, 1)
    localTimeline.fromTo('.text-wrapper div', { autoAlpha: 0, marginRight: '-200%' }, { marginRight: 0, autoAlpha: 1, margin: '0 20px 0 0', duration: 1, stagger: 0.1 }, +2);
    return localTimeline;
}
export const square_1_4 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.bg', { scale: 1 }, { scale: 1.2, ease: Linear.easeNone, duration: 3 }, 1)
    localTimeline.to('.poster-content', { width: '195px', right: 25, ease: Linear.easeOut, duration: 1 }, +3)
    return localTimeline;
}
export const square_1_5 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.sidepanel', { width: 100, ease: Linear.easeOut, duration: 1 }, 1)
    localTimeline.to('.photo', { scale: '1.1', ease: Linear.easeNone, duration: 5 }, 1)
    localTimeline.to('.photo-container', { width: '120', ease: Linear.easeOut, duration: 1 }, +3)
    localTimeline.to('.ta', { margin: '0', ease: Linear.easeOut, duration: 0.5, stagger: 0.04 }, +4)
    localTimeline.to('.sub-ta', { margin: '8px 0 0 0', ease: Linear.easeOut, duration: 1, stagger: 0.2 }, +4)
    return localTimeline;
}
export const square_1_6 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.photo', { width: 120, height: 120, ease: Linear.easeOut, duration: 1 })
    localTimeline.fromTo('.text-wrapper div', { autoAlpha: 0 }, { autoAlpha: 1, ease: Linear.easeOut, duration: 1 })
    return localTimeline;
}
export const square_1_7 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.photo-container', { left: '-65px', ease: Linear.easeOut, duration: 1 })
    localTimeline.to('.photo-inner', { scale: 1.05, ease: Linear.easeNone, duration: 2.5 }, '-=0.5')
    localTimeline.fromTo('.text-wrapper div', {autoAlpha:0},{ autoAlpha: 1, ease: Power1.easeOut, duration: 0.8 }, '-=2.5')
    return localTimeline;
}
export const square_1_8 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.from('.ta-outer', { width: 0, ease: Power1.easeOut, duration: 0.5 })
        .to('.bg', { scale: 0.9, ease: Linear.easeNone, duration: 5 }, -5)
    return localTimeline;
}













export const story_1_1 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.bg', { scale: 1 }, { scale: 1.2, ease: Linear.easeIn, duration: 5 }, 1)
    localTimeline.fromTo('.text-wrappers div', { marginLeft: "200%" }, { marginLeft: 0, duration: 1, stagger: 0.1 }, +3);
    return localTimeline;
}

export const story_1_2 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.poster-content', { width: '100%' }, { width: '100px', ease: Linear.easeNone, duration: 1 }, 1)
    localTimeline.fromTo('.bg', { scale: 1 }, { scale: 1.15, backgroundPosition: '-105px center', ease: Linear.easeNone, duration: 5 }, 1)
    localTimeline.fromTo('.text-wrappers div', { marginRight: "-200%" ,autoAlpha:0}, { autoAlpha:1, marginRight: 0, duration: 1, stagger: 0.1 }, 3);
    return localTimeline;
}

export const story_1_3 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.poster-content', { width: '100%' }, { width: '100px', ease: Linear.easeNone, duration: 1 }, 1)
    localTimeline.fromTo('.bg', { scale: 1 }, { scale: 1.2, ease: Linear.easeNone, duration: 5 }, 1)
    localTimeline.fromTo('.poster-content div', { right: -100,autoAlpha:0 }, { autoAlpha:1, right: 0, duration: 1, stagger: 0.1 }, +3);
    return localTimeline;
}

export const story_1_4 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.bg', { scale: 1 }, { scale: 1.1, ease: Linear.easeNone, duration: 5 })
    localTimeline.to('.poster-content', { width: '112px', right: 10, ease: Power2.easeOut }, '-=4')
    return localTimeline;
}

export const story_1_5 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.sidepanel', { width: 55, ease: Power1.easeOut, duration: 1 }, 1)
    localTimeline.to('.photo', { scale: '1.1', ease: Linear.easeNone, duration: 5 }, +2)
    localTimeline.to('.photo-container', { width: '110', right: -5, ease: Power2.easeOut, duration: 1 }, 1)
    localTimeline.to('.ta', { margin: '0', ease: Power4.easeOut, duration: 0.5, stagger: 0.04 }, +2)
    localTimeline.fromTo('.text-wrapper div', { right: 100 }, { right: 0, ease: Power4.easeOut, duration: 1, stagger: 0.2 }, +2)
    return localTimeline;
}

export const story_1_6 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.photo', { width: '80px', height: '80px', ease: Power1.easeOut, duration: 1 })
    localTimeline.fromTo('.text-wrapper div', { autoAlpha: 0 }, { autoAlpha: 1, ease: Power1.easeOut, duration: 1 }, '-=0.3')
    return localTimeline;
}

export const story_1_7 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.to('.photo-container', { left: '-105px', ease: Power1.easeOut, duration: 0.8 })
    localTimeline.to('.photo-inner', { scale: 1.05, ease: Linear.easeNone, duration: 2.5 }, '-=0.5')
    localTimeline.fromTo('.text-wrapper div', { autoAlpha: 0 }, { autoAlpha: 1, ease: Power1.easeOut, duration: 0.8 }, '-=2.5')
    return localTimeline;
}

export const story_1_8 = (pauseTTS) => {
    const localTimeline = init(pauseTTS);
    localTimeline.fromTo('.text-wrapper div', { left: -200 }, { left: 0, ease: Power1.easeOut, duration: 0.5 })
    localTimeline.to('.bg', { scale: 0.9, ease: Linear.easeNone, duration: 5 }, '-=0.5')
    return localTimeline;
}


