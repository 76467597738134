import styled from "styled-components";

export const Square6Style = styled.div`
  .flex-column {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-center {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .flex-row-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: left;
    justify-content: left;
  }

  .subflex-left {
    width: 100%;
    text-align: left;
  }

  .subflex-right {
    width: 100%;
    text-align: right;
  }

  .subflex-center {
    width: 100%;
    text-align: center;
  }
  .text-wrapper {
    white-space: pre-wrap;
  }
  /*.f-arial {*/
  /*	font-family: Arial;*/
  /*}*/

  /*.f-mvboli {*/
  /*	font-family: mvboli;*/
  /*}*/

  /*.f-nexalight {*/
  /*	font-family: nexalight;*/
  /*}*/

  /*.f-nexabold {*/
  /*	font-family: nexabold;*/
  /*}*/

  .f-cyan {
    color: #00fffc;
  }

  .f-cyan-dark {
    color: #00d1cf;
  }

  .f-blue {
    color: #005596;
  }

  .f-white {
    color: #ffffff;
  }

  .poster {
    position: relative;
    overflow: hidden !important;
  }

  .poster.nine-by-sixteen {
    width: 1080px;
    height: 1920px;
  }

  .poster.one-by-one {
    width: 310px;
    height: 310px;
  }

  .poster.nine-by-sixteen {
    width: 171px;
    height: 304px;
  }

  .poster.six {
    background-color: #00d1cf;
  }

  .poster.six > div {
    height: 100%;
  }

  .poster.one-by-one.six .photo-container {
    height: 700px;
    width: 100%;
    margin-bottom: 180px;
  }

  .poster.one-by-one.six .photo {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
  }

  .poster.one-by-one.six .ta {
    font-size: 80px;
    line-height: 98px;
    margin: 0;
    font-weight: bold;
    /*opacity: 0;*/
  }

  .poster.one-by-one.six .photo-container {
    height: 100px;
    width: 100%;
    margin-bottom: 40px;
  }

  .poster.one-by-one.six .photo {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
  }

  .poster.one-by-one.six .ta {
    font-size: 13px;
    line-height: 17px;
    margin: 0;
    font-weight: bold;
    opacity: 0;
  }

  .poster.one-by-one.six .photo img{
    border-radius: 50%;
  }
  
`;
