import styled from "styled-components";

export const Portait5Style = styled.div`
width:100%;
height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container7 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-white {
    color: #fff;
  }

  .f-pippin {
    color: #ffe0db;
  }

  .f-cinnabar {
    color: #ec4f43;
  }

  .f-cinnabar-transparency {
    color: #f3928b;
  }

  .text-wrapper div {
    font-family: "Nexa", sans-serif;
    line-height: 1em;
    font-weight: 600;
    margin: 0;
  }

  .back-layer {
    width:100%;
  height:100%;
    background: #ec4f43;
  }

  .ellipse {
    width: auto;
    height: 100%;
    background: #fe948d;
    clip-path: circle();
    transform: scale(1.4);
    margin-left: -100%;
  }

  .front-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .front-layer .col {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .back-layer img,
  .back-layer video {
    width: 100%;
    height: 35vw;
    object-fit: cover;
    object-position: center;
  }

  .col .vid-wrapper {
    width: 80%;
    height: 207px;
    margin-left: auto;
    left: -99.9%;
    overflow:hidden;
  }

  .border-wrap {
    position: absolute;
    width: 100%;
    height: 45%;
    top: 25%;
  }

  .border-wrap .border {
    background: #fff;
    border-radius: 50px;
    position: absolute;
  }

  .top-right-to-bottom-right {
    width: 3.5%;
    height: 0%;
    right: -0.3vw;
    top: -0.19vh;
  }

  .bottom-right-to-bottom-left {
    height: 3.9%;
    width: 0%;
    bottom: 0;
    right: 0;
  }

  .bottom-left-to-top-left {
    width: 3.5%;
    height: 0%;
    bottom: 0.19vh;
    left: -0.3vw;
  }

  .top-left-to-top-right {
    height: 3.9%;
    width: 0%;
    top: -0.19vh;
  }

  .text-wrapper {
    z-index: 1;
    // position: absolute;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

  .text-wrapper > div {
    width:100%;
  }

`;
