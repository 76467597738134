import styled from "styled-components";

export const Regular8Style = styled.div`
width: 100%;
height: 100%;
.
  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .f-white {
    color: #fff;
  }

  // .text-wrapper div {
  //   font-family: "Nexa", sans-serif;
  //   line-height: 1.2em;
  //   font-weight: 600;
  //   margin: 0;
  // }

  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: #f4c88c;
  }

  .front-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
 
  }

  .front-layer .img-wrapper {
    width: 100%;
    height: 100%;
    /* left: 101vw; */
  }

  // .front-layer .img-wrapper .wrap {
  //   width: 100%;
  //   height: 100%;
  //   overflow: hidden;
  //   opacity: 0.8;
  // }

  .front-layer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
    transform: scale(1.1);
    left: 5%;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .text-wrapper div {
    width:100%;
  }


`;
