import React from 'react';

const TemplateLogo = (props) => {
    return (
        <>
            {
                props.logo !== "" ?
                    <div className={`logo-${props.logo.position} logo-style`}><img src={props.logo.url} alt="logo"/></div>
                    : ''
            }
        </>
    );
};

export default TemplateLogo;
