import styled from "styled-components";

export const Portait8Style = styled.div`
width:100%;
height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container8 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-white {
    color: #fff;
  }

  .text-wrapper div {
    width:100%
  }

  .back-layer {
    width:100%;
height:100%;
    background: #2f7dff;
    background: -moz-linear-gradient(left, #2f7dff 0%, #ff48d2 100%);
    background: -webkit-linear-gradient(left, #2f7dff 0%, #ff48d2 100%);
    background: linear-gradient(to right, #2f7dff 0%, #ff48d2 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f7dff', endColorstr='#ff48d2',GradientType=1 );
  }

  .back-layer img,
  .back-layer video {
    width:100%;
height:100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.5;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: #2f7dffd6;
    clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    width:100%;
    height:40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }


  .letter-space {
    letter-spacing: 0.15vw;
  }
`;
