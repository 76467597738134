import React, { useRef, useState, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import ContentEditable from "react-contenteditable";
import parse, { domToReact } from "html-react-parser";

const OutroText = (props) => {
  const editableRef = useRef();

  const [text, setText] = useState({
    id: props.id,
    text: props.text,
    textStyle: props.textStyle,
    editStatus: props.editStatus,
  });

  useEffect(() => {
    setText({
      id: props.id,
      text: props.text,
      textStyle: props.textStyle,
      editStatus: props.editStatus,
    });
  }, [props.id, props.editStatus, props.textStyle, props.text]);

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }

      if (attribs.id === text.id) {
        return (
          <div id={text.id} style={props.textStyle}>
            {domToReact(children, options)}
          </div>
        );
      }
    },
  };

  const handleChange = (e) => {
    let parseHtml = parse(e.target.value, options);

    console.log(renderToStaticMarkup(parseHtml),'parse outrrore')

    setText({ ...text, text: e.target.value });
    props.onInputSlideText(renderToStaticMarkup(parseHtml));
  };

  return (
    <>
      {text.editStatus ? (
        <ContentEditable
          innerRef={editableRef}
          html={`${text.text}`} // innerHTML of the editable div
          disabled={false} // use true to disable editing
          onChange={(e) => handleChange(e)} // handle innerHTML change
          style={props.textStyle}
          tagName="" // Use a custom HTML tag (uses a div by default)
        />
      ) : (
        parse(`${text.text}`, options)
      )}
    </>
  );
};

export default OutroText;
