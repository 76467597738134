import React, {useState} from "react";
import {useSelector} from "react-redux";
import queryString from "query-string";
import {Link} from "react-router-dom";

const TrainingTitles = ({location, name}) => {

    const supportData = useSelector(state => state.support);
    const articleId = queryString.parse(location.search).id;

    const [articles, setArticles] = useState(supportData.articles);
    // const currentArt = articles.filter((article)=> article.id === articleId);


    return(
        <>
            <div className="col-md-4">
                <div className="popular-topics-box">
                    <h5 className="mt-2 text-white">POPULAR TOPICS</h5>
                    <div className="nav flex-column" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        {
                            articles.length > 0 ?
                                articles.map((item,index)=>{
                                    return(
                                        <Link key={index} className={`nav-link ${articleId === item.id ? 'active' : '' }`} to={`/training?id=${item.id}`} >
                                            {item.name}
                                        </Link>
                                    )
                                })
                            : ''
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default TrainingTitles;