import React, { useEffect, useState } from "react";
import SOUNDWAVE from "../../images/SOUND WAVE.png";
import { useDispatch } from "react-redux";
import {addAudioToSlides, removeAudioFromSlides} from "../../actions/videoAction";
import SweetAlert from "react-bootstrap-sweetalert";

let audio = false;
const AudioFiles = ({ title, url, currentUrl, setCurrentUrl, type, selectedAudio, setSelectedAudio, removeMedia, id }) => {

    const dispatch = useDispatch();

    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState(false);
    const [audioDuration, setAudioDuration] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [buttonText, setButtonText] = useState('Delete');

    let clearPause;
    const playAudio = (url) => {

        if (audio !== false) {
            audio.pause();
            setPlay(false);
        }

        setLoader(true);
        audio = new Audio(url);
        audio.play()
            .then(r => setLoader(false)).catch(err => console.log(err));

        setCurrentUrl(url);

        setPlay(true)

        clearPause = setTimeout(() => {
            pauseAudio()
        }, audioDuration * 1000)

    }

    const pauseAudio = (url) => {

        if (audio !== false) {
            clearTimeout(clearPause);
            audio.pause();
            setPlay(false);
            audio = false;
        }
    }

    const selectAudio = (url) => {
        setSelectedAudio(url);
        dispatch(addAudioToSlides(url))
    }

    const removeAudio = (url) => {
        setSelectedAudio('');
        dispatch(removeAudioFromSlides())
    }

    const onConfirm = async () => {
        setButtonText('Deleting...');
        await removeMedia(id);
        setShowDelete(false);
        setButtonText('Delete');
    }

    const showPopUp = (id) => {
        setShowDelete(true);
    }



    useEffect(() => {
        return () => {
            if (audio !== false) {
                audio.pause();
            }
        }
    }, [])


    useEffect(() => {
        let au = document.createElement('audio');
        au.src = url;
        au.addEventListener('loadedmetadata', function () {
            setAudioDuration(Number((au.duration).toFixed(1)));
        }, false);
    }, [])

    return (
        <>
            <div className="colmn">
                <div className="audio-music-box">
                    <div className={`card ${selectedAudio === url ? 'active-audio' : ''}`}>

                        <div className="active-audio-wrapper cursor-pointer" onClick={()=>removeAudio(url)}>
                            <i className="fa fa-check" aria-hidden="true"></i>
                        </div>

                        <div className="card-header-uploaded">
                            {
                                type === "uploaded" ?
                                    <div className="card-icon-delete" onClick={() => showPopUp()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                            <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
                                        </svg>
                                    </div>
                                    : ''
                            }
                            <h6 className="card-title text-right m-0 ">
                                {audioDuration} s
                            </h6>
                        </div>
                        <div className="card-body mb-3 d-flex justify-content-center cursor-pointer" onClick={() => selectAudio(url)}>
                            <img src={SOUNDWAVE} alt />
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-3 d-flex align-items-center justify-content-center">
                                    <div className="card-play-icon">
                                        {
                                            play && currentUrl === url ? <i className="fas fa-2x fa-pause-circle" onClick={() => pauseAudio(url)} />
                                                : <i className={`${loader ? 'fa fa-spinner fa-spin' : 'fas fa-2x fa-play-circle'} `} onClick={() => playAudio(url)} />
                                        }
                                    </div>
                                </div>
                                <div className="col-9 d-flex align-items-center">
                                    <div className="card-music-name">
                                        <h6 className="m-0">{title}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SweetAlert
                warning
                showCancel
                confirmBtnText={buttonText}
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="light"
                title="Are you sure?"
                onConfirm={() => onConfirm()}
                onCancel={() => setShowDelete(false)}
                focusCancelBtn
                show={showDelete}
            >
                You want to delete this file
            </SweetAlert>

        </>
    )
}

export default AudioFiles;