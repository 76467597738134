import React from "react";

const SocialIcon = (props) => {

    return(
        <>
            <div className={`wrap-icon-image ${props.selectIcon === props.checkName ? 'active-push' : ''}`}>
                <img src={props.icon} className="img-fluid" />
            </div>
            <p>{props?.name}</p>
        </>
    )
}

export default React.memo(SocialIcon);