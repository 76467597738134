import styled from "styled-components";

export const Regular5Style = styled.div`
width: 100% !important;
height: 100% !important;


.t5-containerfive .t5-img-fluid {
  display: block;
  max-width: 100%;
}

.t5-containerfive {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.f-cyan {
  color: #00fffc;
}

.f-white {
  color: #fff;
}

.f-blue {
  color: #005596;
}

.t5-containerfive div {
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
  font-weight: 500;
  margin: 0;
}

.t5-containerfive .t5-text-wrappers div {
  font-size: 1.6vw;
  text-align: left;
}

.t5-containerfive .t5-first-layer {
  height: 100%;
  width: 100%;
  display: flex;
}

.t5-containerfive .t5-first-layer .t5-t-colmn-1 {
  background: #085a93;
  flex: 0 0 30%;
  max-width: 0%;
}

.t5-containerfive .t5-first-layer .t5-t-colmn-2 {
  flex: 0 0 100%;
  max-width: 100%;
  background: #00d1cf;
}

.t5-containerfive .t5-second-layer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.t5-containerfive .t5-second-layer .t5-img-wrapper {
  width: 0;
  height: 66.3%;
  overflow: hidden;
}

.t5-containerfive .t5-second-layer .t5-img-wrapper img {
  object-fit: cover;
  width: 60vw;
  height: 30vh;
  filter: saturate(0);
}

.t5-containerfive .t5-second-layer .t5-t-colmn-1 {
  flex: 0 0 40%;
  max-width: 35%;
}

.t5-containerfive .t5-second-layer .t5-t-colmn-2 {
  flex: 0 0 60%;
  max-width: 50%;
  height: 100% !important;
  position: relative;
}

.t5-containerfive .t5-text-wrappers div:last-child {
  font-size: 1vw;
  margin-top: 2%;
}

.t5-containerfive .t5-text-wrappers {
  overflow: hidden;
  max-height: 68%;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1366.98px) {
  .t5-containerfive .t5-text-wrappers p {
    /*font-size: 1.4vw;*/
  }

  .t5-containerfive .t5-text-wrappers p:last-child {
    /*font-size: 0.8vw;*/
  }
}

@media screen and (max-width: 991.98px) {
  .t5-containerfive .t5-text-wrappers p {
    /*font-size: 2vw;*/
  }

  .t5-containerfive .t5-text-wrappers p:last-child {
    /*font-size: 1.2vw;*/
  }
}

@media screen and (max-width: 991.98px) {
  .t5-containerfive .t5-text-wrappers p {
    /*font-size: 3vw;*/
  }

  .t5-containerfive .t5-text-wrappers p:last-child {
    /*font-size: 1.8vw;*/
  }
}
`;
