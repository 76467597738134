import styled from "styled-components";

export const Regular1Style = styled.div`

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }


  

  .front-layer {
    position: absolute;
    top: 0;
    bottom: 0;
  
    right: 0;
  }
  
  .back-layer {
    position: absolute;
  }

  .back-layer:after {
    content: '';
    width: 50vw;
    height: 100vh;
    background: #f5bfcf;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    max-width: 50vw;
    right: 0;
    padding: 0 2vw;
    height: max-content;
  }

  .text-wrapper p {
    font-size: 3vw;
    flex: 0 0 100%;
  }

  .container_two_one{
    width:100%;
    height:100%;
  }
  .back-layer{
    width: 100%;
    height: 100%;
  }
  
  .front-layer img, .front-layer video{
    width:100%;
  }

  .text-wrappers {
    position:absolute;
    top:0;
    left:50%;
    width:50%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .text-wrappers > div {
    width:100%;
    overflow: hidden;
  }
`;