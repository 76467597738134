import React, { useCallback, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseURL } from "../../global/global";
import HoverVideoPlayer from "react-hover-video-player";
import { updateSlideImage, updateSlideVideo } from "../../actions/videoAction";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";
import Cropper from "react-easy-crop";

const SearchVideo = ({ selectedIndex, dimension }) => {

    let dimArr = dimension.split(':');

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputVideo, setOutputVideo] = useState(false);
    const [outputThumb, setOutputThumb] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);

    /*Crop image or video*/
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const [search, setSearch] = useState({
        keyword: '',
        search_type: 'video',
        page: 1
    });

    const [videData, setVideoData] = useState([]);

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });

        setVideoData([]);
    }

    const searchVideo = (page = 1) => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}search-media`,
            data: JSON.stringify({ keyword: search.keyword, search_type: search.search_type, page: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setError(false);

                let arr = [...videData];
                setVideoData(arr.concat(res.data.data));
            } else {
                setError(res.data.message);
            }

            setSearch({...search, page: page});

            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const handleVideoClick = () => {
        setError(false);

        searchVideo();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);

        searchVideo();
    }

    const selectVideo = (image, index) => {
        setCheckImage(index);

        let jsonData = {
            file_url: image.url,
            poster: image.image,
            index: selectedIndex,
            type: 'video'
        }

        dispatch(updateSlideVideo(jsonData))
    }

    const selectCropImage = (image) => {
        let jsonData = {
            file_url: image,
            poster: outputThumb,
            index: selectedIndex,
            type: 'video'
        }

        dispatch(updateSlideVideo(jsonData))
        setCropModel(false);
        setOutputVideo(false);
    }

    const selectCrop = (url) => {
        setCropModel(true);
        setCurrentImage(url);
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)

    }, [])

    const cropVideoFile = () => {
        setCropLoader(true);

        let formData = new FormData();
        formData.append('width', croppedAreaPixels.width);
        formData.append('height', croppedAreaPixels.height);
        formData.append('videourl', currentImage);
        formData.append('postionx', croppedAreaPixels.x);
        formData.append('postiony', croppedAreaPixels.y);
        formData.append('user_id', auth.user.id);

        axios({
            method: "POST",
            url: `${baseURL}video-crop`,
            data: formData,
            headers: { 'Authorization': auth.token }
        }).then(res => {
            console.log(res);
            if (res.data.status === true) {
                setOutputVideo(res.data.video);
                setOutputThumb(res.data.thumbnail);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setCropLoader(false);
        }).catch(err => {
            console.log(err);
            setCropLoader(false);
        })
    }



    return (
        <>
            <Alert />
            <div className="row mt-2">
                <div className="col-md-12">
                    <form method="post" className="media-search-box" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" onClick={() => handleVideoClick()}>
                                    <i className="fas fa-search" />
                                </span>
                            </div>
                            <input type="text" className="form-control" placeholder="Search" onInput={(e) => onInputChange(e)} />
                        </div>
                    </form>
                </div>
            </div>
            <div className="search-load-box mt-4">
                <div className="row" id="scrollableVideo" style={{ overflowX: "hidden" }}>

                    <InfiniteScroll
                        dataLength={videData.length} //This is important field to render the next data
                        next={() => searchVideo(search.page + 1)}
                        hasMore={true}
                        scrollableTarget="scrollableVideo"
                    >
                        {
                            videData.length > 0 ?
                                videData.map((image, index) => {
                                    return (
                                        <div className="colmn col-6" key={index}>
                                            <div className="search-load d-flex justify-content-center align-items-center cursor-pointer" onClick={() => selectVideo(image, index)}>
                                                <HoverVideoPlayer
                                                    videoSrc={image.url}
                                                    pausedOverlay={
                                                        <img src={image.image} alt={image?.name} width="100%" height="100%" onClick={() => selectVideo(image, index)} style={{ objectFit: "cover" }} />
                                                    }
                                                    loadingOverlay={
                                                        <div className="loader-center"><i style={{ color: "#16d489" }} className="fa fa-spinner fa-spin mr-2" /></div>
                                                    }
                                                />
                                                {
                                                    checkImage === index ?
                                                        <div className="loader-center" style={{ top: "-31px" }}>
                                                            <i className="fa fa-check" aria-hidden="true" style={{ padding: "5px" }}></i>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                            <div className="crop-icon cursor-pointer" style={{ left: "17px", zIndex: "2" }} onClick={() => selectCrop(image.url)}>
                                                <i className="fa fa-crop"></i>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''
                        }
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div>
                                : ''
                        }
                        {
                            error ?
                                <div className="col-md-12">
                                    <h6 className="text-center">{error}</h6>
                                </div>
                                : ''
                        }
                    </InfiniteScroll>

                </div>
            </div>

            <div className={`modal ${cropModel ? 'show-modal' : ''} `} style={{ height: "auto" }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center">Crop</h5>
                            <button onClick={(e) => setCropModel(false)} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ position: "relative", height: "70vh" }}>
                            {/*<ul className="nav d-flex justify-content-center" id="pills-tab" role="tablist">*/}
                            {/*    <li className="nav-item text-center">*/}
                            {/*        <a*/}
                            {/*            className="nav-link active"*/}
                            {/*            id="pills-story-tab"*/}
                            {/*            data-toggle="pill"*/}
                            {/*            href="#pills-crop"*/}
                            {/*            role="tab"*/}
                            {/*            aria-controls="pills-crop"*/}
                            {/*            aria-selected="true"*/}
                            {/*        >*/}
                            {/*            <h6>Crop</h6>*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*    <li className="nav-item text-center">*/}
                            {/*        <a*/}
                            {/*            className="nav-link"*/}
                            {/*            id="pills-story-tab"*/}
                            {/*            data-toggle="pill"*/}
                            {/*            href="#pills-trim"*/}
                            {/*            role="tab"*/}
                            {/*            aria-controls="pills-trim"*/}
                            {/*            aria-selected="true"*/}
                            {/*        >*/}
                            {/*            <h6>Trim</h6>*/}
                            {/*        </a>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                            <div className="tab-content text-white" id="pills-tabContent">
                                <div className="tab-pane editor-panel fade show active" id="pills-crop"
                                    role="tabpanel" aria-labelledby="pills-story-tab">
                                    <Cropper
                                        video={currentImage}
                                        crop={crop}
                                        zoom={zoom}
                                        aspect={dimArr[0] / dimArr[1]}
                                        onCropChange={setCrop}
                                        onZoomChange={setZoom}
                                        onCropComplete={onCropComplete}
                                    />
                                    {
                                        croppedAreaPixels === null ? <div className="crop-icon-center"><i className="fa fa-spinner fa-spin mr-2" /></div> : ''
                                    }
                                </div>
                                <div className="tab-pane editor-panel fade" id="pills-trim"
                                    role="tabpanel" aria-labelledby="pills-story-tab">
                                    <h4>Trim Video</h4>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer d-flex justify-content-between">
                            <button className="btn-custom mr-1" type="button" onClick={() => cropVideoFile()}>
                                {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                {cropLoader ? 'Cropping' : 'Crop'}
                            </button>
                            <button className="btn-custom" type="button"
                                disabled={outputVideo === false ? true : false}
                                onClick={() => selectCropImage(outputVideo)} >Use</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(SearchVideo);