import styled from "styled-components";

export const Square1Style = styled.div`
width:100%;
 height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container10 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .text-wrapper > div {
    width:70%;
    text-align: center;
  }

  .back-layer {
    width:100%;
    height:100%;
  }

  .ellipse {
    background: -moz-linear-gradient(
      top,
      rgba(255, 38, 252, 0.8) 0%,
      rgba(255, 245, 128, 0.8) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 38, 252, 0.8) 0%,
      rgba(255, 245, 128, 0.8) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 38, 252, 0.8) 0%,
      rgba(255, 245, 128, 0.8) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ccff26fc', endColorstr='#ccfff580',GradientType=0 );
    width: 80%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 10%;
    right: 0;
    clip-path: circle();
    transform: scale(0.5);
    opacity: 0;
  }

  .back-layer img,
  .back-layer video {
    width:100%;
 height:100%;
    object-fit: cover;
    object-position: center;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }
`;
