import React from "react";
import { Portait5Style } from "./css/Portrait5Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return <Portait5Style>
       <div className="container7">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="back-layer">
          <div className="ellipse" />
        </div>
        <div className="front-layer">
          <div className="col">
            <div className="vid-wrapper">
           
              <div className="border-wrap">
              <TemplateImage slideType={props.slideType} file={props.file} />
                <div className="border top-right-to-bottom-right" />
                <div className="border bottom-right-to-bottom-left" />
                <div className="border bottom-left-to-top-left" />
                <div className="border top-left-to-top-right" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="text-wrapper">
              <TemplateText
                id={props.id}
                text={props.text}
                textStyle={props.textStyle}
                editStatus={props.editStatus}
                onInputSlideText={props.onInputSlideText}
              />
            </div>
          </div>
        </div>
      </div>
  </Portait5Style>;
};

export default Template;
