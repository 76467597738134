import styled from "styled-components";

export const Square3Style = styled.div`
width:100%;
height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container8{
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-white {
    color: #fff;
  }

  .text-wrapper div {
    width:100%
  }

  .back-layer {
    width:100%;
    height:100%;
    background: #2f7dff;
  }

  .back-layer img,
  .back-layer video {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.5;
  }

  .front-layer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
    transform: scale(1.5);
    background: #2f7dff;
    background: -moz-linear-gradient(left, #2f7dff 0%, #a64cdf 100%);
    background: -webkit-linear-gradient(left, #2f7dff 0%, #a64cdf 100%);
    background: linear-gradient(to right, #2f7dff 0%, #a64cdf 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f7dff', endColorstr='#a64cdf',GradientType=1 );
  }

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .text-wrapper {
    flex: 0 0 60%;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }
`;
