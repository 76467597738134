import styled from "styled-components";

export const Regular1Style = styled.div`
.t1-containersone .img-fluid {
  display: block;
  max-width: 100%;
}

.t1-containersone {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.t1-containersone {
  background: #005596;
}

.t1-containersone .t1-img-wrapper .img-fluid {
  opacity: 0.5;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.f-cyan {
  color: #00fffc;
}

.f-white {
  color: #fff;
}

.t1-containersone div,
.t1-containersone span,
.t1-containersone mark {
  font-family: "Poppins", sans-serif;
  line-height: 1.3;
  font-weight: 500;
  margin: 0;
}

.t1-containersone .t1-text-wrappers div,
.t1-containersone span {
  /*font-size: 2vw;*/
  line-height: 1.2em;
}

.t1-containersone .t1-text-wrappers {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  margin: auto;
  height: max-content;
  right: 5%;
  width: 60%;
  max-height: 55%;
  overflow: hidden;
  hyphens: auto;
}

.t1-text-wrappers div {
  position: relative;
  outline: none !important;
  border: none !important;
}

.t1-containersone .t1-text-wrappers div:not(:last-child),
.t1-containersone span:not(:last-child) {
  margin-bottom: 10px;
}
@media screen and (max-width: 1366.98px) {
  .t1-containersone .t1-text-wrappers div,
  .t1-containersone span {
    /*font-size: 1.6vw;*/
  }
}

@media screen and (max-width: 991.98px) {
  .t1-containersone .t1-text-wrappers div,
  .t1-containersone span {
    /*font-size: 2.4vw;*/
  }
}

@media screen and (max-width: 640.98px) {
  .t1-containersone .t1-text-wrappers div,
  .t1-containersone span {
    /*font-size: 3.4vw;*/
  }
}
`;
