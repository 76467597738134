import {
  regular_1_1,
  regular_1_2,
  regular_1_3,
  regular_1_4,
  regular_1_5,
  regular_1_6,
  regular_1_7,
  regular_1_8,
  square_1_1,
  square_1_2,
  square_1_3,
  square_1_4,
  square_1_5,
  square_1_6,
  square_1_7,
  square_1_8,
  story_1_1,
  story_1_2,
  story_1_3,
  story_1_4,
  story_1_5,
  story_1_6,
  story_1_7,
  story_1_8,
} from "../../../Template/commonGsap/1";
import {
  regular_2_1,
  regular_2_2,
  regular_2_3,
  regular_2_4,
  regular_2_5,
  regular_2_6,
  regular_2_7,
  regular_2_8,
  square_2_1,
  square_2_2,
  square_2_3,
  square_2_4,
  square_2_5,
  square_2_6,
  square_2_7,
  square_2_8,
  story_2_1,
  story_2_2,
  story_2_3,
  story_2_4,
  story_2_5,
  story_2_6,
  story_2_7,
  story_2_8,
} from "../../../Template/commonGsap/2";
import {
  regular_3_1,
  regular_3_2,
  regular_3_3,
  regular_3_4,
  regular_3_5,
  regular_3_6,
  regular_3_7,
  regular_3_8,
  square_3_1,
  square_3_2,
  square_3_3,
  square_3_4,
  square_3_5,
  square_3_6,
  square_3_7,
  square_3_8,
  story_3_1,
  story_3_2,
  story_3_3,
  story_3_4,
  story_3_5,
  story_3_6,
  story_3_7,
  story_3_8,
} from "../../../Template/commonGsap/3";
import {
  regular_4_1,
  regular_4_2,
  regular_4_3,
  regular_4_4,
  regular_4_5,
  regular_4_6,
  regular_4_7,
  regular_4_8,
  square_4_1,
  square_4_2,
  square_4_3,
  square_4_4,
  square_4_5,
  square_4_6,
  square_4_7,
  square_4_8,
  story_4_1,
  story_4_2,
  story_4_3,
  story_4_4,
  story_4_5,
  story_4_6,
  story_4_7,
  story_4_8,
} from "../../../Template/commonGsap/4";
import {
  regular_5_1,
  regular_5_2,
  regular_5_3,
  regular_5_4,
  regular_5_5,
  regular_5_6,
  regular_5_7,
  regular_5_8,
  square_5_1,
  square_5_2,
  square_5_3,
  square_5_4,
  square_5_5,
  square_5_6,
  square_5_7,
  square_5_8,
  story_5_1,
  story_5_2,
  story_5_3,
  story_5_4,
  story_5_5,
  story_5_6,
  story_5_7,
  story_5_8,
} from "../../../Template/commonGsap/5";

import {
  regular_6_1,
  regular_6_2,
  regular_6_3,
  regular_6_4,
  regular_6_5,
  regular_6_6,
  regular_6_7,
  regular_6_8,
  square_6_1,
  square_6_2,
  square_6_3,
  square_6_4,
  square_6_5,
  square_6_6,
  square_6_7,
  square_6_8,
  story_6_1,
  story_6_2,
  story_6_3,
  story_6_4,
  story_6_5,
  story_6_6,
  story_6_7,
  story_6_8,
} from "../../../Template/commonGsap/6";

import {
  regular_7_1,
  regular_7_2,
  regular_7_3,
  regular_7_4,
  regular_7_5,
  regular_7_6,
  regular_7_7,
  regular_7_8,
  square_7_1,
  square_7_2,
  square_7_3,
  square_7_4,
  square_7_5,
  square_7_6,
  square_7_7,
  square_7_8,
  story_7_1,
  story_7_2,
  story_7_3,
  story_7_4,
  story_7_5,
  story_7_6,
  story_7_7,
  story_7_8,
} from "../../../Template/commonGsap/7";

import {
  regular_8_1,
  regular_8_2,
  regular_8_3,
  regular_8_4,
  regular_8_5,
  regular_8_6,
  regular_8_7,
  regular_8_8,
  square_8_1,
  square_8_2,
  square_8_3,
  square_8_4,
  square_8_5,
  square_8_6,
  square_8_7,
  square_8_8,
  story_8_1,
  story_8_2,
  story_8_3,
  story_8_4,
  story_8_5,
  story_8_6,
  story_8_7,
  story_8_8,
} from "../../../Template/commonGsap/8";

import {
  regular_9_1,
  regular_9_2,
  regular_9_3,
  regular_9_4,
  regular_9_5,
  regular_9_6,
  regular_9_7,
  regular_9_8,
  square_9_1,
  square_9_2,
  square_9_3,
  square_9_4,
  square_9_5,
  square_9_6,
  square_9_7,
  square_9_8,
  story_9_1,
  story_9_2,
  story_9_3,
  story_9_4,
  story_9_5,
  story_9_6,
  story_9_7,
  story_9_8,
} from "../../../Template/commonGsap/9";

import {
  regular_10_1,
  regular_10_2,
  regular_10_3,
  regular_10_4,
  regular_10_5,
  regular_10_6,
  regular_10_7,
  regular_10_8,
  square_10_1,
  square_10_2,
  square_10_3,
  square_10_4,
  square_10_5,
  square_10_6,
  square_10_7,
  square_10_8,
  story_10_1,
  story_10_2,
  story_10_3,
  story_10_4,
  story_10_5,
  story_10_6,
  story_10_7,
  story_10_8,
} from "../../../Template/commonGsap/10";

import {
  regular_11_1,
  regular_11_2,
  regular_11_3,
  regular_11_4,
  regular_11_5,
  regular_11_6,
  regular_11_7,
  regular_11_8,
  square_11_1,
  square_11_2,
  square_11_3,
  square_11_4,
  square_11_5,
  square_11_6,
  square_11_7,
  square_11_8,
  story_11_1,
  story_11_2,
  story_11_3,
  story_11_4,
  story_11_5,
  story_11_6,
  story_11_7,
  story_11_8,
} from "../../../Template/commonGsap/11";

import {
  regular_12_1,
  regular_12_2,
  regular_12_3,
  regular_12_4,
  regular_12_5,
  regular_12_6,
  regular_12_7,
  regular_12_8,
  square_12_1,
  square_12_2,
  square_12_3,
  square_12_4,
  square_12_5,
  square_12_6,
  square_12_7,
  square_12_8,
  story_12_1,
  story_12_2,
  story_12_3,
  story_12_4,
  story_12_5,
  story_12_6,
  story_12_7,
  story_12_8,
} from "../../../Template/commonGsap/12";

export const common_export = (aspect, template, variant, pauseTTS) => {
  if (aspect === "regular" && template === "1" && variant === "1")
    return regular_1_1(pauseTTS);
  if (aspect === "regular" && template === "1" && variant === "2")
    return regular_1_2(pauseTTS);
  if (aspect === "regular" && template === "1" && variant === "3")
    return regular_1_3(pauseTTS);
  if (aspect === "regular" && template === "1" && variant === "4")
    return regular_1_4(pauseTTS);
  if (aspect === "regular" && template === "1" && variant === "5")
    return regular_1_5(pauseTTS);
  if (aspect === "regular" && template === "1" && variant === "6")
    return regular_1_6(pauseTTS);
  if (aspect === "regular" && template === "1" && variant === "7")
    return regular_1_7(pauseTTS);
  if (aspect === "regular" && template === "1" && variant === "8")
    return regular_1_8(pauseTTS);

  if (aspect === "square" && template === "1" && variant === "1")
    return square_1_1(pauseTTS);
  if (aspect === "square" && template === "1" && variant === "2")
    return square_1_2(pauseTTS);
  if (aspect === "square" && template === "1" && variant === "3")
    return square_1_3(pauseTTS);
  if (aspect === "square" && template === "1" && variant === "4")
    return square_1_4(pauseTTS);
  if (aspect === "square" && template === "1" && variant === "5")
    return square_1_5(pauseTTS);
  if (aspect === "square" && template === "1" && variant === "6")
    return square_1_6(pauseTTS);
  if (aspect === "square" && template === "1" && variant === "7")
    return square_1_7(pauseTTS);
  if (aspect === "square" && template === "1" && variant === "8")
    return square_1_8(pauseTTS);

  if (aspect === "story" && template === "1" && variant === "1")
    return story_1_1(pauseTTS);
  if (aspect === "story" && template === "1" && variant === "2")
    return story_1_2(pauseTTS);
  if (aspect === "story" && template === "1" && variant === "3")
    return story_1_3(pauseTTS);
  if (aspect === "story" && template === "1" && variant === "4")
    return story_1_4(pauseTTS);
  if (aspect === "story" && template === "1" && variant === "5")
    return story_1_5(pauseTTS);
  if (aspect === "story" && template === "1" && variant === "6")
    return story_1_6(pauseTTS);
  if (aspect === "story" && template === "1" && variant === "7")
    return story_1_7(pauseTTS);
  if (aspect === "story" && template === "1" && variant === "8")
    return story_1_8(pauseTTS);

  // Template 2
  if (aspect === "regular" && template === "2" && variant === "1")
    return regular_2_1(pauseTTS);
  if (aspect === "regular" && template === "2" && variant === "2")
    return regular_2_2(pauseTTS);
  if (aspect === "regular" && template === "2" && variant === "3")
    return regular_2_3(pauseTTS);
  if (aspect === "regular" && template === "2" && variant === "4")
    return regular_2_4(pauseTTS);
  if (aspect === "regular" && template === "2" && variant === "5")
    return regular_2_5(pauseTTS);
  if (aspect === "regular" && template === "2" && variant === "6")
    return regular_2_6(pauseTTS);
  if (aspect === "regular" && template === "2" && variant === "7")
    return regular_2_7(pauseTTS);
  if (aspect === "regular" && template === "2" && variant === "8")
    return regular_2_8(pauseTTS);

  if (aspect === "square" && template === "2" && variant === "1")
    return square_2_1(pauseTTS);
  if (aspect === "square" && template === "2" && variant === "2")
    return square_2_2(pauseTTS);
  if (aspect === "square" && template === "2" && variant === "3")
    return square_2_3(pauseTTS);
  if (aspect === "square" && template === "2" && variant === "4")
    return square_2_4(pauseTTS);
  if (aspect === "square" && template === "2" && variant === "5")
    return square_2_5(pauseTTS);
  if (aspect === "square" && template === "2" && variant === "6")
    return square_2_6(pauseTTS);
  if (aspect === "square" && template === "2" && variant === "7")
    return square_2_7(pauseTTS);
  if (aspect === "square" && template === "2" && variant === "8")
    return square_2_8(pauseTTS);

  if (aspect === "story" && template === "2" && variant === "1")
    return story_2_1(pauseTTS);
  if (aspect === "story" && template === "2" && variant === "2")
    return story_2_2(pauseTTS);
  if (aspect === "story" && template === "2" && variant === "3")
    return story_2_3(pauseTTS);
  if (aspect === "story" && template === "2" && variant === "4")
    return story_2_4(pauseTTS);
  if (aspect === "story" && template === "2" && variant === "5")
    return story_2_5(pauseTTS);
  if (aspect === "story" && template === "2" && variant === "6")
    return story_2_6(pauseTTS);
  if (aspect === "story" && template === "2" && variant === "7")
    return story_2_7(pauseTTS);
  if (aspect === "story" && template === "2" && variant === "8")
    return story_2_8(pauseTTS);

  // Templates 3
  if (aspect === "regular" && template === "3" && variant === "1")
    return regular_3_1(pauseTTS);
  if (aspect === "regular" && template === "3" && variant === "3")
    return regular_3_3(pauseTTS);
  if (aspect === "regular" && template === "3" && variant === "2")
    return regular_3_2(pauseTTS);
  if (aspect === "regular" && template === "3" && variant === "4")
    return regular_3_4(pauseTTS);
  if (aspect === "regular" && template === "3" && variant === "5")
    return regular_3_5(pauseTTS);
  if (aspect === "regular" && template === "3" && variant === "6")
    return regular_3_6(pauseTTS);
  if (aspect === "regular" && template === "3" && variant === "7")
    return regular_3_7(pauseTTS);
  if (aspect === "regular" && template === "3" && variant === "8")
    return regular_3_8(pauseTTS);

  if (aspect === "square" && template === "3" && variant === "1")
    return square_3_1(pauseTTS);
  if (aspect === "square" && template === "3" && variant === "2")
    return square_3_2(pauseTTS);
  if (aspect === "square" && template === "3" && variant === "3")
    return square_3_3(pauseTTS);
  if (aspect === "square" && template === "3" && variant === "4")
    return square_3_4(pauseTTS);
  if (aspect === "square" && template === "3" && variant === "5")
    return square_3_5(pauseTTS);
  if (aspect === "square" && template === "3" && variant === "6")
    return square_3_6(pauseTTS);
  if (aspect === "square" && template === "3" && variant === "7")
    return square_3_7(pauseTTS);
  if (aspect === "square" && template === "3" && variant === "8")
    return square_3_8(pauseTTS);

  if (aspect === "story" && template === "3" && variant === "1")
    return story_3_1(pauseTTS);
  if (aspect === "story" && template === "3" && variant === "2")
    return story_3_2(pauseTTS);
  if (aspect === "story" && template === "3" && variant === "3")
    return story_3_3(pauseTTS);
  if (aspect === "story" && template === "3" && variant === "4")
    return story_3_4(pauseTTS);
  if (aspect === "story" && template === "3" && variant === "5")
    return story_3_5(pauseTTS);
  if (aspect === "story" && template === "3" && variant === "6")
    return story_3_6(pauseTTS);
  if (aspect === "story" && template === "3" && variant === "7")
    return story_3_7(pauseTTS);
  if (aspect === "story" && template === "3" && variant === "8")
    return story_3_8(pauseTTS);

  // template 4
  if (aspect === "regular" && template === "4" && variant === "1")
    return regular_4_1(pauseTTS);
  if (aspect === "regular" && template === "4" && variant === "4")
    return regular_4_4(pauseTTS);
  if (aspect === "regular" && template === "4" && variant === "2")
    return regular_4_2(pauseTTS);
  if (aspect === "regular" && template === "4" && variant === "3")
    return regular_4_3(pauseTTS);
  if (aspect === "regular" && template === "4" && variant === "5")
    return regular_4_5(pauseTTS);
  if (aspect === "regular" && template === "4" && variant === "6")
    return regular_4_6(pauseTTS);
  if (aspect === "regular" && template === "4" && variant === "7")
    return regular_4_7(pauseTTS);
  if (aspect === "regular" && template === "4" && variant === "8")
    return regular_4_8(pauseTTS);

  if (aspect === "square" && template === "4" && variant === "1")
    return square_4_1(pauseTTS);
  if (aspect === "square" && template === "4" && variant === "2")
    return square_4_2(pauseTTS);
  if (aspect === "square" && template === "4" && variant === "3")
    return square_4_3(pauseTTS);
  if (aspect === "square" && template === "4" && variant === "4")
    return square_4_4(pauseTTS);
  if (aspect === "square" && template === "4" && variant === "5")
    return square_4_5(pauseTTS);
  if (aspect === "square" && template === "4" && variant === "6")
    return square_4_6(pauseTTS);
  if (aspect === "square" && template === "4" && variant === "7")
    return square_4_7(pauseTTS);
  if (aspect === "square" && template === "4" && variant === "8")
    return square_4_8(pauseTTS);

  if (aspect === "story" && template === "4" && variant === "1")
    return story_4_1(pauseTTS);
  if (aspect === "story" && template === "4" && variant === "2")
    return story_4_2(pauseTTS);
  if (aspect === "story" && template === "4" && variant === "3")
    return story_4_3(pauseTTS);
  if (aspect === "story" && template === "4" && variant === "4")
    return story_4_4(pauseTTS);
  if (aspect === "story" && template === "4" && variant === "5")
    return story_4_5(pauseTTS);
  if (aspect === "story" && template === "4" && variant === "6")
    return story_4_6(pauseTTS);
  if (aspect === "story" && template === "4" && variant === "7")
    return story_4_7(pauseTTS);
  if (aspect === "story" && template === "4" && variant === "8")
    return story_4_8(pauseTTS);

  // template 5

  if (aspect === "regular" && template === "5" && variant === "1")
    return regular_5_1(pauseTTS);
  if (aspect === "regular" && template === "5" && variant === "2")
    return regular_5_2(pauseTTS);
  if (aspect === "regular" && template === "5" && variant === "3")
    return regular_5_3(pauseTTS);
  if (aspect === "regular" && template === "5" && variant === "4")
    return regular_5_4(pauseTTS);
  if (aspect === "regular" && template === "5" && variant === "5")
    return regular_5_5(pauseTTS);
  if (aspect === "regular" && template === "5" && variant === "6")
    return regular_5_6(pauseTTS);
  if (aspect === "regular" && template === "5" && variant === "7")
    return regular_5_7(pauseTTS);
  if (aspect === "regular" && template === "5" && variant === "8")
    return regular_5_8(pauseTTS);

  if (aspect === "square" && template === "5" && variant === "1")
    return square_5_1(pauseTTS);
  if (aspect === "square" && template === "5" && variant === "2")
    return square_5_2(pauseTTS);
  if (aspect === "square" && template === "5" && variant === "3")
    return square_5_3(pauseTTS);
  if (aspect === "square" && template === "5" && variant === "4")
    return square_5_4(pauseTTS);
  if (aspect === "square" && template === "5" && variant === "5")
    return square_5_5(pauseTTS);
  if (aspect === "square" && template === "5" && variant === "6")
    return square_5_6(pauseTTS);
  if (aspect === "square" && template === "5" && variant === "7")
    return square_5_7(pauseTTS);
  if (aspect === "square" && template === "5" && variant === "8")
    return square_5_8(pauseTTS);

  if (aspect === "story" && template === "5" && variant === "1")
    return story_5_1(pauseTTS);
  if (aspect === "story" && template === "5" && variant === "2")
    return story_5_2(pauseTTS);
  if (aspect === "story" && template === "5" && variant === "3")
    return story_5_3(pauseTTS);
  if (aspect === "story" && template === "5" && variant === "4")
    return story_5_4(pauseTTS);
  if (aspect === "story" && template === "5" && variant === "5")
    return story_5_5(pauseTTS);
  if (aspect === "story" && template === "5" && variant === "6")
    return story_5_6(pauseTTS);
  if (aspect === "story" && template === "5" && variant === "7")
    return story_5_7(pauseTTS);
  if (aspect === "story" && template === "5" && variant === "8")
    return story_5_8(pauseTTS);

  // template 6

  if (aspect === "regular" && template === "6" && variant === "1")
    return regular_6_1(pauseTTS);
  if (aspect === "regular" && template === "6" && variant === "2")
    return regular_6_2(pauseTTS);
  if (aspect === "regular" && template === "6" && variant === "3")
    return regular_6_3(pauseTTS);
  if (aspect === "regular" && template === "6" && variant === "4")
    return regular_6_4(pauseTTS);
  if (aspect === "regular" && template === "6" && variant === "5")
    return regular_6_5(pauseTTS);
  if (aspect === "regular" && template === "6" && variant === "6")
    return regular_6_6(pauseTTS);
  if (aspect === "regular" && template === "6" && variant === "7")
    return regular_6_7(pauseTTS);
  if (aspect === "regular" && template === "6" && variant === "8")
    return regular_6_8(pauseTTS);

  if (aspect === "square" && template === "6" && variant === "1")
    return square_6_1(pauseTTS);
  if (aspect === "square" && template === "6" && variant === "2")
    return square_6_2(pauseTTS);
  if (aspect === "square" && template === "6" && variant === "3")
    return square_6_3(pauseTTS);
  if (aspect === "square" && template === "6" && variant === "4")
    return square_6_4(pauseTTS);
  if (aspect === "square" && template === "6" && variant === "5")
    return square_6_5(pauseTTS);
  if (aspect === "square" && template === "6" && variant === "6")
    return square_6_6(pauseTTS);
  if (aspect === "square" && template === "6" && variant === "7")
    return square_6_7(pauseTTS);
  if (aspect === "square" && template === "6" && variant === "8")
    return square_6_8(pauseTTS);

  if (aspect === "story" && template === "6" && variant === "1")
    return story_6_1(pauseTTS);
  if (aspect === "story" && template === "6" && variant === "2")
    return story_6_2(pauseTTS);
  if (aspect === "story" && template === "6" && variant === "3")
    return story_6_3(pauseTTS);
  if (aspect === "story" && template === "6" && variant === "4")
    return story_6_4(pauseTTS);
  if (aspect === "story" && template === "6" && variant === "5")
    return story_6_5(pauseTTS);
  if (aspect === "story" && template === "6" && variant === "6")
    return story_6_6(pauseTTS);
  if (aspect === "story" && template === "6" && variant === "7")
    return story_6_7(pauseTTS);
  if (aspect === "story" && template === "6" && variant === "8")
    return story_6_8(pauseTTS);

  // template 7

  if (aspect === "regular" && template === "7" && variant === "1")
    return regular_7_1(pauseTTS);
  if (aspect === "regular" && template === "7" && variant === "2")
    return regular_7_2(pauseTTS);
  if (aspect === "regular" && template === "7" && variant === "3")
    return regular_7_3(pauseTTS);
  if (aspect === "regular" && template === "7" && variant === "4")
    return regular_7_4(pauseTTS);
  if (aspect === "regular" && template === "7" && variant === "5")
    return regular_7_5(pauseTTS);
  if (aspect === "regular" && template === "7" && variant === "6")
    return regular_7_6(pauseTTS);
  if (aspect === "regular" && template === "7" && variant === "7")
    return regular_7_7(pauseTTS);
  if (aspect === "regular" && template === "7" && variant === "8")
    return regular_7_8(pauseTTS);

  if (aspect === "square" && template === "7" && variant === "1")
    return square_7_1(pauseTTS);
  if (aspect === "square" && template === "7" && variant === "2")
    return square_7_2(pauseTTS);
  if (aspect === "square" && template === "7" && variant === "3")
    return square_7_3(pauseTTS);
  if (aspect === "square" && template === "7" && variant === "4")
    return square_7_4(pauseTTS);
  if (aspect === "square" && template === "7" && variant === "5")
    return square_7_5(pauseTTS);
  if (aspect === "square" && template === "7" && variant === "6")
    return square_7_6(pauseTTS);
  if (aspect === "square" && template === "7" && variant === "7")
    return square_7_7(pauseTTS);
  if (aspect === "square" && template === "7" && variant === "8")
    return square_7_8(pauseTTS);

  if (aspect === "story" && template === "7" && variant === "1")
    return story_7_1(pauseTTS);
  if (aspect === "story" && template === "7" && variant === "2")
    return story_7_2(pauseTTS);
  if (aspect === "story" && template === "7" && variant === "3")
    return story_7_3(pauseTTS);
  if (aspect === "story" && template === "7" && variant === "4")
    return story_7_4(pauseTTS);
  if (aspect === "story" && template === "7" && variant === "5")
    return story_7_5(pauseTTS);
  if (aspect === "story" && template === "7" && variant === "6")
    return story_7_6(pauseTTS);
  if (aspect === "story" && template === "7" && variant === "7")
    return story_7_7(pauseTTS);
  if (aspect === "story" && template === "7" && variant === "8")
    return story_7_8(pauseTTS);

  // template 8

  if (aspect === "regular" && template === "8" && variant === "1")
    return regular_8_1(pauseTTS);
  if (aspect === "regular" && template === "8" && variant === "2")
    return regular_8_2(pauseTTS);
  if (aspect === "regular" && template === "8" && variant === "3")
    return regular_8_3(pauseTTS);
  if (aspect === "regular" && template === "8" && variant === "4")
    return regular_8_4(pauseTTS);
  if (aspect === "regular" && template === "8" && variant === "5")
    return regular_8_5(pauseTTS);
  if (aspect === "regular" && template === "8" && variant === "6")
    return regular_8_6(pauseTTS);
  if (aspect === "regular" && template === "8" && variant === "7")
    return regular_8_7(pauseTTS);
  if (aspect === "regular" && template === "8" && variant === "8")
    return regular_8_8(pauseTTS);

  if (aspect === "square" && template === "8" && variant === "1")
    return square_8_1(pauseTTS);
  if (aspect === "square" && template === "8" && variant === "2")
    return square_8_2(pauseTTS);
  if (aspect === "square" && template === "8" && variant === "3")
    return square_8_3(pauseTTS);
  if (aspect === "square" && template === "8" && variant === "4")
    return square_8_4(pauseTTS);
  if (aspect === "square" && template === "8" && variant === "5")
    return square_8_5(pauseTTS);
  if (aspect === "square" && template === "8" && variant === "6")
    return square_8_6(pauseTTS);
  if (aspect === "square" && template === "8" && variant === "7")
    return square_8_7(pauseTTS);
  if (aspect === "square" && template === "8" && variant === "8")
    return square_8_8(pauseTTS);

  if (aspect === "story" && template === "8" && variant === "1")
    return story_8_1(pauseTTS);
  if (aspect === "story" && template === "8" && variant === "2")
    return story_8_2(pauseTTS);
  if (aspect === "story" && template === "8" && variant === "3")
    return story_8_3(pauseTTS);
  if (aspect === "story" && template === "8" && variant === "4")
    return story_8_4(pauseTTS);
  if (aspect === "story" && template === "8" && variant === "5")
    return story_8_5(pauseTTS);
  if (aspect === "story" && template === "8" && variant === "6")
    return story_8_6(pauseTTS);
  if (aspect === "story" && template === "8" && variant === "7")
    return story_8_7(pauseTTS);
  if (aspect === "story" && template === "8" && variant === "8")
    return story_8_8(pauseTTS);

  // template 9

  if (aspect === "regular" && template === "9" && variant === "1")
    return regular_9_1(pauseTTS);
  if (aspect === "regular" && template === "9" && variant === "2")
    return regular_9_2(pauseTTS);
  if (aspect === "regular" && template === "9" && variant === "3")
    return regular_9_3(pauseTTS);
  if (aspect === "regular" && template === "9" && variant === "4")
    return regular_9_4(pauseTTS);
  if (aspect === "regular" && template === "9" && variant === "5")
    return regular_9_5(pauseTTS);
  if (aspect === "regular" && template === "9" && variant === "6")
    return regular_9_6(pauseTTS);
  if (aspect === "regular" && template === "9" && variant === "7")
    return regular_9_7(pauseTTS);
  if (aspect === "regular" && template === "9" && variant === "8")
    return regular_9_8(pauseTTS);

  if (aspect === "square" && template === "9" && variant === "1")
    return square_9_1(pauseTTS);
  if (aspect === "square" && template === "9" && variant === "2")
    return square_9_2(pauseTTS);
  if (aspect === "square" && template === "9" && variant === "3")
    return square_9_3(pauseTTS);
  if (aspect === "square" && template === "9" && variant === "4")
    return square_9_4(pauseTTS);
  if (aspect === "square" && template === "9" && variant === "5")
    return square_9_5(pauseTTS);
  if (aspect === "square" && template === "9" && variant === "6")
    return square_9_6(pauseTTS);
  if (aspect === "square" && template === "9" && variant === "7")
    return square_9_7(pauseTTS);
  if (aspect === "square" && template === "9" && variant === "8")
    return square_9_8(pauseTTS);

  if (aspect === "story" && template === "9" && variant === "1")
    return story_9_1(pauseTTS);
  if (aspect === "story" && template === "9" && variant === "2")
    return story_9_2(pauseTTS);
  if (aspect === "story" && template === "9" && variant === "3")
    return story_9_3(pauseTTS);
  if (aspect === "story" && template === "9" && variant === "4")
    return story_9_4(pauseTTS);
  if (aspect === "story" && template === "9" && variant === "5")
    return story_9_5(pauseTTS);
  if (aspect === "story" && template === "9" && variant === "6")
    return story_9_6(pauseTTS);
  if (aspect === "story" && template === "9" && variant === "7")
    return story_9_7(pauseTTS);
  if (aspect === "story" && template === "9" && variant === "8")
    return story_9_8(pauseTTS);

  // template 10

  if (aspect === "regular" && template === "10" && variant === "1")
    return regular_10_1(pauseTTS);
  if (aspect === "regular" && template === "10" && variant === "2")
    return regular_10_2(pauseTTS);
  if (aspect === "regular" && template === "10" && variant === "3")
    return regular_10_3(pauseTTS);
  if (aspect === "regular" && template === "10" && variant === "4")
    return regular_10_4(pauseTTS);
  if (aspect === "regular" && template === "10" && variant === "5")
    return regular_10_5(pauseTTS);
  if (aspect === "regular" && template === "10" && variant === "6")
    return regular_10_6(pauseTTS);
  if (aspect === "regular" && template === "10" && variant === "7")
    return regular_10_7(pauseTTS);
  if (aspect === "regular" && template === "10" && variant === "8")
    return regular_10_8(pauseTTS);

  if (aspect === "square" && template === "10" && variant === "1")
    return square_10_1(pauseTTS);
  if (aspect === "square" && template === "10" && variant === "2")
    return square_10_2(pauseTTS);
  if (aspect === "square" && template === "10" && variant === "3")
    return square_10_3(pauseTTS);
  if (aspect === "square" && template === "10" && variant === "4")
    return square_10_4(pauseTTS);
  if (aspect === "square" && template === "10" && variant === "5")
    return square_10_5(pauseTTS);
  if (aspect === "square" && template === "10" && variant === "6")
    return square_10_6(pauseTTS);
  if (aspect === "square" && template === "10" && variant === "7")
    return square_10_7(pauseTTS);
  if (aspect === "square" && template === "10" && variant === "8")
    return square_10_8(pauseTTS);

  if (aspect === "story" && template === "10" && variant === "1")
    return story_10_1(pauseTTS);
  if (aspect === "story" && template === "10" && variant === "2")
    return story_10_2(pauseTTS);
  if (aspect === "story" && template === "10" && variant === "3")
    return story_10_3(pauseTTS);
  if (aspect === "story" && template === "10" && variant === "4")
    return story_10_4(pauseTTS);
  if (aspect === "story" && template === "10" && variant === "5")
    return story_10_5(pauseTTS);
  if (aspect === "story" && template === "10" && variant === "6")
    return story_10_6(pauseTTS);
  if (aspect === "story" && template === "10" && variant === "7")
    return story_10_7(pauseTTS);
  if (aspect === "story" && template === "10" && variant === "8")
    return story_10_8(pauseTTS);

  // template 11

  if (aspect === "regular" && template === "11" && variant === "1")
    return regular_11_1(pauseTTS);
  if (aspect === "regular" && template === "11" && variant === "2")
    return regular_11_2(pauseTTS);
  if (aspect === "regular" && template === "11" && variant === "3")
    return regular_11_3(pauseTTS);
  if (aspect === "regular" && template === "11" && variant === "4")
    return regular_11_4(pauseTTS);
  if (aspect === "regular" && template === "11" && variant === "5")
    return regular_11_5(pauseTTS);
  if (aspect === "regular" && template === "11" && variant === "6")
    return regular_11_6(pauseTTS);
  if (aspect === "regular" && template === "11" && variant === "7")
    return regular_11_7(pauseTTS);
  if (aspect === "regular" && template === "11" && variant === "8")
    return regular_11_8(pauseTTS);

  if (aspect === "square" && template === "11" && variant === "1")
    return square_11_1(pauseTTS);
  if (aspect === "square" && template === "11" && variant === "2")
    return square_11_2(pauseTTS);
  if (aspect === "square" && template === "11" && variant === "3")
    return square_11_3(pauseTTS);
  if (aspect === "square" && template === "11" && variant === "4")
    return square_11_4(pauseTTS);
  if (aspect === "square" && template === "11" && variant === "5")
    return square_11_5(pauseTTS);
  if (aspect === "square" && template === "11" && variant === "6")
    return square_11_6(pauseTTS);
  if (aspect === "square" && template === "11" && variant === "7")
    return square_11_7(pauseTTS);
  if (aspect === "square" && template === "11" && variant === "8")
    return square_11_8(pauseTTS);

  if (aspect === "story" && template === "11" && variant === "1")
    return story_11_1(pauseTTS);
  if (aspect === "story" && template === "11" && variant === "2")
    return story_11_2(pauseTTS);
  if (aspect === "story" && template === "11" && variant === "3")
    return story_11_3(pauseTTS);
  if (aspect === "story" && template === "11" && variant === "4")
    return story_11_4(pauseTTS);
  if (aspect === "story" && template === "11" && variant === "5")
    return story_11_5(pauseTTS);
  if (aspect === "story" && template === "11" && variant === "6")
    return story_11_6(pauseTTS);
  if (aspect === "story" && template === "11" && variant === "7")
    return story_11_7(pauseTTS);
  if (aspect === "story" && template === "11" && variant === "8")
    return story_11_8(pauseTTS);

  // template 12

  if (aspect === "regular" && template === "12" && variant === "1")
    return regular_12_1(pauseTTS);
  if (aspect === "regular" && template === "12" && variant === "2")
    return regular_12_2(pauseTTS);
  if (aspect === "regular" && template === "12" && variant === "3")
    return regular_12_3(pauseTTS);
  if (aspect === "regular" && template === "12" && variant === "4")
    return regular_12_4(pauseTTS);
  if (aspect === "regular" && template === "12" && variant === "5")
    return regular_12_5(pauseTTS);
  if (aspect === "regular" && template === "12" && variant === "6")
    return regular_12_6(pauseTTS);
  if (aspect === "regular" && template === "12" && variant === "7")
    return regular_12_7(pauseTTS);
  if (aspect === "regular" && template === "12" && variant === "8")
    return regular_12_8(pauseTTS);

  if (aspect === "square" && template === "12" && variant === "1")
    return square_12_1(pauseTTS);
  if (aspect === "square" && template === "12" && variant === "2")
    return square_12_2(pauseTTS);
  if (aspect === "square" && template === "12" && variant === "3")
    return square_12_3(pauseTTS);
  if (aspect === "square" && template === "12" && variant === "4")
    return square_12_4(pauseTTS);
  if (aspect === "square" && template === "12" && variant === "5")
    return square_12_5(pauseTTS);
  if (aspect === "square" && template === "12" && variant === "6")
    return square_12_6(pauseTTS);
  if (aspect === "square" && template === "12" && variant === "7")
    return square_12_7(pauseTTS);
  if (aspect === "square" && template === "12" && variant === "8")
    return square_12_8(pauseTTS);

  if (aspect === "story" && template === "12" && variant === "1")
    return story_12_1(pauseTTS);
  if (aspect === "story" && template === "12" && variant === "2")
    return story_12_2(pauseTTS);
  if (aspect === "story" && template === "12" && variant === "3")
    return story_12_3(pauseTTS);
  if (aspect === "story" && template === "12" && variant === "4")
    return story_12_4(pauseTTS);
  if (aspect === "story" && template === "12" && variant === "5")
    return story_12_5(pauseTTS);
  if (aspect === "story" && template === "12" && variant === "6")
    return story_12_6(pauseTTS);
  if (aspect === "story" && template === "12" && variant === "7")
    return story_12_7(pauseTTS);
  if (aspect === "story" && template === "12" && variant === "8")
    return story_12_8(pauseTTS);
};
