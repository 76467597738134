import React from "react";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";
import { Square1Style } from "./css/Square1Style";
import pink from "./images/pink.png";
const square_1_1 = (props) => {
  return (
    <Square1Style>
      <div className="template-wrapper">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="container_two_one">
          <div className="back-layer" />
          <TemplateImage slideType={props.slideType} file={props.file} />
          <div className="front-layer">
            <img src={pink} alt="pink" className="img-fluid" />

            <div className="text-wrappers">
              <TemplateText
                id={props.id}
                text={props.text}
                textStyle={props.textStyle}
                editStatus={props.editStatus}
                onInputSlideText={props.onInputSlideText}
              />
            </div>
          </div>
        </div>
      </div>
    </Square1Style>
  );
};

export default square_1_1;
