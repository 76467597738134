import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import queryString from "query-string";

const TrainingBreadcrumbs = ({location, name}) => {
    const supportData = useSelector(state => state.support);
    const articleId = queryString.parse(location.search).id;

    const [articles, setArticles] = useState(supportData.articles);
    const currentArt = articles.filter((article)=> article.id === articleId);

    return(
        <>
            <div className="col-md-12 mb-4">
                <h6 className="mt-5 text-white">
                    <Link to="/help-and-support">Support </Link>
                    /
                    <Link className={`${name ? '' : 'active-help'}`} to={`training?id=${currentArt[0].id}`}> {currentArt[0].name} </Link>
                    {
                        name ? <> / <span className="active-help">{name.title}</span></> : ''
                    }
                </h6>
            </div>
        </>
    )
}

export default TrainingBreadcrumbs;