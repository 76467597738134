import React, { useCallback, useState } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { updateSlideImage } from "../../actions/videoAction";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import Alert from "../Alert";
import { setAlert } from "../../actions/alert";

const SearchImage = ({ selectedIndex, dimension }) => {

    let dimArr = dimension.split(':');

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [checkImage, setCheckImage] = useState(false);
    const [cropModel, setCropModel] = useState(false);
    const [currentImage, setCurrentImage] = useState(false);
    const [outputImage, setOutputImage] = useState(false);
    const [cropLoader, setCropLoader] = useState(false);

    /*Crop image or video*/
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)

    const [search, setSearch] = useState({
        keyword: '',
        search_type: 'image',
        page: 1
    });

    const [imageData, setImageData] = useState([]);

    const onInputChange = (e) => {
        setSearch({ ...search, keyword: e.target.value });

        setImageData([]);
    }

    const closePopup = () => {
        setCropModel(false);
        setOutputImage(false);
    }

    const searchImage = (page = 1) => {
        setLoader(true);

        axios({
            method: "POST",
            url: `${baseURL}search-media`,
            data: JSON.stringify({ keyword: search.keyword, search_type: search.search_type, page: page }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setError(false);

                let arr = [...imageData];
                setImageData(arr.concat(res.data.data));
            } else {
                setError(res.data.message);
            }

            setSearch({...search, page: page});

            setLoader(false);
        }).catch(err => {
            setLoader(false);
        })
    }

    const handleClick = () => {
        setError(false);

        searchImage();
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setError(false);

        searchImage();
    }

    const selectImage = (image, index) => {

        setCheckImage(index);

        let jsonData = {
            file_url: image.image,
            poster: image.thumb,
            index: selectedIndex

        }

        dispatch(updateSlideImage(jsonData))
    }

    const selectCrop = (url) => {
        setCropModel(true);
        setCurrentImage(url);
    }

    const selectCropImage = (image) => {
        let jsonData = {
            file_url: image,
            poster: image,
            index: selectedIndex
        }
        console.log(jsonData);

        dispatch(updateSlideImage(jsonData))
        setCropModel(false);
        setOutputImage(false);
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        setCropLoader(true);
        try {
            const croppedImage = await getCroppedImg(
                currentImage,
                croppedAreaPixels,
                rotation
            )
            downloadFiles(croppedImage);
            setCroppedImage(croppedImage)
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const downloadFiles = (imageData) => {
        let ext = currentImage.match(/\.([^\./\?]+)($|\?)/)[1];

        setCropLoader(true);
        axios({
            method: "POST",
            url: `${baseURL}generate-file`,
            data: JSON.stringify({ data: imageData, format: ext }),
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                setOutputImage(res.data.data);
            } else {
                dispatch(setAlert(res.data.message, 'danger'));
            }
            setCropLoader(false);
        }).catch(err => {
            console.log(err);
            setCropLoader(false);
        })
    }


    return (
        <>
            <Alert />
            <div className="row mt-2">
                <div className="col-md-12">
                    <form method="post" className="media-search-box" onSubmit={(e) => onFormSubmit(e)}>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text" onClick={() => handleClick()}>
                                    <i className="fas fa-search" />
                                </span>
                            </div>
                            <input type="text" className="form-control" placeholder="Search" onInput={(e) => onInputChange(e)} />
                        </div>
                    </form>
                </div>
            </div>
            <div className="search-load-box mt-4">
                <div className="row" id="scrollableMeme" style={{ overflowX: "hidden" }}>

                    <InfiniteScroll
                        dataLength={imageData.length} //This is important field to render the next data
                        next={() => searchImage(search.page + 1)}
                        hasMore={true}
                        scrollableTarget="scrollableMeme"
                    >
                        {
                            imageData.length > 0 ?
                                imageData.map((image, index) => {
                                    return (
                                        <div className="colmn col-6" key={index}>
                                            <div className="search-load d-flex justify-content-center align-items-center cursor-pointer">
                                                <div className="search-load-wrapper-blur-bg">
                                                    <img src={image.thumb} alt={image?.name} width="100%" height="100%" onClick={() => selectImage(image, index)} style={{ objectFit: "contain" }} />
                                                </div>

                                                <div className="search-img-result">
                                                    <img src={image.thumb} alt={image?.name} width="100%" height="100%" onClick={() => selectImage(image, index)} style={{ objectFit: "contain" }} />
                                                    {
                                                        checkImage === index ?
                                                            <div className="slide-active">
                                                                <i className="fa fa-check" aria-hidden="true"></i>
                                                            </div>
                                                            : ''
                                                    }
                                                </div>

                                                <div className="crop-icon" onClick={() => selectCrop(image.image)}>
                                                    <i className="fa fa-crop"></i>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : ''
                        }
                        {
                            loader ?
                                <div className="col-md-12">
                                    <h4 className="text-center"><i className="fa fa-spinner fa-spin mr-2" /></h4>
                                </div>
                                : ''
                        }
                        {
                            error ?
                                <div className="col-md-12">
                                    <h6 className="text-center">{error}</h6>
                                </div>
                                : ''
                        }
                    </InfiniteScroll>

                </div>
            </div>

            <div className={`modal ${cropModel ? 'show-modal' : ''} `} style={{ height: "auto" }}>
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title text-center">Crop Image</h5>
                            <button onClick={(e) => closePopup()} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true"><i className="fas fa-times-circle" style={{ color: "#ffffff" }} ></i></span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ position: "relative", height: "50vh" }}>
                            <Cropper
                                image={currentImage}
                                crop={crop}
                                zoom={zoom}
                                aspect={dimArr[0] / dimArr[1]}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                                objectFit="contain"
                            />
                            {
                                croppedAreaPixels === null ? <div className="crop-icon-center"><i className="fa fa-spinner fa-spin mr-2" /></div> : ''
                            }

                        </div>

                        <div className="modal-footer d-flex justify-content-between">
                            <button className="btn-custom mr-1" type="button" onClick={() => showCroppedImage()}>
                                {cropLoader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                {cropLoader ? 'Cropping' : 'Crop'}
                            </button>
                            <button className={`${outputImage === false ? 'btn-custom-disable' : 'btn-custom'}`} type="button" disabled={outputImage === false ? true : false} onClick={() => selectCropImage(outputImage)}>Use</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


export default React.memo(SearchImage);