import styled from "styled-components";

export const Portait6Style = styled.div`
.flex-column {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-center {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.flex-row-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: left;
  justify-content: left;
}

.subflex-left {
  width: 100%;
  text-align: left;
}

.subflex-right {
  width: 100%;
  text-align: right;
}

.subflex-center {
  width: 100%;
  text-align: center;
}
.text-wrapper {
  white-space: pre-wrap;
}
/*.f-arial {*/
/*	font-family: Arial;*/
/*}*/

/*.f-mvboli {*/
/*	font-family: mvboli;*/
/*}*/

/*.f-nexalight {*/
/*	font-family: nexalight;*/
/*}*/

/*.f-nexabold {*/
/*	font-family: nexabold;*/
/*}*/

.f-cyan {
  color: #00fffc;
}

.f-cyan-dark {
  color: #00d1cf;
}

.f-blue {
  color: #005596;
}

.f-white {
  color: #ffffff;
}

.poster {
  position: relative;
  overflow: hidden !important;
}

.poster.nine-by-sixteen {
  width: 1080px;
  height: 1920px;
}

.poster.one-by-one {
  width: 310px;
  height: 310px;
}

.poster.nine-by-sixteen {
  width: 171px;
  height: 304px;
}

.poster.six {
  background-color: #00d1cf;
}

.poster.six > div {
  height: 135%;
}

.poster.nine-by-sixteen.six .photo-container {
  height: 500px;
  width: 100%;
  margin-bottom: 130px;
}

.poster.nine-by-sixteen.six .photo {
  position: absolute;
  height: 0;
  width: 0;
}

.poster.nine-by-sixteen.six .ta {
  font-size: 58px;
  line-height: 70px;
  margin: 0;
  font-weight: bold;
  opacity: 0;
}

.poster.nine-by-sixteen.six .photo-container {
  height: 50px;
  margin-bottom: 35px;
}

.poster.nine-by-sixteen.six .photo {
  border-radius: 50%;
  background-size: 125px;
}

.poster.nine-by-sixteen.six .ta {
  font-size: 9px;
  line-height: 12px;
}

.photo img,
.photo video {
  border-radius: 50%;
}

.poster.six .text-wrapper {
  height: 128px;
}
`;
