import React from "react";
import { Square5Style } from "./css/Square5Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";

const Template = (props) => {
  return (
    <Square5Style>
      <div className="container4">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="back-layer">
          {" "}
          <TemplateImage slideType={props.slideType} file={props.file} />
        </div>

        <div className="front-layer">
          <div className="text-wrapper">
            <TemplateText
                id={props.id}
                text={props.text}
                textStyle={props.textStyle}
                editStatus={props.editStatus}
                onInputSlideText={props.onInputSlideText}
            />
          </div>
        </div>
      </div>
    </Square5Style>
  );
};

export default Template;
