import axios from "axios";
import {baseURL} from "../global/global";

export const fetchUserCampaigns = ( token ) => (dispatch) => {
    axios({
        method: "POST",
        url: `${baseURL}fetch-campaigns`,
        data: {},
        headers: {'Content-Type': 'application/json', 'Authorization': token}
    }).then(res=>{
        if(res.data.status === true){
            dispatch({type: 'ADD_CAMPAIGNS', payload: res.data.data});
        }
    }).catch(err=>{

    })
}