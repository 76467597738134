import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../global/global";
import { useDispatch, useSelector } from "react-redux";
import AudioFiles from "./AudioFiles";
import swal from "sweetalert";
import { setAlert } from "../../actions/alert";
import Alert from "../Alert";

const UploadAudio = ({ currentUrl, setCurrentUrl , selectedAudio, setSelectedAudio}) => {

    let allowedExt = ['audio/mpeg'];

    const dispatch = useDispatch();

    const auth = useSelector(state => state.auth);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [loader, setLoader] = useState(false);
    const [percent, setPercent] = useState(0);

    const fetchUserLibrary = () => {
        axios({
            method: 'POST',
            url: `${baseURL}user-library`,
            data: {},
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            console.log(res)
            if (res.data.status === true) {
                setUploadedFiles(res.data.data.audio);
            }
        }).catch(err => {

        })
    }

    const onInputFile = (e) => {

        console.log(e.target.files[0]);
        if (e.target.files[0] !== undefined) {
            if (allowedExt.includes(e.target.files[0].type)) {

                let allowedSize = 5000000;

                if (e.target.files[0].size < allowedSize) {
                    setLoader(true);

                    const formData = new FormData();
                    formData.append('file_type', 'music');
                    formData.append('file', e.target.files[0])

                    const options = {
                        onUploadProgress: (progressEvent) => {
                            const { loaded, total } = progressEvent;
                            let percent = Math.floor((loaded * 100) / total);

                            setPercent(percent);
                        },
                        headers: {
                            'Authorization': auth.token
                        }
                    }

                    axios.post(`${baseURL}upload-file`, formData, options).then(res => {
                        if (res.data.status === "success") {
                            dispatch(setAlert(res.data.message, 'success'));
                            fetchUserLibrary();
                        } else {
                            dispatch(setAlert(res.data.message, 'danger'));
                        }
                        setLoader(false);
                        setPercent(0);
                    })

                } else {
                    swal("Oops!", "Max allowed size for MP3 5MB");
                }

            } else {
                swal("Oops!", "You have Selected Invalid File Type");
            }
        }
    }

    const removeMedia = async (id) => {
        let formData = new FormData();
        formData.append('id', id);

        await axios({
            method: 'POST',
            url: `${baseURL}delete-media-file`,
            data: formData,
            headers: { 'Content-Type': 'application/json', 'Authorization': auth.token }
        }).then(res => {
            if (res.data.status === true) {
                dispatch(setAlert(res.data.message, 'success'));
                fetchUserLibrary();
            } else {
                dispatch(setAlert(res.data.message, 'dnger'));
            }
        }).catch(err => {
            dispatch(setAlert('Getting server error', 'dnger'));
        })
    }

    useEffect(() => {
        fetchUserLibrary();
    }, [])

    return (
        <>
            <Alert />
            <div className="audio-library-boxs">
                <div className="audio-upload-btn">
                    <button type="button" className="form-control">
                        <span className="mr-1">
                            <i className="fas fa-cloud-upload-alt" />
                        </span>{" "}
                        Upload Audio
                    </button>
                    <div className="custom-file">
                        <input
                            type="file"
                            className="custom-file-input"
                            onChange={(e) => onInputFile(e)}
                        />
                    </div>
                </div>
                {
                    loader ?
                        <div className="progress mt-4">
                            <div className="progress-bar" role="progressbar" style={{ width: `${percent}%`, backgroundColor: "#10ca9c" }} aria-valuenow={`${percent}`}
                                aria-valuemin="0" aria-valuemax="100">{percent}%
                            </div>
                        </div>
                        : ''
                }
                <div className="upload-audio-box mt-4">
                    <div className="row m-0" style={{height: 'auto'}}>
                        <div className="alert alert-primary" role="alert">
                            This music will apply to all slides
                        </div>
                        {
                            uploadedFiles.length > 0 ?
                                uploadedFiles.map((item, index) => {
                                    return (
                                        <AudioFiles
                                            title="Uploaded Music"
                                            url={`${baseURL}${item.file}`}
                                            currentUrl={currentUrl}
                                            setCurrentUrl={setCurrentUrl}
                                            type="uploaded"
                                            removeMedia={removeMedia}
                                            selectedAudio={selectedAudio}
                                            setSelectedAudio={setSelectedAudio}
                                            id={item.id}
                                            key={index} />
                                    )
                                })
                                : ''
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default React.memo(UploadAudio);