import styled from "styled-components";

export const Portait6Style = styled.div`
  width: 100%;
  height: 100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container5 {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .back-layer {
    width: 100%;
    height: 100%;
    background: #002a29;
  }

  .back-layer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
    transform: scale(1.1);
    left: 5%;
  }

  .text-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }

  .white-border {
    position: absolute;
    background: #fff;
    height: 100%;
    width: 1%;
    top: 0;
    left: 0;
  }

  .text-wrapper > div {
    width: 70%;
  }
`;
