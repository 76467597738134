import React, {useState} from "react";
import StoryBoard from "./StoryBoard";
import {Helmet} from "react-helmet";
import Footer from "../Footer";

import "./css/editor.css"
import "./css/responsive.css"
import VideoTiles from "./VideoTiles";
import { useSelector} from "react-redux";
import TextStyles from "./TextStyles";
import Media from "./Media";
import Audio from "./Audio";
import LogoWatermark from "./LogoWatermark";
import Credits from "./Credits";
import EditorHead from "./EditorHead";
import EditorTabs from "./EditorTabs";
import Preview from "./Preview";
import Navbar from "../Navbar";

const Editor = () => {

    const videoData = useSelector(state => state.videos);
    const brandName = useSelector(state => state.rebrand.data);

    const [outroData, setOutroData] = useState(false);

    const logoData = videoData.data.logo;
    const watermarkData = videoData.data.watermark;
    const template_id = videoData.data.template_id;
    const template_no = videoData.data.template_no;
    const dimmer = videoData.data?.dimmer;
    const slideLength = videoData.data.slides.length;
    const audioFile = videoData.data.music;

    const tilesData = videoData.data.slides;
    const dimension = videoData.data.size;

    const selectedIndex = tilesData.findIndex(({is_selected}) => is_selected === true);
    const selectedLayer = tilesData.find(({is_selected}) => is_selected === true);
    const lastSlide = tilesData.find(({uniq_id}) => uniq_id < selectedIndex);
    const duration = selectedLayer.duration;

    return (
        <>
            <main className="editor-main">
                <Helmet>
                    <title>{brandName !== false ? brandName.name : 'ClipsReel'} | Editor </title>
                </Helmet>
                <Navbar page="editor" />

                <section className="editor-sec">
                    <div className="row editor-container m-0">
                        <div className="colmn colmn-1 order-lg-1 order-2 p-0">
                            <div className="editor-part">
                                <div className="tab-content text-white" id="pills-tabContent">
                                    <div className="tab-pane editor-panel fade show active" id="pills-story"
                                         role="tabpanel" aria-labelledby="pills-story-tab">
                                        <StoryBoard storyBoard={videoData} slideLength={tilesData.length} template_id={template_id} />
                                    </div>
                                    <div className="tab-pane editor-panel fade" id="pills-text" role="tabpanel"
                                         aria-labelledby="pills-text-tab">
                                        <TextStyles selectedLayerData={selectedLayer} selectedIndex={selectedIndex} tilesData={tilesData} />
                                    </div>
                                    <div className="tab-pane editor-panel fade" id="pills-media" role="tabpanel"
                                         aria-labelledby="pills-media-tab">
                                        <Media selectedIndex={selectedIndex} dimension={dimension} />
                                    </div>
                                    <div className="tab-pane editor-panel fade" id="pills-audio" role="tabpanel"
                                         aria-labelledby="pills-audio-tab">
                                        <Audio selectedIndex={selectedIndex} audioFile={audioFile} />
                                    </div>
                                    <div className="tab-pane editor-panel fade" id="pills-style" role="tabpanel"
                                         aria-labelledby="pills-style-tab">
                                        <LogoWatermark logoData={logoData} watermarkData={watermarkData} template_id={template_id} selectedIndex={selectedIndex} selectedLayer={selectedLayer} />
                                    </div>
                                    <div className="tab-pane editor-panel fade" id="pills-credits" role="tabpanel"
                                         aria-labelledby="pills-credits-tab">
                                        <Credits dimension={dimension} tilesData={tilesData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="colmn colmn-2 order-lg-2 order-1 p-0">
                            <div className="colmn-preview-wrapper">
                                <EditorHead/>
                                <EditorTabs/>
                                <Preview
                                    currentLayer={selectedLayer}
                                    dimension={dimension}
                                    dimmer={dimmer}
                                    slideLength={slideLength}
                                    selectedIndex={selectedIndex}
                                    logo={logoData}
                                    watermark={watermarkData}
                                    template_id={template_id}
                                    template_no={template_no}
                                    duration={duration}
                                    audioFile={audioFile}
                                    lastSlide={lastSlide}
                                    allSlides={tilesData}
                                    outro={outroData}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <VideoTiles
                    videos={videoData}
                    dimension={dimension}
                    template_id={template_id}
                    selectedIndex={selectedIndex}
                    setOutro={setOutroData}
                />

                <Footer/>
            </main>
        </>
    )
}

export default Editor;