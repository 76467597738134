import React from "react";
import { Square2Style } from "./css/Square2Style";
import TemplateImage from "../../components/editor/Templates/TemplateImage";
import TemplateLogo from "../../components/editor/Templates/TemplateLogo";
import TemplateWatermark from "../../components/editor/Templates/TemplateWatermark";
import TemplateText from "../../components/editor/Templates/TemplateText";
const square_6_1 = (props) => {
  return (
    <Square2Style>
      <div className="container6">
        <TemplateLogo logo={props.logo} />
        <TemplateWatermark watermark={props.watermark} />
        <div className="two-layer-bg">
          <div className="col-1" />
          <div className="col-2" />
        </div>
        <div className="front-layer">
          <div className="img-wrapper">
            <div className="wrap">
              <TemplateImage slideType={props.slideType} file={props.file} />
            </div>
          </div>
        </div>
        <div className="text-wrappers">
          <TemplateText
            id={props.id}
            text={props.text}
            textStyle={props.textStyle}
            editStatus={props.editStatus}
            onInputSlideText={props.onInputSlideText}
          />
        </div>
      </div>
    </Square2Style>
  );
};

export default square_6_1;
