import styled from "styled-components";

export const Square6Style = styled.div`
  width:100%;
  height:100%;

  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container4 {
    width:100%;
    height:100%;
    overflow: hidden;
  }

  .f-peach {
    color: #f98365;
  }

  .f-white {
    color: #fff;
  }

  .f-blue {
    color: #005596;
  }

  .back-layer {
    width:100%;
    height:100%;
    clip-path: polygon(50% 100%, 50% 100%, 50% 0%, 50% 0%);
  }

  .back-layer img {
    width:100%;
    height:100%;
    object-fit: cover;
    transform: scale(1.1);
    left: -5%;
  }

  .text-wrapper {
    top: 0;
    position: absolute;
    width:100%;
    height:100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

  .text-wrapper > div {
    width: 100%;
  }
`;
