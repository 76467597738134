import React, { useState } from "react";
import { withRouter } from "react-router-dom";
//import queryString from "query-string";
import {Link, useHistory} from "react-router-dom";
import LoginFooter from "./auth/LoginFooter";
import { loginUser } from "../actions/authAction";
import loginBg from "../images/BG.png"
import { connect } from "react-redux";
import Alert from "./Alert";
import logo from "../images/LOGO.png"
import {masterLoginUser} from "../actions/authAction";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";

const MasterLogin = () => {
    // const email = queryString.parse(location.search).email;
    // const token = queryString.parse(location.search).token;

    // useEffect(() => {
    //     if (email !== "" && token !== "") {
    //         let newToken = "";

    //         token.split(" ").forEach((element) => {
    //             newToken = newToken.concat(element + "+");
    //         });
    //         newToken = newToken.slice(0, newToken.length - 1);
    //         masterLoginUser(email, newToken, history);
    //     }
    // }, [email, token]);

    const history = useHistory();
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    if(auth.isAuthenticated) {
        history.push('/dashboard');
    }

    const [loader, setLoader] = useState(false);
    const [user, setUser] = useState({
        useremail: '',
        email:'',
        password: ''
    })

    const onInputChange = (e) => {
        setUser({...user, [e.target.name] : e.target.value});
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);

        dispatch(masterLoginUser(user, setLoader,history));
    }

    return <div>
        <Helmet>
                <title>ClipsReel | Login </title>
            </Helmet>
            <Alert/>
            <section className="logoimg" style={{backgroundImage : "url("+loginBg+")"}}>
                <div className="container">
                    <div className="row pt-4">
                        <div className="col-md-4">
                            <div className="logo">
                                <img src={logo} alt="logo" />
                            </div>
                        </div>
                    </div>

                    <div className="login-box">
                        <div className="row">
                            <div className="col-lg-6 d-flex align-items-center">
                                <div className="login-content text-white text-center">
                                    <h1><span>Welcome</span> Back !</h1>
                                    <p>Instantly Turn Any Webpage Into a Traffic-Getting Video At The Push of a
                                        Button!</p>
                                    <h5 className="mt-3">…It’s Super Easy-To-Use</h5>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <form className="login-form text-white" method="post" onSubmit={(e)=>onFormSubmit(e)}>
                                    <h4><span>Login</span> to Your Account Now</h4>
                                    <div className="form-group mt-4">
                                        <label>User Email Address</label>
                                        <input type="email" name="useremail" className="form-control"
                                               placeholder="Email Address" required
                                               onChange={(e)=>onInputChange(e)}
                                        />
                                    </div>

                                    <div className="form-group mt-4">
                                        <label>Admin Email Address</label>
                                        <input type="email" name="email" className="form-control"
                                               placeholder="Admin Email Address" required
                                               onChange={(e)=>onInputChange(e)}
                                        />
                                    </div>


                                    <div className="form-group">
                                        <label>Admin Password</label>
                                        <input type="password" name="password" className="form-control"
                                               placeholder="************" required
                                               onChange={(e)=>onInputChange(e)}
                                        />
                                    </div>

                                    <button type="submit" className="form-control btn-change6 mt-4" disabled={loader}>
                                        { loader ? <i className="fa fa-spinner fa-spin mr-2"/>  : '' } Login to ClipsReel
                                    </button>
                                    <div className="forgot text-center mt-4">
                                        <Link to="/forget-password" className="text-center ">Forgot your account
                                            details? <span>Click here</span></Link>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>

                <LoginFooter/>

            </section>

    </div>;
};

export default connect(null, { masterLoginUser })(withRouter(MasterLogin));
