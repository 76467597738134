import styled from "styled-components";

export const Portait1Style = styled.div`
width:100%;
height:100%;
  .img-fluid {
    display: block;
    max-width: 100%;
  }

  .container9 {
    width:100%;
    height:100%;
    overflow: hidden;
  }


  .back-layer {
    width:100%;
    height:100%;
  }

  .front-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(0, 0, 0, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(0, 0, 0, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 30%,
      rgba(0, 0, 0, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#000000',GradientType=0 );
    top: 100%;
  }

  .back-layer img,
  .back-layer video {
    width:100%;
    height:100%;
    object-fit: cover;
    object-position: center;
  }

  .text-wrapper {
    position: absolute;
    bottom: -25%;
    left: 0;
    right: 0;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
  }

  .text-wrapper div {
    text-align: center;
    width:100%;
  }


`;
